import React from 'react'
import { useDispatch } from 'react-redux'
import { logout } from '../redux/authentication'
import { Button } from 'react-bootstrap'
import ImageWithBasePath from '../core/img/imagewithbasebath'

export default function NotMachineConfigured() {
	const dispatch = useDispatch()

	return (
		<main>
			<section className='vh-100 w-100 d-flex flex-column justify-content-center align-items-center gap-4'>
				<ImageWithBasePath src='assets/img/lapi.png' height={250} width={250} />

				<h1 className='text-center'>
					No se ha configurado un dispositivo, <br/> contacte a un administrador para
					que lo haga.
				</h1>

				<Button
					onClick={() => {
						dispatch(logout())
					}}
					className='w-25'>
					Cerrar Sesión
				</Button>
			</section>
		</main>
	)
}
