import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react'
import ImageWithBasePath from '../../core/img/imagewithbasebath'
import {
	selectAuthenticationUser,
	selectAuthenticationLogoutIsLoading,
} from '../../redux/selectors/authentication'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/authentication'
import { admin_role, manager_role, ROUTES } from '../../utils/var'
import { verifyUserRole } from '../../utils/functions/authorization'
import { initWithdraw, verifyNeedWithdraw } from '../../redux/withdraw'

const Header = () => {
	const [isFullscreen, setIsFullscreen] = useState(false)
	const user = useSelector(selectAuthenticationUser)
	const logoutIsLoading = useSelector(selectAuthenticationLogoutIsLoading)
	const { firstName, lastName, position, userId } = user
	const [toggle, SetToggle] = useState(true)
	const [isSectionVisible, setSectionVisible] = useState(false)
	const isElementVisible = (element) => {
		return element.offsetWidth > 0 || element.offsetHeight > 0
	}
	const dispatch = useDispatch()

	const handleLogout = () => {
		dispatch(logout())
	}

	useEffect(() => {
		const handleFullscreenChange = () => {
			setIsFullscreen(
				document.fullscreenElement ||
					document.mozFullScreenElement ||
					document.webkitFullscreenElement ||
					document.msFullscreenElement
			)
		}

		document.addEventListener('fullscreenchange', handleFullscreenChange)
		document.addEventListener('mozfullscreenchange', handleFullscreenChange)
		document.addEventListener('webkitfullscreenchange', handleFullscreenChange)
		document.addEventListener('msfullscreenchange', handleFullscreenChange)

		return () => {
			document.removeEventListener('fullscreenchange', handleFullscreenChange)
			document.removeEventListener(
				'mozfullscreenchange',
				handleFullscreenChange
			)
			document.removeEventListener(
				'webkitfullscreenchange',
				handleFullscreenChange
			)
			document.removeEventListener('msfullscreenchange', handleFullscreenChange)
		}
	}, [])

	const toggleFullscreen = (elem) => {
		elem = elem || document.documentElement
		if (
			!document.fullscreenElement &&
			!document.mozFullScreenElement &&
			!document.webkitFullscreenElement &&
			!document.msFullscreenElement
		) {
			if (elem.requestFullscreen) {
				elem.requestFullscreen()
			} else if (elem.msRequestFullscreen) {
				elem.msRequestFullscreen()
			} else if (elem.mozRequestFullScreen) {
				elem.mozRequestFullScreen()
			} else if (elem.webkitRequestFullscreen) {
				elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
			}
		} else {
			if (document.exitFullscreen) {
				document.exitFullscreen()
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen()
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen()
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen()
			}
		}
	}

	useEffect(() => {
		const handleMouseover = (e) => {
			e.stopPropagation()

			const body = document.body
			const toggleBtn = document.getElementById('toggle_btn')

			if (
				body.classList.contains('mini-sidebar') &&
				isElementVisible(toggleBtn)
			) {
				e.preventDefault()
			}
		}

		document.addEventListener('mouseover', handleMouseover)

		return () => {
			document.removeEventListener('mouseover', handleMouseover)
		}
	}, [])

	const handlesidebar = () => {
		document.body.classList.toggle('mini-sidebar')
		SetToggle((current) => current)
	}
	const expandMenu = () => {
		document.body.classList.remove('expand-menu')
	}
	const expandMenuOpen = () => {
		document.body.classList.add('expand-menu')
	}
	const sidebarOverlay = () => {
		document?.querySelector('.main-wrapper')?.classList?.toggle('slide-nav')
		document?.querySelector('.sidebar-overlay')?.classList?.toggle('opened')
		document?.querySelector('html')?.classList?.toggle('menu-opened')
	}
	// Función para alternar la visibilidad de la sección
	const userActionsVisibility = () => {
		setSectionVisible(!isSectionVisible);
	};

	let pathname = location.pathname

	const exclusionArray = [
		'/reactjs/template/dream-pos/index-three',
		'/reactjs/template/dream-pos/index-one',
	]
	if (exclusionArray.indexOf(window.location.pathname) >= 0) {
		return ''
	}

	return (
		<>
			<div className='header'>
				{/* Logo */}
				<div
					className={`header-left ${toggle ? '' : 'active'}`}
					onMouseLeave={expandMenu}
					onMouseOver={expandMenuOpen}>
					<Link to={ROUTES.ADMIN_DASHBOARD} className='logo logo-normal'>
						<ImageWithBasePath
							src='assets/img/login-images/lapape-logo.png'
							alt='img'
						/>
					</Link>
					<Link to={ROUTES.ADMIN_DASHBOARD} className='logo logo-white'>
						<ImageWithBasePath src='assets/img/logo-white.png' alt='img' />
					</Link>
					<Link to={ROUTES.ADMIN_DASHBOARD} className='logo-small'>
						<ImageWithBasePath
							src='assets/img/login-images/lapape-logo-chico.png'
							alt='img'
						/>
					</Link>
					<Link
						id='toggle_btn'
						to='#'
						style={{
							display:
								pathname.includes('tasks') || pathname.includes('pos')
									? 'none'
									: pathname.includes('compose')
									? 'none'
									: '',
						}}
						onClick={handlesidebar}>
						<FeatherIcon icon='chevrons-left' className='feather-16' />
					</Link>
				</div>
				{/* /Logo */}
				<Link
					id='mobile_btn'
					className='mobile_btn'
					to='#'
					onClick={sidebarOverlay}>
					<span className='bar-icon'>
						<span />
						<span />
						<span />
					</span>
				</Link>

				{/* Header Menu */}
					<ul className='nav user-menu flex-row align-items-center justify-content-end'>
						<Link
							onClick={userActionsVisibility}
							className='btn-toggle-section'>
							{isSectionVisible ? '' : ''}
							<FeatherIcon icon='chevrons-left' className='feather-16' />
						</Link>
						
						{/* <ActionBar /> */}
						{isSectionVisible && (
						<>
						<li className='nav-item nav-item-box'>
							{/* Expand window */}
							<Link
								to='#'
								id='btnFullscreen'
								onClick={() => toggleFullscreen()}
								className={isFullscreen ? 'Exit Fullscreen' : 'Go Fullscreen'}>
								{/* <i data-feather="maximize" /> */}
								<FeatherIcon icon='maximize' />
							</Link>
						</li>
						<li className='nav-item dropdown has-arrow main-drop'>
							<Link
								to='#'
								className='dropdown-toggle nav-link userset'
								data-bs-toggle='dropdown'>
								<span className='user-info'>
									<span className='user-letter'>
										<ImageWithBasePath
											src='assets/img/profiles/avator1.jpg'
											alt='img'
											className='img-fluid'
										/>
									</span>
									<span className='user-detail'>
										<span className='user-name'>
											{firstName} {lastName}
										</span>
										<span className='user-role'>{position}</span>
									</span>
								</span>
							</Link>
							<div className='dropdown-menu menu-drop-user'>
								<div className='profilename'>
									<div className='profileset'>
										<span className='user-img'>
											<ImageWithBasePath
												src='assets/img/profiles/avator1.jpg'
												alt='img'
											/>
											<span className='status online' />
										</span>
										<div className='profilesets'>
											<h6>
												{firstName} {lastName}
											</h6>
											<h5>{position}</h5>
										</div>
									</div>
									<hr className='m-0' />
									{verifyUserRole(user, [admin_role, manager_role]) && (
										<Link
											className='dropdown-item logout pb-0'
											to={ROUTES.ADMIN_DASHBOARD}>
											<ImageWithBasePath
												src='assets/img/icons/log-out.svg'
												alt='img'
												className='me-2'
											/>
											Ir al dashboard
										</Link>
									)}
									<button
										className='dropdown-item pb-0'
										onClick={() => {
											dispatch(verifyNeedWithdraw(true))
											dispatch(
												initWithdraw({
													idEmp: userId,
													needWithDrawal: true,
													openingBalance: 500,
													withDrawalAmount: 0,
													amountOrders: 0,
													amountWithDrawals: 0,
													amountCashDrawer: 0,
												})
											)
										}}>
										<ImageWithBasePath
											src='assets/img/icons/dollar-square.svg'
											alt='img'
											className='me-2'
										/>
										Ejecutar retiro
									</button>
									<button
										className='dropdown-item logout pb-0'
										onClick={handleLogout}>
										{!logoutIsLoading ? (
											<ImageWithBasePath
												src='assets/img/icons/log-out.svg'
												alt='img'
												className='me-2'
											/>
										) : (
											<div
												className='spinner-border spinner-border-sm ms-1'
												role='status'>
												<span className='sr-only'>Loading...</span>
											</div>
										)}
										Logout
									</button>
								</div>
							</div>
						</li></>
						)}
					</ul>
				{/* /Header Menu */}
				{/* Mobile Menu */}
				<div className='dropdown mobile-user-menu'>
					<Link
						to='#'
						className='nav-link dropdown-toggle'
						data-bs-toggle='dropdown'
						aria-expanded='false'>
						<i className='fa fa-ellipsis-v' />
					</Link>
					<div className='dropdown-menu dropdown-menu-right'>
						<Link className='dropdown-item' to={ROUTES.ADMIN_DASHBOARD}>
							Ir al Dashboard
						</Link>
						<Link
							className='dropdown-item'
							to={ROUTES.LOGIN}
							onClick={handleLogout}>
							Logout
						</Link>
					</div>
				</div>
				{/* /Mobile Menu */}
			</div>
		</>
	)
}

export default Header
