import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectScannedProduct } from '../../../redux/selectors/catalog'
import { apiUrl } from '../../../utils/var'
import Item from './item'
import ImageWithBasePath from '../../../core/img/imagewithbasebath'
import Swal from 'sweetalert2'

export default function ScannedItem() {
	const codebar = useSelector(selectScannedProduct)
	const [product, setProduct] = useState(null)
	const [itemOfert, setItemOfert] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState(null)

	const getScannedProduct = async () => {
		setIsLoading(true)
		setError(null)
		try {
			const url = `${apiUrl}/api/items-without-auth/getItemWithoutAuth/${codebar}`
			const scannedProductResponse = await fetch(url, {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				credentials: 'include',
			})

			if (!scannedProductResponse.ok) {
				let errorMessage
				try {
					const errorBody = await scannedProductResponse.json()
					errorMessage = errorBody.message || 'Error desconocido'
				} catch (error) {
					errorMessage =
						'Ocurrió un error al consultar el producto, recargue el punto de venta o contacte a un encargado.'
				}

				const errorInfo = {
					code: scannedProductResponse.status,
					message: errorMessage,
					url: url,
				}

				throw new Error(JSON.stringify(errorInfo))
			}

			const scannedProduct = (await scannedProductResponse.json()).dataItem.data
			setProduct(scannedProduct)

			if (scannedProduct.length === 0) {
				throw new Error('Producto no encontrado')
			}
		} catch (error) {
			console.error(error)
			let errorMessage

			try {
				const errorInfo = JSON.parse(error.message)
				errorMessage = errorInfo.message
			} catch {
				errorMessage =
					'El producto que escaneo no esta en el catálogo, vuelva a escanear.'
			}
			setError(errorMessage)
		} finally {
			setIsLoading(false)
		}
	}

	const getScannedProductOfert = async () => {
		try {
			const url = `${apiUrl}/api/items-without-auth/getItemsLiquidationWithoutAuth`
			const scannedProductResponse = await fetch(url, {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				credentials: 'include',
			})

			if (!scannedProductResponse.ok) {
				const errorBody = await scannedProductResponse.json()
				throw new Error(errorBody.message || 'Error desconocido')
			}

			const scannedProduct = (await scannedProductResponse.json())
				.dataItemLiquidation.data
			setItemOfert(scannedProduct)
		} catch (error) {
			console.error(error)
			let errorMessage

			try {
				const errorInfo = JSON.parse(error.message)
				errorMessage = errorInfo.message
			} catch {
				errorMessage = 'Ocurrió un error al obtener el producto en liquidación.'
			}
			setError(errorMessage)
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		getScannedProduct()
		getScannedProductOfert()
	}, [codebar])

	useEffect(() => {
		if (isLoading) {
			Swal.fire({
				title: 'Buscando producto',
				text: 'Por favor, espere',
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading()
				},
			})
		} else {
			Swal.close()
		}
	}, [isLoading])

	if (error)
		return (
			<div className='pantalla-error d-flex flex-row align-items-center justify-content-center'>
				<div className='d-flex lapy-escaner'>
					<ImageWithBasePath src='assets/img/lapi.png' />
				</div>
				<h3 className='d-flex align-items-center justify-content-center'>
					Error: {error}
				</h3>
			</div>
		)

	return (
		<div>
			{product && product.length > 0 && itemOfert && (
				<Item
					ItemCode={product[0].ItemCode}
					ItemName={product[0].ItemName}
					AvgPrice={product[0].AvgPrice}
					PicturName={product[0].PicturName}
					OnHand={product[0].OnHand}
					AvgPriceLiquidation={product[0].AvgPriceLiquidation}
					ItemCodeOfert={itemOfert[0].ItemCode}
					ItemNameOfert={itemOfert[0].ItemName}
					AvgPriceOfert={itemOfert[0].AvgPrice}
					AvgPriceLiquidationOfert={itemOfert[0].AvgPriceLiquidation}
				/>
			)}
		</div>
	)
}
