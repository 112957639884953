import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Filter } from 'react-feather'
import DashboardLayout from '../../../../layouts/DashboardLayout'
import PageTtle from '../../../../layouts/PagesTitle'
import { apiUrl } from '../../../../utils/var'
import { Button, Table, Tooltip } from 'antd'
import { formatCurrency } from '../../../../utils/functions/money'
import { convertMilitaryTimeToStandardTimeFormat } from '../../../../utils/functions/date'
import { FileSearchOutlined } from '@ant-design/icons'
import { DatePicker, Space } from 'antd'
const { RangePicker } = DatePicker
import dayjs from 'dayjs'
import ImageWithBasePath from '../../../../core/img/imagewithbasebath'
import { RotateCcw } from 'feather-icons-react/build/IconComponents'
import Error404 from '../../../../feature-module/pages/errorpages/error404'
import ModalDetails from '../../../../components/modalSales'

const GeneralMovements = () => {
	const fromDate = dayjs().subtract(7, 'day').format('YYYY-MM-DD')
	const todayDate = dayjs().format('YYYY-MM-DD')

	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState(null)
	const [cashCounts, setCashCount] = useState([])
	const [modalVisible, setModalVisible] = useState(false)
	const [selectedRecord, setSelectedRecord] = useState(null)
	const [searchText, setSearchText] = useState('')
	// Estado para almacenar el rango de fechas
	const [dateRange, setDateRange] = useState([
		dayjs(fromDate),
		dayjs(todayDate),
	])

	// Función que se ejecuta cuando el usuario cambia la fecha
	const onChange = (dates) => {
		setDateRange(dates) // Guardamos el valor en el estado
	}

	const handleShowModal = (record) => {
		setSelectedRecord(record)
		setModalVisible(true)
	}

	const handleCloseModal = () => {
		setModalVisible(false)
		setSelectedRecord(null)
	}

	const searchDatable = async () => {
		await getItems(
			dateRange[0].format('YYYY-MM-DD'),
			dateRange[1].format('YYYY-MM-DD')
		)
	}

	const handleSearch = (e) => {
		setSearchText(e.target.value)
	}

	//Busqueda en el arreglo de cashCounts
	const filteredData =
		cashCounts?.filter((entry) => {
			return Object.keys(entry).some((key) => {
				return String(entry[key])
					.toLowerCase()
					.includes(searchText.toLowerCase())
			})
		}) || []

	const getItems = async (from, to) => {
		setIsLoading(true)
		try {
			const requestCashCounts = await fetch(
				`${apiUrl}/api/cash-count/getCashCountOrdersWithdrawals?from=${from}&to=${to}`,
				{
					method: 'GET',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin': '*',
					},
				}
			)

			const responseCashCount = await requestCashCounts.json()
			if (!requestCashCounts.ok) {
				throw new Error(responseCashCount.message)
			} else {
				setError(null)
				setCashCount(responseCashCount.data)
			}
		} catch (error) {
			setError(error.message)
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		getItems(fromDate, todayDate)
	}, [])

    const expandedRowRender = (record) => {
        const columns = [
            {
                title: 'Tipo',
                dataIndex: 'type',
                align: 'center',
                render: (text) => (
                    text === 'order' ?
                        <span className="badge badge-lineinfo">
                            <Link to="#"> Venta</Link>
                        </span>
                        :
                        <span className="badge badge-linesuccess">
                            <Link to="#"> Retiro</Link>
                        </span>
                ),
            },
            {
                title: 'DocNum',
                dataIndex: 'DocNum',
                align: 'center',
            },
            {
                title: 'DocEntry',
                dataIndex: 'DocEntry',
                align: 'center',
            },
            {
                title: 'Fecha',
                dataIndex: 'DocDate',
                align: 'center',
                render: (text) => (
                    <span>
                        {text.substring(0, 10)}
                    </span>
                )
            },
            {
                title: 'Hora',
                dataIndex: 'DocTime',
                align: 'center',
                render: (time) => {
                    return <span>{convertMilitaryTimeToStandardTimeFormat(time)}</span>;
                },

            },
            {
                title: 'Metodo de pago',
                dataIndex: 'amount',
                align: 'center',
                render: (amount, record) => (
                    record.type === 'order' ?
                        <div>
                            {formatCurrency(amount)} {record.currency}
                        </div>
                        :
                        <div>
                            Saldo Retirado: {formatCurrency(record.U_CSM_SaldoRetirado)} {record.currency}
                        </div >
                ),
            },
            {
                title: 'Monto',
                dataIndex: 'amount',
                align: 'center',
                render: (amount, record) => (
                    record.type === 'order' ?
                        <div>
                            {formatCurrency(amount)} {record.currency}
                        </div>
                        :
                        <div>
                            Saldo Retirado: {formatCurrency(record.U_CSM_SaldoRetirado)} {record.currency}
                        </div >
                ),
            },
            {
                title: 'Estatus',
                dataIndex: 'Canceled',
                align: 'center',
                render: (canceled, record) => {
                    if (canceled === 'Y') {
                        return <span className="badge badge-linepape">Cancelado</span>;
                    }
                    else if (record.DocStatus === 'O') {
                        return <span className="badge badge-lineinfo">Abierto</span>;
                    } else if (record.DocStatus === 'C') {
                        return <span className="badge badge-linesuccess">Cerrado</span>;
                    }
                },

            },
            {
                title: "Detalles",
                dataIndex: "type",
                align: 'center',
                render: (text, record) => (
                    text === 'order' ?

                        <div className="action-table-data">
                            <Tooltip title="search">
                                <Button className="color-primary-button-pap"
                                    icon={<FileSearchOutlined />}
                                    onClick={() => handleShowModal(record)} />
                            </Tooltip>
                        </div> :
                        <span>
                            Retirado por: {record.Manager}
                        </span>
                ),
            },
        ];
        return <Table
            columns={columns}
            dataSource={record.details}
            pagination={false}
            rowKey={(detail) => detail.DocEntry}

        />;
    };

    const columns = [
        {
            title: "Corte",
            dataIndex: 'DocEntry',
            align: 'center',
            sorter: (a, b) => a.DocEntry - b.DocEntry,
        },
        {
            title: "Estado",
            dataIndex: "Status",
            align: 'center',
            render: (text) => (
                text === 'O' ?
                    <span className="badge badge-linesuccess">
                        <Link to="#"> Corte abierto</Link>
                    </span>
                    :
                    <span className="badge badge-lineinfo">
                        <Link to="#"> Corte cerrado</Link>
                    </span>
            ),
            sorter: (a, b) => a.Status.localeCompare(b.Status),
        },
        {
            title: "Fecha de apertura",
            dataIndex: "CreateDate",
            align: 'center',
            sorter: (a, b) => a.CreateDate.localeCompare(b.CreateDate),
            render: (text) => (
                <div>
                    <span>{(text ?? '').substring(0, 10)}</span>
                </div>
            ),
        },
        {
            title: "Hora de apertura",
            dataIndex: "CreateTime",
            align: 'center',
            sorter: (a, b) => a.CreateTime - b.CreateTime,
            render: (time) => {
                return <span>{convertMilitaryTimeToStandardTimeFormat(time)}</span>;
            },
        },
        {
            title: "Cajero",
            dataIndex: "Cashier",
            align: 'center',
            sorter: (a, b) => a.Cashier.localeCompare(b.Cashier),
        },
        {
            title: "Gerente",
            dataIndex: "Manager",
            align: 'center',
            sorter: (a, b) => a.Manager.localeCompare(b.Manager),
        },
        {
            title: "Saldo inicial",
            dataIndex: "U_CSM_SaldoInicial",
            align: 'center',
            sorter: (a, b) => a.U_CSM_SaldoInicial - b.U_CSM_SaldoInicial,
            render: (text) => (
                <div>
                    {formatCurrency(text)}
                </div>
            ),
        },
        {
            title: "Saldo total",
            dataIndex: "U_CSM_SaldoFinal",
            align: 'center',
            sorter: (a, b) => a.U_CSM_SaldoFinal - b.U_CSM_SaldoFinal,
            render: (text) => (
                <div>
                    {formatCurrency(text)}
                </div>
            ),
        },
        {
            title: "Saldo entregado",
            dataIndex: "SaldoEntregado",
            align: 'center',
            sorter: (a, b) => a.SaldoEntregado - b.SaldoEntregado,
            render: (text) => (
                <div>
                    {formatCurrency(text)}
                </div>
            ),
        },
        {
            title: "Equipo",
            dataIndex: "U_CSM_IdEquipo",
            align: 'center',
            sorter: (a, b) => a.U_CSM_IdEquipo.localeCompare(b.U_CSM_IdEquipo),
        },
    ];

    return (
        <DashboardLayout>
            <PageTtle
                maintitle="Movimientos generales"
                subtitle="Consulta los movimientos realizados en La Pape"
                toolbar={
                    <ul className="table-top-head">
                        <li>
                            <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                <RotateCcw />
                            </Link>
                        </li>
                        <li>
                            <Link
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Pdf"
                            >
                                <ImageWithBasePath
                                    src="assets/img/icons/pdf.svg"
                                    alt="img"
                                />
                            </Link>
                        </li>
                        <li>
                            <Link
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Excel"
                            >
                                <ImageWithBasePath
                                    src="assets/img/icons/xls.svg"
                                    alt="img"
                                />
                            </Link>
                        </li>
                        <li>
                            <Link
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Print"
                            >
                                <ImageWithBasePath
                                    src="assets/img/icons/excel.svg"
                                    alt="img"
                                />
                            </Link>
                        </li>
                    </ul>}
            />
           
                {/* /List CashCounts*/}
                {(!error) ?
                    <div className="card table-list-card">
                        <div className="card-body">
                            <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="form-control form-control-sm formsearch"
                                            aria-controls="DataTables_Table_0"
                                            value={searchText}
                                            onChange={handleSearch}
                                        />
                                        <Link to className="btn btn-searchset">
                                            <i data-feather="search" className="feather-search" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="search-path" onClick={searchDatable}>
                                    <Link
                                        className={`btn btn-filter ${isLoading ? 'disabled' : ''}`}
                                        id="filter_search"
                                    >
                                        <Filter
                                            className="filter-icon"
                                        />

                                    </Link>
                                </div>
                                <div className="form-sort stylewidth" style={{ width: 280 }}>
                                    <Space direction="vertical" size={12}>
                                        <RangePicker
                                            defaultValue={[dayjs(fromDate, 'YYYY-MM-DD'), dayjs(todayDate, 'YYYY-MM-DD')]}
                                            allowClear={false}
                                            onChange={onChange}
                                        />
                                    </Space>
                                </div>

                            </div>

                            {/* /Filter */}
                            <div className="table-responsive">

                                <Table
                                    bordered={true}
                                    columns={columns}
                                    dataSource={filteredData}
                                    expandable={{
                                        expandedRowRender,
                                    }}
                                    loading={isLoading}
                                    rowKey="DocEntry"
                                />
                            </div>
                        </div>
                    </div>
                    :
                    <Error404 />
                }
                <ModalDetails visible={modalVisible} onClose={handleCloseModal} record={selectedRecord} />
         
        </DashboardLayout>

    );
};

export default GeneralMovements
