import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
	selectScannedProduct,
	selectScanCount,
	selectIsShowWarning,
} from '../../../redux/selectors/catalog'
import {
	apiUrl,
	showScannedProductConfig,
	showShowWarningConfig,
} from '../../../utils/var'
import { addItem } from '../redux/cart'
import ProductItem from '../components/ProductItem'
import Switch from '../components/Switch'
import Lector from '../components/Lector'
import { Button } from 'react-bootstrap'
import { changeComponentShow } from '../../../redux/catalog'

export default function ScannedProduct() {
	const dispatch = useDispatch()
	const codebar = useSelector(selectScannedProduct)
	const scanCount = useSelector(selectScanCount)
	const [product, setProduct] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState(null)
	const isShowWarning = useSelector(selectIsShowWarning)

	const getWarning = async () => {
		dispatch(
			changeComponentShow(
				isShowWarning ? showScannedProductConfig : showShowWarningConfig
			)
		)
	}

	const getScannedProduct = async () => {
		setIsLoading(true)
		setError(null)
		try {
			const url = `${apiUrl}/api/items/getItem?param=${codebar}`
			const scannedProductResponse = await fetch(url, {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				credentials: 'include',
			})

			if (!scannedProductResponse.ok) {
				let errorMessage
				try {
					const errorBody = await scannedProductResponse.json()
					console.error(errorBody)

					errorMessage =
						errorBody.message ||
						'Error desconocido o de red al traer el articulo, recargue el punto de venta o llame a mantenimiento.'
				} catch (error) {
					errorMessage =
						'Ocurrió un error al consultar el producto, recargue el punto de venta o contacte a un encargado.'
				}
				const errorInfo = {
					code: scannedProductResponse.status,
					message: errorMessage,
					url: url,
				}

				throw new Error(JSON.stringify(errorInfo))
			}

			const scannedProduct = (await scannedProductResponse.json()).items
			setProduct(scannedProduct)
			console.log(scannedProduct[0].AvgPriceLiquidation)

			dispatch(addItem(scannedProduct[0]))
		} catch (error) {
			console.error(error)
			let errorMessage

			try {
				const errorInfo = JSON.parse(error.message)
				errorMessage = errorInfo.message
			} catch {
				errorMessage =
					'Ocurrió un error al traer el producto, recargue el punto de venta o contacte a un encargado.'
			}
			setError(errorMessage)
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		getScannedProduct()
	}, [scanCount])

	if (isLoading) return <div>Cargando...</div>
	if (error)
		return (
			<div
				className='pantalla-error-pos d-flex flex-column align-items-center justify-content-center'
				style={{ margin: 220 + 'px' }}>
				<h3>Error: {error}</h3>
				<br></br>,<Button onClick={getWarning}>Volver</Button>
			</div>
		)
	if (!product) return <div>No hay producto escaneado.</div>

	return (
		<>
			<div className='d-flex flex-column'>
				<div
					className='d-flex flex-row align-items-center justify-content-between'
					style={{ margin: 2 + '%' }}>
					<Lector />
					<Switch />
				</div>
				<br></br>
				{product && product.length > 0 && (
					<ProductItem
						ItemCode={product[0].ItemCode}
						ItemName={product[0].ItemName}
						AvgPrice={product[0].AvgPrice}
						PicturName={product[0].PicturName}
						OnHand={product[0].OnHand}
						AvgPriceLiquidation={product[0].AvgPriceLiquidation}
					/>
				)}
			</div>
		</>
	)
}
