import React from 'react'
import { PropTypes } from 'prop-types'
import SettingSelect from './SettingSelect'
/* import PaymentMethodSelector from './PaymentMethodSelector'; */
import FormFooter from './FormFooter'

const PrinterSettingsForm = ({
	printers,
	logos,
	razones,
	tiendas,
	ubicaciones,
	telefonos,
	rfc,
	selections /* 
    paymentMethods, */,
	verifingConnection,
	handleSelectChange,
	verifyPrinter,
	/*     handlePaymentMethodChange, */
	handleDeleteConfirmation,
	restoreConfigurations,
	onSubmit,
	usePrinter,
}) => {
	return (
		<form onSubmit={onSubmit} className='company-info border-0'>
			<SettingSelect
				label='Impresora Térmica'
				description='Permite seleccionar la impresora termica'
				options={printers}
				value={selections.printer}
				onChange={(value) => handleSelectChange(value, 'printer')}
				required={true}
				showButton={true}
				loadingButton={verifingConnection}
				funcion={verifyPrinter}
				disableButton={selections.printer == null}
				disableSelect={usePrinter}
			/>

			<SettingSelect
				label='Logo'
				description='Selecciona el logo utilizado en el encabezado de cada ticket de la tienda'
				options={logos}
				value={selections.logo}
				onChange={(value) => handleSelectChange(value, 'logo')}
				required={true}
				disableSelect={usePrinter}
			/>

			<SettingSelect
				label='Razon Social'
				description='Selecciona la razon social que aparecerá en los tickets'
				options={razones}
				value={selections.razon}
				onChange={(value) => handleSelectChange(value, 'razon')}
				required={true}
				disableSelect={usePrinter}
			/>

			<SettingSelect
				label='Tienda'
				description='Selecciona la tienda que aparecerá en los tickets'
				options={tiendas}
				value={selections.tienda}
				onChange={(value) => handleSelectChange(value, 'tienda')}
				required={true}
				disableSelect={usePrinter}
			/>

			<SettingSelect
				label='Ubicacion'
				description='Selecciona la ubicación que aparecerá en los tickets'
				options={ubicaciones}
				value={selections.ubicacion}
				onChange={(value) => handleSelectChange(value, 'ubicacion')}
				required={true}
				disableSelect={usePrinter}
			/>

			<SettingSelect
				label='Telefono'
				description='Selecciona el telefono que aparecerá en los tickets'
				options={telefonos}
				value={selections.telefono}
				onChange={(value) => handleSelectChange(value, 'telefono')}
				required={true}
				disableSelect={usePrinter}
			/>

			<SettingSelect
				label='RFC'
				description='Selecciona el RFC que aparecerá en los tickets'
				options={rfc}
				value={selections.rfc}
				onChange={(value) => handleSelectChange(value, 'rfc')}
				required={true}
				disableSelect={usePrinter}
			/>

			{/* <div className="company-info border-0">
                <div className="localization-info">
                    <PaymentMethodSelector
                        label="Imprimir tickets en: "
                        paymentMethods={paymentMethods}
                        selectedMethods={selections.selectedMethods || ['']}
                        onChange={handlePaymentMethodChange}
                        disablePayMethod={usePrinter}
                    />
                </div>
            </div> */}
			<FormFooter
				handleDeleteConfirmation={handleDeleteConfirmation}
				restoreConfigurations={restoreConfigurations}
			/>
		</form>
	)
}
PrinterSettingsForm.propTypes = {
	printers: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		})
	).isRequired,
	logos: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		})
	).isRequired,
	razones: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		})
	).isRequired,
	tiendas: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		})
	).isRequired,
	ubicaciones: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		})
	).isRequired,
	telefonos: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		})
	).isRequired,
	rfc: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		})
	).isRequired,
	selections: PropTypes.shape({
		printer: PropTypes.string,
		logo: PropTypes.string,
		razon: PropTypes.string,
		tienda: PropTypes.string,
		ubicacion: PropTypes.string,
		telefono: PropTypes.string,
		rfc: PropTypes.string,
		openCashDrawerAfterSale: PropTypes.bool,
		selectedMethods: PropTypes.arrayOf(PropTypes.string),
	}).isRequired,
	paymentMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
	verifingConnection: PropTypes.bool.isRequired,
	handleSelectChange: PropTypes.func.isRequired,
	verifyPrinter: PropTypes.func.isRequired,
	handleSwitchChange: PropTypes.func.isRequired,
	handlePaymentMethodChange: PropTypes.func.isRequired,
	handleDeleteConfirmation: PropTypes.func.isRequired,
	restoreConfigurations: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	usePrinter: PropTypes.bool,
}

export default PrinterSettingsForm
