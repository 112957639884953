import React from 'react'
import { Link } from 'react-router-dom'
import { PropTypes } from 'prop-types'

const FilterTable = ({ updateSearchOrder, searchOrder }) => {
	return (
		<div className='table-top'>
			<div className='search-set'>
				<div className='search-input'>
					<input
						type='text'
						placeholder='Buscar'
						className='form-control form-control-sm formsearch'
						aria-controls='DataTables_Table_0'
						onChange={(event) => updateSearchOrder(event)}
						value={searchOrder}
					/>
					<Link to className='btn btn-searchset'>
						<i data-feather='search' className='feather-search' />
					</Link>
				</div>
			</div>
		</div>
	)
}

FilterTable.propTypes = {
	updateSearchOrder: PropTypes.func.isRequired, // 'visible' debe ser un booleano y es requerido
	searchOrder: PropTypes.string.isRequired,
}

export default FilterTable
