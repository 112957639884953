import React from 'react'
import dayjs from 'dayjs'
import { convertMilitaryTimeToStandardTimeFormat } from './date'
import { formatCurrency } from './money'
import { Tree } from 'antd'

export const formatDataCouponDetails = (data) => {
	const details = []
	details.push({
		key: 'created',
		label: 'Creado',
		children: (
			<p>{`${data.CreateDate.substring(
				0,
				10
			)} ${convertMilitaryTimeToStandardTimeFormat(data.CreateTime)}`}</p>
		),
	})
	details.push({
		key: 'code',
		label: 'Codigo',
		children: <p>{data.U_code}</p>,
	})
	details.push({
		key: 'description',
		label: 'Descripción',
		children: <p>{data.U_description}</p>,
	})
	details.push({
		key: 'discount_type',
		label: 'Tipo de descuento',
		children: (
			<p>{data.U_discount_type === 'M' ? 'Monetario' : 'Porcentaje'}</p>
		),
	})
	details.push({
		key: 'discount_value',
		label: 'Descuento',
		children: (
			<p>
				{data.U_discount_type === 'M'
					? formatCurrency(data.U_discount_value)
					: `${data.U_discount_value}%`}
			</p>
		),
	})
	details.push({
		key: 'start',
		label: 'Inicio',
		children: (
			<p>{`${data.U_start_date.substring(0, 10)} ${
				data.U_start_time
					? convertMilitaryTimeToStandardTimeFormat(data.U_start_time)
					: ''
			}`}</p>
		),
	})
	details.push({
		key: 'end',
		label: 'Expiración',
		children: (
			<p>{`${data.U_end_date.substring(0, 10)} ${
				data.U_end_time
					? convertMilitaryTimeToStandardTimeFormat(data.U_start_time)
					: ''
			}`}</p>
		),
	})
	details.push({
		key: 'max_usage',
		label: 'Usos maximos',
		children: <p>{data.U_max_usage}</p>,
	})
	if (data.U_discount_type === 'P') {
		details.push({
			key: 'max_money_discount',
			label: 'Descuento máximo',
			children: <p>{formatCurrency(data.U_max_money_discount)}</p>,
		})
	}
	details.push({
		key: 'min_order_value',
		label: 'Orden mínima',
		children: <p>{formatCurrency(data.U_min_order_value)}</p>,
	})
	details.push({
		key: 'status',
		label: 'Estatus',
		children: <p>{data.U_status === 'A' ? 'Activo' : 'Inactivo'}</p>,
	})

	const applicabilities = []
	const brands = data.Brand
	if (brands && brands.length > 0) {
		applicabilities.push({
			key: 'brands',
			label: 'Marcas',
			children: (
				<Tree
					treeData={brands.map((brand) => ({
						title: `${brand.FirmName}`,
						key: brand.FirmCode,
						children: brand.items.map((item) => ({
							title: `${item.ItemCode} - ${item.ItemName}`,
							key: item.ItemCode,
						})),
					}))}
				/>
			),
		})
	}

	const lines = data.Line
	if (lines && lines.length > 0) {
		applicabilities.push({
			key: 'lines',
			label: 'Lineas',
			children: (
				<Tree
					treeData={lines.map((line) => ({
						title: line.Name,
						key: line.Code,
						children: line.items.map((item) => ({
							title: `${item.ItemCode} - ${item.ItemName}`,
							key: item.ItemCode,
						})),
					}))}
				/>
			),
		})
	}

	const sublines = data.SubLine
	if (sublines && sublines.length > 0) {
		applicabilities.push({
			key: 'sublines',
			label: 'Sublineas',
			children: (
				<Tree
					treeData={sublines.map((subline) => ({
						title: subline.Name,
						key: subline.Code,
						children: subline.items.map((item) => ({
							title: `${item.ItemCode} - ${item.ItemName}`,
							key: item.ItemCode,
						})),
					}))}
				/>
			),
		})
	}

	const items = data.Item
	if (items && items.length > 0) {
		applicabilities.push({
			key: 'items',
			label: 'Productos',
			children: items.map((item) => (
				<li key={item.ItemCode}>&#x25CF; {`${item.ItemName}`}</li>
			)),
		})
	}

	return {
		details: details,
		applicabilities: applicabilities,
	}
}

export const formatDataUpdateCoupon = (data) => {
	console.log(data)

	const dataFormatted = {
		code: data.U_code,
		discount_type: data.U_discount_type,
		discount_value: formatCurrency(data.U_discount_value)
			.replaceAll(',', '')
			.replaceAll('$', ''),
		max_usage: data.U_max_usage,
		'start-end-date': [
			dayjs(data.U_start_date.substring(0, 10), 'YYYY-MM-DD'),
			dayjs(data.U_end_date.substring(0, 10), 'YYYY-MM-DD'),
		],
		'start-end-time': [
			dayjs(
				convertMilitaryTimeToStandardTimeFormat(data.U_start_time),
				'HH:mm'
			),
			dayjs(convertMilitaryTimeToStandardTimeFormat(data.U_end_time), 'HH:mm'),
		],
		min_order_value: data.U_min_order_value
			? formatCurrency(data.U_min_order_value)
					.replaceAll(',', '')
					.replaceAll('$', '')
			: null,
		max_money_discount: data.U_max_money_discount
			? formatCurrency(data.U_max_money_discount)
					.replaceAll(',', '')
					.replaceAll('$', '')
			: null,
		description: data.U_description,
		status: data.U_status === 'A' ? true : false,
		isEnabledTime:
			(data.U_start_time !== null || data.U_start_time !== undefined) &&
			data.U_end_time
				? true
				: false,
		isEnabledMinOrderValue: data.U_min_order_value ? true : false,
		isSelectedBrands: false,
		isSelectedLinesAndSublines: false,
		isSelectedItems: false,
		isAllItems: true,
	}

	const brands = data.Brand.map((brand) => {
		return brand.FirmCode
	})
	if (brands) {
		if (brands.length > 0) {
			dataFormatted.brands = brands
			dataFormatted.isSelectedBrands = true
			dataFormatted.isAllItems = false
		}
	}

	const items = data.Item.map((item) => {
		return item.ItemCode
	})
	if (items) {
		if (items.length > 0) {
			dataFormatted.items = items
			dataFormatted.isSelectedItems = true
			dataFormatted.isAllItems = false
		}
	}

	const lines = data.Line.filter(
		(line) => !data.SubLine.some((subline) => subline.LineCode === line.Code)
	).map((line) => line.Code)

	if (lines) {
		if (lines.length > 0) {
			dataFormatted.lines = lines
		}
	}

	const sublines = data.SubLine.map((subline) => {
		return `${subline.LineCode}-${subline.Code}`
	})
	if (sublines) {
		if (sublines.length > 0) {
			dataFormatted.sublines = sublines
		}
	}

	const linesAndSublines = [...lines, ...sublines]
	if (linesAndSublines) {
		if (linesAndSublines.length > 0) {
			dataFormatted.linesAndSublines = linesAndSublines
			dataFormatted.isSelectedLinesAndSublines = true
			dataFormatted.isAllItems = false
		}
	}

	return dataFormatted
}

export const formatDataCreateCoupon = (data) => {
	const dataOutState = { ...data }
	const formattedStartDate = dayjs(dataOutState['start-end-date'][0]).format(
		'YYYY-MM-DD'
	)
	const formattedEndDate = dayjs(dataOutState['start-end-date'][1]).format(
		'YYYY-MM-DD'
	)

	const dataFormatted = {
		...data,
		start_date: formattedStartDate,
		end_date: formattedEndDate,
	}

	if (dataOutState['start-end-time']) {
		const formattedStartTime = dayjs(dataOutState['start-end-time'][0]).format(
			'HH:mm:ss'
		)
		const formattedEndTime = dayjs(dataOutState['start-end-time'][1]).format(
			'HH:mm:ss'
		)

		dataFormatted.start_time = formattedStartTime
		dataFormatted.end_time = formattedEndTime
	}

	if (dataOutState.isSelectedLinesAndSublines) {
		const lines = []
		const sublines = []
		dataOutState.linesAndSublines.forEach((item) => {
			if (item.includes('-')) {
				const [, right] = item.split('-')
				sublines.push(right)
			} else {
				lines.push(item)
			}
		})

		dataFormatted.lines = lines
		dataFormatted.sublines = sublines
	} else {
		delete dataFormatted.linesAndSublines
		delete dataFormatted.lines
		delete dataFormatted.Sublines
	}

	if (dataOutState.isSelectedItems) {
		const items = []
		dataOutState.items.forEach((item) => {
			if (typeof item === 'string') {
				items.push(item)
			}
		})

		dataFormatted.items = items
	} else {
		delete dataFormatted.items
	}

	if (dataOutState.isSelectedBrands) {
		const brands = []
		dataOutState.brands.forEach((brand) => {
			if (typeof brand === 'number') {
				brands.push(brand)
			}
		})

		dataFormatted.brands = brands
	} else {
		delete dataFormatted.brands
	}

	delete dataFormatted.linesAndSublines
	delete dataFormatted['start-end-date']
	delete dataFormatted['start-end-time']
	delete dataFormatted.isAllItems
	delete dataFormatted.isEnabledTime
	delete dataFormatted.isEnabledMaxDiscountValue
	delete dataFormatted.isEnabledMinOrderValue
	dataFormatted.discount_value = Number(dataFormatted.discount_value)
	dataFormatted.max_usage = Number(dataFormatted.max_usage)
	dataFormatted.max_money_discount =
		!dataFormatted.max_money_discount || dataFormatted.max_money_discount === ''
			? null
			: Number(dataFormatted.max_money_discount)
	dataFormatted.min_order_value =
		!dataFormatted.min_order_value || dataFormatted.min_order_value === ''
			? null
			: Number(dataFormatted.min_order_value)

	return dataFormatted
}
