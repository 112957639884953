import React, { useEffect, useState } from 'react'
import ImageWithBasePath from '../../../core/img/imagewithbasebath'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { all_routes } from '../../../Router/all_routes'
import { useNavigate } from 'react-router-dom'
import {
	selectAuthenticationError,
	selectAuthenticationIsAuthenticated,
	selectAuthenticationIsLoading,
} from '../../../redux/selectors/authentication'
import { login } from '../../../redux/authentication'
import { resetCatalog } from '../../../redux/catalog'

const SignIn = () => {
	const dispatch = useDispatch()
	const authenticationIsLoading = useSelector(selectAuthenticationIsLoading)
	const authenticationError = useSelector(selectAuthenticationError)
	const isAuthenticated = useSelector(selectAuthenticationIsAuthenticated)
	const [userId, setUserId] = useState('')
	const [userPass, setUserPass] = useState('')
	const [isPasswordVisible, setPasswordVisible] = useState(false)
	const navigate = useNavigate()

	const handleLogin = (event) => {
		event.preventDefault()
		dispatch(resetCatalog())
		dispatch(login({ userId, userPass }))
	}

	useEffect(() => {
		if (isAuthenticated) {
			navigate('/')
		}
	}, [isAuthenticated])

	const togglePasswordVisibility = () => {
		setPasswordVisible((prevState) => !prevState)
	}

	const route = all_routes
	return (
		<div className='login-wrapper bg-img'>
			<div className='login-content'>
				<form
					onSubmit={handleLogin}
					style={{
						width: '50%',
					}}>
					<div className=''>
						<div className='login-logo logo-normal'>
							<ImageWithBasePath
								src='assets/img/login-images/lapape-logo.png'
								alt='img'
							/>
						</div>
						<Link to={route.dashboard} className='login-logo logo-white'>
							<ImageWithBasePath src='assets/img/logo-white.png' alt />
						</Link>
						<div className='login-userheading'>
							<h3>Login</h3>
							<h4>Inicia sesión con tus credenciales.</h4>
						</div>
						<div className='form-login mb-3'>
							<label className='form-label'>Id</label>
							<div className='form-addons'>
								<input
									disabled={authenticationIsLoading}
									type='text'
									className='form-control'
									value={userId}
									onChange={(e) => setUserId(e.target.value)}
								/>
							</div>
						</div>
						<div className='form-login mb-3'>
							<label className='form-label'>Contraseña</label>
							<div className='pass-group'>
								<input
									disabled={authenticationIsLoading}
									type={isPasswordVisible ? 'text' : 'password'}
									className='pass-input form-control'
									value={userPass}
									onChange={(e) => setUserPass(e.target.value)}
								/>
								<span
									className={`fas toggle-password ${
										isPasswordVisible ? 'fa-eye' : 'fa-eye-slash'
									}`}
									onClick={togglePasswordVisibility}></span>
							</div>
						</div>
						{authenticationError && (
							<span className='text-danger'>{authenticationError.message}</span>
						)}
						<div className='form-login'>
							<button
								type='submit'
								className='btn btn-login'
								disabled={authenticationIsLoading}>
								Iniciar Sesión
								{authenticationIsLoading && (
									<div
										className='spinner-border spinner-border-sm ms-1'
										role='status'>
										<span className='sr-only'>Loading...</span>
									</div>
								)}
							</button>
						</div>

						<div className='login-footer'>
							<div className='my-4 d-flex justify-content-center align-items-center copyright-text'>
								<p>
									Thoro Enterprises de México S.A. de C.V. © Copyright{' '}
									{new Date().getFullYear().toString()}
								</p>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	)
}

export default SignIn
