import React from 'react'
import {
	DatePicker,
	Form,
	Input,
	InputNumber,
	Select,
	Switch,
	TimePicker,
	Transfer,
	TreeSelect,
} from 'antd'
import PropTypes from 'prop-types'

FormControl.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	rules: PropTypes.array,
	type: PropTypes.string,
	attributes: PropTypes.object,
	children: PropTypes.node,
}

export default function FormControl({
	name,
	label,
	rules,
	type,
	attributes,
	children,
}) {
	let controlComponent

	switch (type) {
		case 'input':
			controlComponent = <Input {...attributes} />
			break
		case 'textarea':
			controlComponent = <Input.TextArea {...attributes} />
			break
		case 'inputnumber':
			controlComponent = <InputNumber {...attributes} />
			break
		case 'select':
			controlComponent = <Select {...attributes} />
			break
		case 'daterangepicker':
			controlComponent = <DatePicker.RangePicker {...attributes} />
			break
		case 'date':
			controlComponent = <DatePicker {...attributes} />
			break
		case 'timerangepicker':
			controlComponent = <TimePicker.RangePicker {...attributes} />
			break
		case 'switch':
			controlComponent = <Switch {...attributes} />
			break
		case 'transfer':
			controlComponent = <Transfer {...attributes} />
			break
		case 'treeselect':
			controlComponent = <TreeSelect {...attributes} />
			break
		case 'search':
			controlComponent = <Input.Search {...attributes} />
			break
		default:
			controlComponent = null
	}

	return (
		<Form.Item {...(name ? { name } : {})} label={label} rules={rules}>
			{controlComponent}
			{children ? children : <></>}
		</Form.Item>
	)
}
