import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	changeScannedProduct,
	changeComponentShow,
} from '../../../redux/catalog'
import { showScannedProductConfig } from '../../../utils/var'
import ScannedItem from '../layouts/ScannedItem'
import { selectIsShowScanedProduct } from '../../../redux/selectors/catalog'
import ImageWithBasePath from '../../../core/img/imagewithbasebath'

const Scanner = () => {
	const [timer, setTimer] = useState(null)
	const dispatch = useDispatch()
	const isShowScanedProduct = useSelector(selectIsShowScanedProduct)
	const [firstScan, setFirstScan] = useState(true)
	const inputRef = useRef(null)
	const [scannedProduct, setScannedProduct] = useState('')

	useEffect(() => {
		const handleKeyPress = (e) => {
			if (e.key === 'Enter') {
				clearTimeout(timer)
				setTimer(setTimeout(() => {

					setFirstScan(true)
				}, 15000))
				getProductByCodeBar()
			}
		}
		window.addEventListener('keydown', handleKeyPress)
		
		return () => {
			window.removeEventListener('keydown', handleKeyPress)
		}
	}, [scannedProduct])

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus()
		}

		const handleClick = (e) => {
			if (inputRef.current && !inputRef.current.contains(e.target)) {
				inputRef.current.focus()
			}
		}
		document.addEventListener('click', handleClick)
		return () => {
			document.removeEventListener('click', handleClick)
		}
	}, [])

	const getProductByCodeBar = () => {
		if (scannedProduct && scannedProduct !== '0') {
			dispatch(changeScannedProduct(scannedProduct))
			dispatch(changeComponentShow(showScannedProductConfig))
			setScannedProduct('')
			setFirstScan(false) // Cambiar a false cuando se escanee un producto

			if (inputRef.current) {
				inputRef.current.focus()
			}
		}
	}

	return (
		<>
			<div style={{ margin: '7%' }}>
				<input
					ref={inputRef}
					id='searchCodeBar'
					type='text'
					placeholder='Código de barras'
					className='form-control form-control-sm formsearch hidden-input'
					value={scannedProduct}
					onChange={(e) => setScannedProduct(e.target.value)}
				/>
				{firstScan ? (
					<div className='row w-100 align-items-start pos-slick-item'>
						<div
							id='campo'
							style={{
								paddingTop: '200px',
								paddingLeft: '40px',
								textAlign: 'center',
							}}>
							<h2>
								<p>
									Escanee o ingrese el código del artículo para empezar a
									registrar
								</p>
							</h2>
							<ImageWithBasePath
								src='assets/img/categories/category-01.png'
								alt='Categories'
							/>
						</div>
					</div>
				) : (
					<div style={{ marginLeft: '5%', marginRight: '5%' }}>
						{isShowScanedProduct && <ScannedItem />}
					</div>
				)}
			</div>
		</>
	)
}

export default Scanner