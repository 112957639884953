import React, { useEffect, useState } from 'react'
import ImageWithBasePath from '../../../../core/img/imagewithbasebath'
import { Link } from 'react-router-dom'
import { Filter, Sliders, Box } from 'react-feather'
import Select from 'react-select'
import DashboardLayout from '../../../../layouts/DashboardLayout'
import PageTtle from '../../../../layouts/PagesTitle'
import { apiUrl } from '../../../../utils/var'
import { Print } from '../../../../utils/functions/printTicket'
import {
	showErrorPrint,
	showSweetAlertLoadingAnimation,
	showSweetAlertSuccess2,
} from '../components/Alerts';
import Swal from 'sweetalert2'
import { Button, Table } from 'antd'
import { convertMilitaryTimeToStandardTimeFormat } from '../../../../utils/functions/date'
import { formatCurrency } from '../../../../utils/functions/money'

const Withdrawals = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState(null)
	const [items, setItems] = useState({ items: [] })
	const [errorPrinting, setErrorPrinting] = useState(null);
	const [loadingPrinting, setLoadingPrinting] = useState(null);
	const [successPrinting, setSuccessPrinting] = useState(null);

	const getWithdraws = async () => {
		setIsLoading(true)

		const requestItems = await fetch(`${apiUrl}/api/withdraw/`, {
			method: 'GET',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
			},
		})

		const responseItems = await requestItems.json()
		if (!requestItems.ok) {
			setError(responseItems.message)
		} else {
			setError(null)
			setItems(responseItems)
			console.log(responseItems)
		}
		setIsLoading(false)
	}


	const handlePrintRetiro = async (idWithdrawal, LineId) => {
		setLoadingPrinting(true);
		setErrorPrinting(null);
		console.log('Imprimiendo ticket de retiro', loadingPrinting)
		console.log('line id,', LineId)
		try {
			const responsePrint = await Print(
				{ "Type_XML": "WITHDRAWAL", "LineId": LineId },
				`${apiUrl}/api/print/withdraw/${idWithdrawal}`,
				'POST')
			if (responsePrint.statusCode !== 200) {
				console.log(responsePrint);
				throw new Error(`Error al imprimir el ticket: ${responsePrint.message} - ${responsePrint.errors ?? ''}`);
			}

			setSuccessPrinting(true)
			console.log("Respuesta de impresión:", responsePrint);

		} catch (error) {
			console.error("Error en catch:", error);
			setErrorPrinting(error.message); // Esto activará el useEffect para mostrar el error
		} finally {
			setLoadingPrinting(false); // Actualizamos loadingPrinting al finalizar
		}
	}

	useEffect(() => {
		getWithdraws()
	}, [])


	useEffect(() => {
		if (errorPrinting) {
			Swal.close();
			showErrorPrint(errorPrinting);
		}
	}, [errorPrinting]);

	useEffect(() => {
		if (loadingPrinting && !errorPrinting) {
			showSweetAlertLoadingAnimation();
		} else if (!loadingPrinting && !errorPrinting && successPrinting) {
			Swal.close();
			showSweetAlertSuccess2()
		}
	}, [loadingPrinting, errorPrinting, successPrinting]);

	useEffect(() => {
		console.log(error)
	}, [error])


	const [searchText, setSearchText] = useState('')
	const handleSearch = (e) => {
		setSearchText(e.target.value)
	}

	const [isFilterVisible, setIsFilterVisible] = useState(false)
	const toggleFilterVisibility = () => {
		setIsFilterVisible((prevVisibility) => !prevVisibility)
	}

	const options = [
		{ value: 'sortByDate', label: 'Sort by Date' },
		{ value: '140923', label: '14 09 23' },
		{ value: '110923', label: '11 09 23' },
	]

	const productOptions = [
		{ value: 'chooseProduct', label: 'Choose Product' },
		{ value: 'boldV3.2', label: 'Bold V3.2' },
		{ value: 'nikeJordan', label: 'Nike Jordan' },
	]

	const columns = [
		{
			title: 'Corte',
			dataIndex: 'DocEntry',
			align: 'center',
			sorter: (a, b) => a.DocEntry - b.DocEntry,
		},
		{
			title: '# de Retiro',
			dataIndex: 'LineIdRetiro',
			align: 'center',
			render: (retiro) => (
				<span>Retiro #{retiro}</span>
			)
		},
		{
			title: 'Fecha de creación',
			dataIndex: 'U_CSM_FechaRetiro',
			align: 'center',
			render: (text) => (
				<div>
					<span>{(text ?? '').substring(0, 10)}</span>
				</div>
			),
			sorter: (a, b) => {
				const dateA = new Date(a.U_CSM_FechaRetiro).getTime();
				const dateB = new Date(b.U_CSM_FechaRetiro).getTime();
				return (dateA || 0) - (dateB || 0);
			},
		},
		{
			title: 'Hora',
			dataIndex: 'U_CSM_HoraRetiro',
			align: 'center',
			sorter: (a, b) => a.U_CSM_HoraRetiro - b.U_CSM_HoraRetiro,
			render: (time) => {
				return convertMilitaryTimeToStandardTimeFormat(time)
			}
		},
		{
			title: 'Cajero',
			dataIndex: 'Cashier',
			align: 'center',
			sorter: (a, b) => a.Cashier.localeCompare(b.Cashier),
		},
		{
			title: 'Gerente',
			dataIndex: 'Manager',
			align: 'center',
			sorter: (a, b) => a.Manager.localeCompare(b.Manager),
		},
		{
			title: 'Saldo Acumulado',
			dataIndex: 'U_CSM_SaldoAcumulado',
			align: 'center',
			sorter: (a, b) => a.U_CSM_SaldoAcumulado - b.U_CSM_SaldoAcumulado,
			render: (text) => {
				return formatCurrency(text);
			}
		},
		{
			title: 'Saldo Retirado',
			dataIndex: 'U_CSM_SaldoRetirado',
			align: 'center',
			sorter: (a, b) => a.U_CSM_SaldoRetirado - b.U_CSM_SaldoRetirado,
			render: (text) => {
				return formatCurrency(text);
			}
		},
		{
			title: 'Acciones',
			dataIndex: 'DocEntry',
			align: 'center',
			render: (_, data) => (
				<div className='action-table-data'>
					<div className='edit-delete-action'>
						<div className='input-block add-lists'></div>
						
						<Button
							onClick={() => {
								handlePrintRetiro(data.DocEntry, data.LineIdRetiro)
							}}
							type='primary'
							ghost>
							<i className='feather-printer' />
						</Button>
					</div>
				</div>
			),
		},
	]

	return (
		<DashboardLayout>
			<PageTtle
				maintitle='Retiros'
				subtitle='Registro de todos los retiros realizados en La Pape '
			/>
			{/* /product list */}
			<div className='card table-list-card'>
				<div className='card-body'>
					<div className='table-top'>
						<div className='search-set'>
							<div className='search-input'>
								<input
									type='text'
									placeholder='Search'
									className='form-control form-control-sm formsearch'
									aria-controls='DataTables_Table_0'
									value={searchText}
									onChange={handleSearch}
								/>
								<Link to className='btn btn-searchset'>
									<i data-feather='search' className='feather-search' />
								</Link>
							</div>
						</div>

						<div className='search-path'>

							<Link
								className={`btn btn-filter ${isFilterVisible ? 'setclose' : ''
									}`}
								id='filter_search'>
								<Filter
									className='filter-icon'
									onClick={toggleFilterVisibility}
								/>
								<span onClick={toggleFilterVisibility}>
									<ImageWithBasePath
										src='assets/img/icons/closes.svg'
										alt='img'
									/>
								</span>
							</Link>
						</div>
						<div className='form-sort stylewidth'>
							<Sliders className='info-img' />

							<Select
								classNamePrefix='react-select'
								className='img-select'
								options={options}
								placeholder='Sort by Date'
							/>
						</div>
					</div>
					{/* /Filter */}
					<div
						className={`card${isFilterVisible ? ' visible' : ''}`}
						id='filter_inputs'
						style={{ display: isFilterVisible ? 'block' : 'none' }}>
						<div className='card-body pb-0'>
							<div className='row'>
								<div className='col-lg-3'>
									<div className='input-blocks'>
										<Box className='info-img' />
										<Select
											className='img-select'
											classNamePrefix='react-select'
											options={productOptions}
										/>
									</div>
								</div>
								{/* <div className="col-lg-3">
                                    <div className="input-blocks">
                                        <Zap className="info-img" />
                                        <Select
                                            className="img-select"
                                            classNamePrefix="react-select"
                                            options={categoryOptions}
                                        />
                                    </div>
                                </div> */}
								<div className='col-lg-6 col-sm-6 col-12'>
									<div className='input-blocks'>
										<Link className='btn btn-filters ms-auto'>
											{' '}
											<i
												data-feather='search'
												className='feather-search'
											/>{' '}
											Search{' '}
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* /Filter */}
					<div className='table-responsive'>
						<Table
							rowKey='DocEntry'
							className='table datanew dataTable no-footer'
							// rowSelection={rowSelection}
							columns={columns}
							dataSource={items.data}
							// rowKey={(record) => record.ItemCode}
							loading={isLoading}

						/>
					</div>
				</div>
			</div>
			{/* /product list */}
		</DashboardLayout>
	)
}

export default Withdrawals
