import { createSelector } from '@reduxjs/toolkit'
import { selectCouponCode } from './coupon'
import Big from 'big.js'

export const selectCart = (state) => state.cart.items

export const selectCartItemsCount = createSelector([selectCart], (items) =>
	items.reduce((count, item) => (count += item.quantity), 0)
)
export const selectCartSubtotal = createSelector([selectCart], (items) =>
	items.reduce((subtotal, item) => {
		const subtotalBig = new Big(subtotal)
		const price = item.AvgPriceLiquidation
			? Big(item.AvgPriceLiquidation)
			: Big(item.AvgPrice)
		const quantity = Big(item.quantity ?? 0)
		const itemTotal = price.times(quantity)
		const sub = new Big(subtotalBig.plus(itemTotal)).toNumber()
		return sub
	}, Big(0))
)
export const selectCartDiscount = createSelector(
	[selectCouponCode, selectCart],
	(discountCode, cart) => {
		if (discountCode) {
			const discount = cart.reduce((accumulator, item) => {
				const couponDiscount = Big(item.couponAmountDiscounted ?? 0)
				const quantity = Big(item.quantity ?? 0)
				const itemTotalDiscount = couponDiscount.times(quantity)
				return accumulator.plus(itemTotalDiscount)
			}, Big(0))

			return discount.toNumber()
		}

		return Big(0).toNumber()
	}
)
export const selectCartTotal = createSelector(
	[selectCartSubtotal, selectCartDiscount],
	(subtotal, discount) => {
		const total = Big(subtotal).minus(Big(discount))
		return total.toNumber()
	}
)

export const selectCartIsShowDeleteCartModal = (state) =>
	state.cart.isShowDeleteCartModal

export const selectDiscontLiquidation = createSelector([selectCart], (cart) =>
	cart.reduce((liq, item) => {
		const liquidation = new Big(liq)
		if (item.AvgPriceLiquidation) {
			const avgPrice = Big(item.AvgPrice)
			const avgPriceLiquidation = Big(item.AvgPriceLiquidation)
			const quantity = Big(item.quantity ?? 0)
			const discount = avgPrice.minus(avgPriceLiquidation).times(quantity)

			return liquidation.plus(discount).toNumber()
		}
		return liquidation
	}, Big(0))
)
