import React from 'react'
import AuthenticatedRoute from './AuthenticatedRoute'
import AuthorizationRoute from './AuthorizationRoute'
import MachineConfiguredRoute from './MachineConfiguredRoute'
import HeaderLayout from '../layouts/HeaderLayout'
import HeaderSidebarLayout from '../layouts/HeaderSidebarLayout'
import NotMachineConfigured from '../pages/NotMachineConfigured'
import {
	selectAuthenticationIsAuthenticated,
	selectAuthenticationUser,
} from '../redux/selectors/authentication'
import { useSelector } from 'react-redux'
import { Route, Routes, Navigate } from 'react-router-dom'
import { manager_role, admin_role, ROUTES } from '../utils/var'
import { verifyUserRole } from '../utils/functions/authorization'

// Importaciones de componentes de página
import SignIn from '../feature-module/sales/Authentication/signin'
import PosAuto from '../features/pos/pages/posauto'
import Scanner from '../features/checador/pages/scanner'
import Dashboard from '../features/dashboard/pages/Dashboard'
import ManagePrice from '../feature-module/stock/manageprice'
import CorteCaja from '../features/cash-count/layout/corte-caja'
//import Cupones from '../feature-module/coupons/coupons'
import Client from '../pages/Client'
import Retiro from '../feature-module/hrm/retiro'
import Settings from '../pages/Settings'
import CashCountHistory from '../features/history-reports/cash-counts/pages/CashCountHistory'
import Inventory from '../features/inventory/pages/Inventory'
import OrdersReports from '../features/history-reports/orders/pages/Orders'
import Withdrawals from '../features/history-reports/withdrawals/pages/Withdrawals'
import Coupons from '../pages/Coupons'
import GeneralReports from '../features/history-reports/general-movements/pages/GeneralMovements'
import FingerPrint from '../features/pinpad/pages/Pinpad'
import PrinterSettings from '../features/web-settings/printer/pages/PrinterSettings'
import PinpadMovements from '../features/pinpad/pages/PinpadMovements'

const AppRoutes = () => {
	const isAuthenticated = useSelector(selectAuthenticationIsAuthenticated)
	const user = useSelector(selectAuthenticationUser)
	const destinationRoute = isAuthenticated
		? verifyUserRole(user, [manager_role, admin_role])
			? ROUTES.ADMIN_DASHBOARD
			: ROUTES.CASHIER_PURCHASE
		: ROUTES.LOGIN

	return (
		<Routes>
			<Route path={ROUTES.HOME} element={<Navigate to={destinationRoute} />} />
			<Route path={ROUTES.LOGIN} element={<SignIn />} />
			<Route path={ROUTES.SCANNER} element={<Scanner />} />

			<Route element={<AuthenticatedRoute />}>
				<Route element={<HeaderLayout />}>
					{/* <Route path={ROUTES.CLIENT_SCANNER} element={<Scanner />} /> */}
					<Route element={<MachineConfiguredRoute />}>
						<Route path={ROUTES.CASHIER_PURCHASE} element={<PosAuto />} />
					</Route>
				</Route>

				<Route
					path={ROUTES.MACHINE_CONFIGURE_WARNING}
					element={<NotMachineConfigured />}
				/>

				<Route
					element={
						<AuthorizationRoute requiredRoles={[manager_role, admin_role]} />
					}>
					<Route element={<HeaderSidebarLayout />}>
						<Route
							path={ROUTES.ADMIN_DASHBOARD}
							element={<Dashboard key={ROUTES.ADMIN_DASHBOARD} />}
						/>
						<Route
							path={ROUTES.ADMIN_MANAGE_PRICE}
							element={<ManagePrice key={ROUTES.ADMIN_MANAGE_PRICE} />}
						/>
						<Route
							path={ROUTES.ADMIN_CASH_COUNT}
							element={<CorteCaja key={ROUTES.ADMIN_CASH_COUNT} />}
						/>
						<Route
							path={ROUTES.ADMIN_COUPONS}
							element={<Coupons key={ROUTES.ADMIN_COUPONS} />}
						/>
						<Route
							path={ROUTES.ADMIN_CASH_WITHDRAWAL}
							element={<Retiro key={ROUTES.ADMIN_CASH_WITHDRAWAL} />}
						/>

						<Route
							path={ROUTES.ADMIN_INVENTORY}
							element={<Inventory key={ROUTES.ADMIN_INVENTORY} />}
						/>
						<Route
							path={ROUTES.ADMIN_ORDERS}
							element={<OrdersReports key={ROUTES.ADMIN_ORDERS} />}
						/>
						<Route
							path={ROUTES.ADMIN_WITHDRAWALS}
							element={<Withdrawals key={ROUTES.ADMIN_WITHDRAWALS} />}
						/>

						<Route
							path={ROUTES.ADMIN_MANAGE_PINPAD}
							element={<FingerPrint />}
						/>
						<Route
							path={ROUTES.ADMIN_GENERAL_MOVEMENTS}
							element={<GeneralReports />}
						/>
						<Route
							path={ROUTES.ADMIN_PINPAD_MOVEMENTS}
							element={<PinpadMovements />}
						/>

						<Route
							path={ROUTES.ADMIN_CASH_COUNT_HISTORY}
							element={
								<CashCountHistory key={ROUTES.ADMIN_CASH_COUNT_HISTORY} />
							}
						/>

						{/* ADMINISTRACION DEL EQUIPO */}
						<Route
							path={ROUTES.ADMIN_SETTINGS}
							element={<Settings key={ROUTES.ADMIN_SETTINGS} />}
						/>

						<Route
							path={ROUTES.ADMIN_PRINTER_SETTINGS}
							element={<PrinterSettings />}
						/>

						{/* ADMINISTRACION DEL EQUIPO */}
					</Route>
				</Route>

				<Route path={ROUTES.CLIENT_PURCHASE} element={<Client />} />
			</Route>
		</Routes>
	)
}

export default AppRoutes
