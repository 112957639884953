import React from 'react'
import { admin_role, manager_role, ROUTES } from '../../utils/var'
import * as Icon from 'react-feather'

export const SidebarDataPape = [
	{
		label: 'Punto de venta',
		submenuOpen: true,
		submenuHdr: 'Menu',
		showSubRoute: false,
		roles: [manager_role, admin_role],
		submenuItems: [
			{
				label: 'Dashboard',
				link: ROUTES.ADMIN_DASHBOARD,
				icon: <Icon.Grid />,
				showSubRoute: false,
				submenu: false,
			},
			{
				label: 'POS',
				link: ROUTES.CASHIER_PURCHASE,
				icon: <Icon.ShoppingBag />,
				showSubRoute: false,
				submenu: false,
			},
		],
	},
	{
		label: 'Arqueo de caja',
		submenuOpen: true,
		showSubRoute: false,
		submenuHdr: 'Admin',
		roles: [manager_role, admin_role],
		submenuItems: [
			{
				label: 'Apertura y Corte de caja',
				link: ROUTES.ADMIN_CASH_COUNT,
				icon: <Icon.DollarSign />,
				showSubRoute: false,
				submenu: false,
			},
		],
	},
	{
		label: 'Administración de La Pape',
		submenuOpen: true,
		showSubRoute: false,
		submenuHdr: 'Admin',
		roles: [manager_role, admin_role],
		submenuItems: [
			{
				label: 'Cupones',
				link: ROUTES.ADMIN_COUPONS,
				icon: <Icon.ShoppingCart />,
				showSubRoute: false,
				submenu: false,
			},
			
			{
				label: 'Inventario',
				link: ROUTES.ADMIN_INVENTORY,
				icon: <Icon.Box />,
				showSubRoute: false,
				submenu: false,
			},
		],
	},
	{
		label: 'Historiales y reportes',
		submenuOpen: true,
		showSubRoute: false,
		submenuHdr: 'Historiales y reportes',
		roles: [manager_role, admin_role],
		submenuItems: [
			{
				label: 'Movimientos Generales',
				link: ROUTES.ADMIN_GENERAL_MOVEMENTS,
				icon: <Icon.FileText />,
				showSubRoute: false,
			},
			{
				label: 'Movimientos en Pinpad',
				link: ROUTES.ADMIN_PINPAD_MOVEMENTS,
				icon: <Icon.CreditCard />,
				showSubRoute: false,
			},
			{
				label: 'Cortes de caja',
				link: ROUTES.ADMIN_CASH_COUNT_HISTORY,
				icon: <Icon.DollarSign />,
				showSubRoute: false,
			},
			{
				label: 'Ordenes',
				link: ROUTES.ADMIN_ORDERS,
				icon: <Icon.FileText />,
				showSubRoute: false,
			},
			{
				label: 'Retiros',
				link: ROUTES.ADMIN_WITHDRAWALS,
				icon: <Icon.FileText />,
				showSubRoute: false,
			},
		],
	},
	{
		label: 'Administración del Equipo',
		submenuOpen: true,
		showSubRoute: false,
		submenuHdr: 'Admin',
		roles: [admin_role, manager_role],
		submenuItems: [
			{
				label: 'Asignar equipo',
				link: ROUTES.ADMIN_SETTINGS,
				icon: <Icon.Settings />,
				showSubRoute: false,
				submenu: false,
			},
			{
				label: 'Métodos pago/Pinpad',
				link: ROUTES.ADMIN_MANAGE_PINPAD,
				icon: <Icon.Smartphone />,
				showSubRoute: false,
				submenu: false,
			},
			{
				label: 'Impresora de tickets',
				link: ROUTES.ADMIN_PRINTER_SETTINGS,
				icon: <Icon.Printer />,
				showSubRoute: false,
				submenu: false,
			},
		],
	},
]
