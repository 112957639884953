import React, { useContext, useState } from 'react'
import DashboardLayout from '../../../layouts/DashboardLayout'
import PageTitle from '../../../layouts/PagesTitle'
import { Tool } from 'feather-icons-react/build/IconComponents'
import { Link } from 'react-router-dom'
import { PinpadProvider } from '../provider/PinpadContext'
import Swal from 'sweetalert2'
import { PinpadContext } from '../../../provider/Pinpad'
import ModalConfigPinpad from '../layouts/ModalConfigPinpad'
import FormControl from '../../../components/FormControl'

const FingerPrintContent = () => {
	const { isPinpadConfigured, portSelected, clearPinpad } =
		useContext(PinpadContext)
	const [modalVisible, setModalVisible] = useState(false)

	const handleShowModal = () => {
		setModalVisible(true)
	}

	const handleCloseModal = () => {
		setModalVisible(false)
	}

	const handleClearPinpad = () => {
		if (isPinpadConfigured || portSelected) {
			handleDeleteConfirmation()
		}
	}

	const handleDeleteConfirmation = async () => {
		const result = await Swal.fire({
			title: '¿Estás seguro?',
			text: 'Esta acción eliminará la configuración del pinpad.',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sí, eliminar!',
			cancelButtonText: 'Cancelar',
		})

		if (result.isConfirmed) {
			clearPinpad()
			Swal.fire(
				'Eliminado!',
				'La configuración del pinpad ha sido eliminada.',
				'success'
			)
		}
	}

	return (
		<>
			<PageTitle maintitle='Configuración de los métodos de pago' />
			<div className='col-xxl-4 col-xl-6 col-lg-12 col-md-6 d-flex mt-3'>
				<div className='connected-app-card email-setting d-flex w-100'>
					<ul className='w-100'>
						<li className='flex-column align-items-start'>
							<div className='d-flex align-items-center justify-content-between w-100 mb-2'>
								<div className='security-type d-flex align-items-center'>
									<div className='security-title'>
										<h5>Pinpad Inbursa</h5>
									</div>
								</div>
							</div>
							<p>
								Integración de la PinpadWebApp para giros tipo Retail.
								<br />
								PinPadWeb RETAIL ver. 2.5.0
							</p>
						</li>
						<li>
							<div
								className='d-flex align-items-center justify-content-between w-100'
								style={{
									marginBottom: -24,
								}}>
								<div
									className='integration-btn'
									style={{
										marginBottom: 24,
									}}>
									<Link onClick={handleShowModal}>
										<Tool className='me-2' />
										{isPinpadConfigured ? 'Volver a conectar' : 'Conectar'}
									</Link>
								</div>
								<FormControl
									key='connect_pinpad'
									label={isPinpadConfigured ? 'Conectada' : 'Inactiva'}
									name='connect_pinpad'
									type='switch'
									attributes={{
										onClick: isPinpadConfigured
											? handleClearPinpad
											: handleShowModal,
										disabled: !isPinpadConfigured,
										checked: isPinpadConfigured,
									}}
								/>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<ModalConfigPinpad
				visible={modalVisible}
				onClose={handleCloseModal}
				record={{}}
			/>
		</>
	)
}

const FingerPrint = () => (
	<DashboardLayout>
		<PinpadProvider>
			<FingerPrintContent />
		</PinpadProvider>
	</DashboardLayout>
)

export default FingerPrint
