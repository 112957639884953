import React, { useContext, useEffect, useState } from "react";
import CountUp from "react-countup";
import {
    File,
    User,
    UserCheck,
    ChevronUp,
    RotateCcw,
} from "feather-icons-react/build/IconComponents";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import DashboardContext from "../provider/Dashboard";
import { formatCurrency } from "../../../utils/functions/money";

export const SalesInfo = () => {
    const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    const targetDate = new Date('2024-12-31T23:59:59'); // Fecha objetivo
    const { isLoading, getDashboardValues, data, error } = useContext(DashboardContext);

    useEffect(() => {
        getDashboardValues();
        console.log(data)
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const now = new Date();
            const difference = targetDate - now;

            // Si la diferencia es negativa, la fecha ha pasado
            if (difference <= 0) {
                clearInterval(intervalId);
                setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
                return;
            }

            const seconds = Math.floor((difference / 1000) % 60);
            const minutes = Math.floor((difference / 1000 / 60) % 60);
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));

            setTimeLeft({ days, hours, minutes, seconds });
        }, 1000);

        return () => clearInterval(intervalId);
    }, [targetDate]);

    const formatTime = (time) => {
        return time < 10 ? `0${time}` : time;
    };

    return (
        (isLoading || error) ? <div className="row">Cargando...</div>
            :
            <div className="row">
                <div className="col-md-12">
                    <div className="row sales-cards">
                        <div className="col-xl-6 col-sm-12 col-12">
                            <div className="card d-flex align-items-center justify-content-between default-cover mb-4">
                                <div>
                                    <h6>Ventas Semanales</h6>
                                    <h3>

                                        <span className="counters" data-count={data?.info[0]?.TotalVentas ?? 0}>
                                            {formatCurrency(data?.info[0]?.TotalVentas || 0) ?? 0}
                                        </span>
                                    </h3>
                                    <p className="sales-range">
                                        <span className="text-success">
                                            <ChevronUp className="feather-16" />
                                            48%&nbsp;
                                        </span>
                                        increase compare to last week
                                    </p>
                                </div>
                                <ImageWithBasePath
                                    src="assets/img/icons/weekly-earning.svg"
                                    alt="img"
                                />
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 col-12">
                            <div className="card color-info bg-primary mb-4">
                                <ImageWithBasePath
                                    src="assets/img/icons/total-sales.svg"
                                    alt="img"
                                />
                                <h3>
                                    {"$"}
                                    <CountUp
                                        end={data?.info[0]?.TotalVentas ?? 0}
                                        duration={4}>
                                        +
                                    </CountUp>
                                </h3>
                                <p>Ventas Totales</p>
                                <Link data-bs-toggle="tooltip" className="feather-dashboard">
                                    <RotateCcw className="feather-16" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 col-12">
                            <div className="card color-info bg-info mb-4">
                                <ImageWithBasePath
                                    src="assets/img/icons/purchased-earnings.svg"
                                    alt="img"
                                />
                                <h3>
                                    <CountUp end={data?.info[0]?.NumeroVentasTotales ?? 0} duration={4}>
                                        +
                                    </CountUp>
                                </h3>
                                <p>No. de Ventas</p>

                                <Link data-bs-toggle="tooltip" data-bs-placement="top" className="feather-dashboard">
                                    <RotateCcw className="feather-16" />
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-sm-6 col-12 d-flex">
                    <div className="dash-widget w-100">
                        <div className="dash-widgetimg">
                            <span>
                                <ImageWithBasePath
                                    src="assets/img/icons/dash1.svg"
                                    alt="img"
                                />
                            </span>
                        </div>
                        <div className="dash-widgetcontent">
                            <h5>
                                <CountUp
                                    start={0}
                                    end={data?.info[0]?.VentaPromedio ?? 0}
                                    duration={4} prefix="$" />
                            </h5>
                            <h6>Venta Promedio</h6>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12 d-flex">
                    <div className="dash-widget dash2 w-100">
                        <div className="dash-widgetimg">
                            <span>
                                <ImageWithBasePath
                                    src="assets/img/icons/dash3.svg"
                                    alt="img"
                                />
                            </span>
                        </div>
                        <div className="dash-widgetcontent">
                            <h5>
                                $
                                <CountUp
                                    start={0}
                                    end={data?.info[0]?.VentaMaxima ?? 0}
                                    duration={3} // Duration in seconds
                                    decimals={2}
                                />
                            </h5>
                            <h6>Venta Maxima</h6>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12 d-flex">
                    <div className="dash-widget dash3 w-100">
                        <div className="dash-widgetimg">
                            <span>
                                <ImageWithBasePath
                                    src="assets/img/icons/dash4.svg"
                                    alt="img"
                                />
                            </span>
                        </div>
                        <div className="dash-widgetcontent">
                            <h5>
                                $
                                <CountUp
                                    start={0}
                                    end={data?.info[0]?.VentaMinima ?? 0}
                                    duration={3}
                                    decimals={2}
                                />
                            </h5>
                            <h6>Venta Minima</h6>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12 d-flex">
                    <div className="dash-widget dash1 w-100">
                        <div className="dash-widgetimg">
                            <span>
                                <ImageWithBasePath
                                    src="assets/img/icons/dash2.svg"
                                    alt="img"
                                />
                            </span>
                        </div>
                        <div className="dash-widgetcontent">
                            <h5>
                                <CountUp
                                    start={0}
                                    end={data?.info[0]?.VentaMinima ?? 0}
                                    duration={3} // Duration in seconds
                                />
                            </h5>
                            <h6>Número promedio de articulos vendidos</h6>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-sm-6 col-12 d-flex">
                    <div className="dash-count">
                        <div className="dash-counts">
                            <h4>100</h4>
                            <h5>Customers</h5>
                        </div>
                        <div className="dash-imgs">
                            <User />
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12 d-flex">
                    <div className="dash-count das1">
                        <div className="dash-counts">
                            <h4>110</h4>
                            <h5>Suppliers</h5>
                        </div>
                        <div className="dash-imgs">
                            <UserCheck />
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12 d-flex">
                    <div className="dash-count das2">
                        <div className="dash-counts">
                            <h4>150</h4>
                            <h5>Purchase Invoice</h5>
                        </div>
                        <div className="dash-imgs">
                            <ImageWithBasePath
                                src="assets/img/icons/file-text-icon-01.svg"
                                className="img-fluid"
                                alt="icon"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 col-12 d-flex">
                    <div className="dash-count das3">
                        <div className="dash-counts">
                            <span className="days">{timeLeft.days}</span> días
                            <span className="hours"> {formatTime(timeLeft.hours)}</span> Hrs
                            <span className="minutes"> {formatTime(timeLeft.minutes)}</span> Min
                            <span className="seconds"> {formatTime(timeLeft.seconds)}</span> Sec
                            <h5>Sales Invoice</h5>
                        </div>
                        <div className="dash-imgs">
                            <File />
                        </div>
                    </div>
                </div>
            </div>
    )
}