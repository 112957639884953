import React from 'react'
import ImageWithBasePath from '../../../core/img/imagewithbasebath'
import { PropTypes } from 'prop-types'
import { Button, Modal } from 'react-bootstrap';
import Barcode from 'react-barcode';
import { apiUrl } from '../../../utils/var'


const DetailsItem = (props) => {
    return (<div className="row">
        <div className="col-lg-8 col-sm-12">
            <div className="card">
                <div className="card-body">
                    <div className="bar-code-view">

                        <Barcode value={props.record?.CodeBars ?? 'Sin código'} />
                    </div>
                    <div className="productdetails">
                        <ul className="product-bar">
                            <li>
                                <h4>Product</h4>
                                <h6>{props.record?.ItemName ?? 'NA'} </h6>
                            </li>
                            <li>
                                <h4>Categoría</h4>
                                <h6>{props.record?.LineName ?? 'NA'}</h6>
                            </li>
                            <li>
                                <h4>SubCategoría</h4>
                                <h6>{props.record?.SubLineName ?? 'NA'}</h6>
                            </li>
                            <li>
                                <h4>Marca</h4>
                                <h6>{props.record?.Brand ?? 'NA'}</h6>
                            </li>
                            <li>
                                <h4>Unit</h4>
                                <h6>Piece</h6>
                            </li>
                            <li>
                                <h4>SKU</h4>
                                <h6>{props.record?.ItemCode ?? 'NA'}</h6>
                            </li>

                            <li>
                                <h4>Discount Type</h4>
                                <h6>Percentage</h6>
                            </li>
                            <li>
                                <h4>Price</h4>
                                <h6>1500.00</h6>
                            </li>
                            <li>
                                <h4>Status</h4>
                                <h6>Active</h6>
                            </li>
                            <li>
                                <h4>Descripcion</h4>
                                <h6>
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the industrys
                                    standard dummy text ever since the 1500s,
                                </h6>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-lg-4 col-sm-12">
            <div className="card">
                <div className="card-body">
                    <div className="slider-product-details">
                        <div className="owl-carousel owl-theme product-slide">
                            <div className="slider-product">
                                <ImageWithBasePath
                                    // url={`${apiUrl}/proxy/empleados/imagenes/ITEMS/${props.record?.PicturName}`}
                                    url={`${apiUrl}/proxy/empleados/web/index.php?r=AppMovil/proxy/compress&filename=${props.record?.PicturName}`}
                                    alt="img"
                                />
                                <h4>{props.record?.PicturName ?? 'NA'}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}


const ModalProductDetail = ({ visible, onClose, record }) => {
    return (
        <Modal
            show={visible}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Articulo</Modal.Title>
            </Modal.Header>
            <Modal.Body >

                <div className="content">
                    <div className="page-header">
                        <div className="page-title">
                            <h4>Detalles del Articulo</h4>
                            <h6>Full details of a product</h6>
                        </div>
                    </div>
                    {/* /add */}
                    <DetailsItem record={record}></DetailsItem>
                    {/* /add */}
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onClose}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    )
}

ModalProductDetail.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    record: PropTypes.shape({})
};

DetailsItem.propTypes = {
    record: PropTypes.shape({
        ItemCode: PropTypes.string,
        ItemName: PropTypes.string,
        PicturName: PropTypes.string,
        CodeBars: PropTypes.string,
        CreateTime: PropTypes.string,
        AvgPrice: PropTypes.number,
        AvgPriceLiquidation: PropTypes.number,
        LineCode: PropTypes.string,
        LineName: PropTypes.string,
        SubLineName: PropTypes.string,
        SubLineCode: PropTypes.string,
        Brand: PropTypes.string,
        Liquidacion: PropTypes.string,
        OnHand: PropTypes.number,
        WhsCode: PropTypes.string,
    }),
}
export default ModalProductDetail
