import React, { createContext, useState } from 'react'
import { convertMilitaryTimeToStandardTimeFormat } from '../../../utils/functions/date'
import { apiUrl } from '../../../utils/var'
import { idDevice } from '../../../utils/var'

export const OrdersCashCountContext = createContext(undefined)

// eslint-disable-next-line react/prop-types
const OrdersCashCountProvider = ({ children }) => {
	const [searchOrder, setSearchOrder] = useState('')
	const [orders, setOrders] = useState([])
	const [idOrderCancel, setIdOrderCancel] = useState(null)
	const [isLoadingOrders, setIsLoadingOrders] = useState(false)
	const [errorOrders, setErrorOrders] = useState(null)
	const [itemsModal, setItemsModal] = useState([])
	const [isItemsShow, setIsItemsShow] = useState(false)
	const [isCancelShow, setIsCancelShow] = useState(false)
	const [isLoadingCancelOrder, setIsLoadingCancelOrder] = useState(false)
	const [isShowFeedbackCancelOrder, setIsShowFeedbackCancelOrder] =
		useState(false)
	const [feedbackCancelOrder, setFeedbackCancelOrder] = useState('')
	const [errorCancelOrder, setErrorCancelOrder] = useState(null)

	const formatOrders = (dataResponseGetOrders) => {
		const ordersFormatted = dataResponseGetOrders.map((order) => {
			return {
				idCorte: order.DocEntryCorte,
				idOrder: order.U_CSM_IdOrden,
				DocEntry: order.DocEntry,
				DocTotal: order.DocTotal,
				CANCELED: order.CANCELED,
				DocStatus: order.DocStatus,
				DocDate: order.DocDate,
				DocTime: convertMilitaryTimeToStandardTimeFormat(order.DocTime),
				items: order.items,
				PeyMethod: order.PeyMethod,
				key: order.U_CSM_IdOrden,
			}
		})

		setOrders(ordersFormatted)
	}

	const getOrders = async () => {
		setIsLoadingOrders(true)
		const requestGetOrders = await fetch(`${apiUrl}/api/Orders/${idDevice()}`, {
			method: 'GET',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
			},
		})
		setIsLoadingOrders(false)
		const responseGetOrders = await requestGetOrders.json()

		if (!requestGetOrders.ok) {
			console.error(responseGetOrders.message)

			setErrorOrders({
				status: responseGetOrders.status,
				message: responseGetOrders.message,
			})
		} else {
			const dataResponseGetOrders = responseGetOrders.orders
			formatOrders(dataResponseGetOrders)
		}
	}

	const cancelOrder = async () => {
		setIsLoadingCancelOrder(true)
		const apiEndpoint = `${apiUrl}/api/Orders/${idOrderCancel}/Cancel`

		try {
			const requestCancelOrder = await fetch(apiEndpoint, {
				method: 'GET',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
			})

			const responseCancelOrder = await requestCancelOrder.json()

			if (!requestCancelOrder.ok) {
				console.error(responseCancelOrder.message)

				setErrorCancelOrder({
					status: requestCancelOrder.status,
					message: responseCancelOrder.message,
				})
				setFeedbackCancelOrder(
					`Ocurrió un error, intentelo de nuevo o contacte a soporte. ${responseCancelOrder.message}`
				)
			} else {
				setFeedbackCancelOrder('Orden cancelada correctamente')
				setErrorCancelOrder(null)
			}
		} catch (error) {
			console.error('Error cancelando la orden:', error)

			setErrorCancelOrder({
				status: 'Network error',
				message: error.message,
			})
			setFeedbackCancelOrder(
				`Ocurrió un error de red, intentelo de nuevo o contacte a soporte. ${error.message}`
			)
		} finally {
			setIsLoadingCancelOrder(false)
			setIsCancelShow(false)
			setIsShowFeedbackCancelOrder(true)
		}
	}

	const updateSearchOrder = (event) => {
		setSearchOrder(event.target.value)
	}

	const updateOrders = (value) => {
		formatOrders(value)
	}

	const updateIdOrderCancel = (value) => {
		setIdOrderCancel(value)
	}

	const updateErrorOrders = (value) => {
		setErrorOrders(value)
	}

	const updateIsLoadingOrders = (value) => {
		setIsLoadingOrders(value)
	}

	const updateItemsModal = (value) => {
		setItemsModal(value)
	}

	const updateIsItemsShow = (value) => {
		setIsItemsShow(value)
	}

	const updateIsCancelShow = (value) => {
		setIsCancelShow(value)
	}

	const updateIsLoadingCancelOrder = (value) => {
		setIsLoadingCancelOrder(value)
	}

	const updateIsShowFeedbackCancelOrder = (value) => {
		setIsShowFeedbackCancelOrder(value)
	}

	const updateFeedbackCancelOrder = (value) => {
		setFeedbackCancelOrder(value)
	}

	const updateErrorCancelOrder = (value) => {
		setErrorCancelOrder(value)
	}

	return (
		<OrdersCashCountContext.Provider
			value={{
				searchOrder,
				orders,
				idOrderCancel,
				isLoadingOrders,
				errorOrders,
				itemsModal,
				isItemsShow,
				isCancelShow,
				isLoadingCancelOrder,
				isShowFeedbackCancelOrder,
				feedbackCancelOrder,
				errorCancelOrder,
				updateSearchOrder,
				getOrders,
				cancelOrder,
				updateOrders,
				updateIdOrderCancel,
				updateErrorOrders,
				updateIsLoadingOrders,
				updateItemsModal,
				updateIsItemsShow,
				updateIsCancelShow,
				updateIsLoadingCancelOrder,
				updateIsShowFeedbackCancelOrder,
				updateFeedbackCancelOrder,
				updateErrorCancelOrder,
			}}>
			{children}
		</OrdersCashCountContext.Provider>
	)
}

export default OrdersCashCountProvider
