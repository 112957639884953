import React from 'react'
import { PropTypes } from 'prop-types'
import { ConfigProvider } from "antd"
import esES from 'antd/locale/es_ES';

export const ConfigProviderPape = ({ children }) => {

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#e51885',
                },

            }}
            table={
        
                {
                    
                style: {
                    textAlign: 'center'
                },
                //     expandable: {
                //         expandIcon: {
                //             expandIconBg:'#e51885'
                //     }
                // }
                }
                
        
            }
            
            locale={esES}
        >
            {children}
        </ConfigProvider>
    )

}

ConfigProviderPape.propTypes = {
    children: PropTypes.node,
}