import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import React from 'react'
import { Trash2, ShoppingCart } from 'react-feather'
import { useSelector, useDispatch } from 'react-redux'

import OrderModal from '../layouts/OrderModal'
import Catalog from '../layouts/Catalog'
import WaitScan from '../layouts/WaitScan'
import ScannedProduct from '../layouts/ScannedProduct'
import CartSection from '../layouts/CartSection'

import InformationModal from '../components/InformationModal'
import ModalCleanCart from '../components/ModalCleanCart'
import WithdrawModal from '../components/WithdrawModal'
import Menu from '../components/Menu'

import { toggleOpenModalDeleteCart } from '../redux/cart'
import { selectCartItemsCount } from '../../../redux/selectors/cart'
import {
	selectIsShowCatalog,
	selectIsShowWarning,
	selectIsShowScanedProduct,
	selectIsShowMenu,
} from '../../../redux/selectors/catalog'
/* import MessageChannelProvider from '../../../provider/MessageChannel' */
import { PinpadProvider } from '../../pinpad/provider/PinpadContext'

const PosAuto = () => {
	const dispatch = useDispatch()
	const cartQuantity = useSelector(selectCartItemsCount)
	const isShowMenu = useSelector(selectIsShowMenu)
	const isShowCatalog = useSelector(selectIsShowCatalog)
	const isShowWarning = useSelector(selectIsShowWarning)
	const isShowScanedProduct = useSelector(selectIsShowScanedProduct)

	return (
		<div>
			<div
				className='row align-items-start'
				style={{
					paddingTop: '70px',
				}}>
				<div className='d-flex col-sm-12 col-lg-5 col-xl-6 col-xxl-7'>
					{isShowMenu && <Menu />}
					{isShowWarning && <WaitScan />}
					{isShowCatalog && <Catalog />}
					{isShowScanedProduct && <ScannedProduct />}
				</div>

				<div
					className='d-flex col-sm-12 col-lg-7 col-xl-6 col-xxl-5'
					style={{ height: '89vh', overflowY: 'unset' }}>
					<aside className='product-order-list'>
						<div className='product-added'>
							<div className='head-text d-flex align-items-center justify-content-between'>
								<h4 className='d-flex align-items-center mb-0 text-primary'>
									<ShoppingCart />
									&nbsp;Carrito<span className='count'>{cartQuantity}</span>
								</h4>
								{cartQuantity > 0 && (
									<button
										onClick={() => dispatch(toggleOpenModalDeleteCart(true))}
										className='d-flex align-items-center text-primary bg-transparent border-0'>
										<span className='me-1'>
											<i data-feather='x' className='feather-16' />
										</span>
										Eliminar todos <Trash2 />
									</button>
								)}
							</div>
						</div>

						{cartQuantity > 0 && <CartSection />}
					</aside>
				</div>
			</div>
			<PinpadProvider>
				<OrderModal />
			</PinpadProvider>
			<InformationModal />
			<ModalCleanCart />
			<WithdrawModal />
		</div>
	)
}

export default PosAuto
