import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiUrl, couponConfig } from '../../../utils/var'
import { selectItemsTotal } from './selectors/new-sale'
import { initWithdraw, verifyNeedWithdraw } from '../../../redux/withdraw'
import { handlePrintTicket } from '../../../utils/functions/printTicket'
import Swal from 'sweetalert2'
/* import { initWithdraw, verifyNeedWithdraw } from '../../../redux/withdraw' */

const initialState = {
	visible: false,
	loading: false,
	data: [], // Artículos seleccionados para la venta
	dataOrden: [], //Informacion de la venta
	searchResults: [], // Resultados de búsqueda
	searchLoading: false, // Indicador de carga
	searchError: null, // Errores de búsqueda,
	isNotRegisteredOrder: false,
	folioVenta: null,
	folioVentaCopy: null,
	loadingFolioVenta: false,
	error: null,
	isDataSaleComplete: false,
	cashier: null,
	isCashierSelected: false,
	coupon: couponConfig,
	status: 'noinit',
}

const saleSlice = createSlice({
	name: 'sale',
	initialState,
	reducers: {
		openModal: (state) => {
			state.visible = true
		},
		closeModal: (state) => {
			state.visible = false
		},
		setData: (state, action) => {
			state.data = action.payload
		},
		deleteData: () => {
			return initialState
		},
		deleteStatusData: (state) => {
			state.folioVentaStatus.code = null
			state.folioVentaStatus.status = null
			state.searchStatus
		},
		addItem: (state, action) => {
			const existingItem = state.data.find(
				(item) => item.ItemCode === action.payload
			)

			if (existingItem) {
				if (existingItem.quantity < existingItem.OnHand) {
					existingItem.quantity = Number(existingItem.quantity) + 1
				}
			} else {
				state.data.push({ ...action.payload, quantity: 1 })
			}
		},
		decreaseItem: (state, action) => {
			const actualItem = state.data.find(
				(item) => item.ItemCode === action.payload
			)

			if (actualItem.quantity == 1) {
				state.data = state.data.filter(
					(item) => item.ItemCode !== action.payload
				)
			} else {
				actualItem.quantity--
			}
		},
		removeItem: (state, action) => {
			state.data = state.data.filter((item) => item.ItemCode !== action.payload)
		},
		updateProductQuantity: (state, action) => {
			const { ItemCode, quantity } = action.payload
			const item = state.data.find((item) => item.ItemCode === ItemCode)
			if (item) {
				item.quantity = quantity
			}
		},
		setNumericValue: (state, action) => {
			const { ItemCode, quantity } = action.payload
			const item = state.data.find((item) => item.ItemCode === ItemCode)
			if (item) {
				if (quantity > 0 && quantity <= item.OnHand) {
					item.quantity = quantity
				} else if (quantity <= 0) {
					state.data = state.data.filter((item) => item.ItemCode !== ItemCode)
				}
			}
		},
		setSearchResults: (state, action) => {
			const newItems = action.payload.filter(
				(item) =>
					!state.data.some(
						(existingItem) => existingItem.ItemCode === item.ItemCode
					)
			)
			state.data = [...state.data, ...newItems]
		},
		setFolioVenta: (state, action) => {
			const { folioVenta } = action.payload
			state.folioVenta = folioVenta
		},
		setCashier: (state, action) => {
			const idCashier = action.payload
			state.cashier = idCashier
		},
		updateDataPreliminarInformation: (state, action) => {
			const { status, cashier } = action.payload
			state.isDataSaleComplete = status
			state.cashier = cashier
		},
		updateIsDataSaleComplete: (state, action) => {
			state.isDataSaleComplete = action.payload
		},

		updateCouponCode: (state, action) => {
			return {
				...state,
				coupon: {
					...state.coupon,
					code: action.payload,
				},
			}
		},
		updateCoupon: (state, action) => {
			return {
				...state,
				coupon: action.payload,
			}
		},
		updateCouponError: (state, action) => {
			return {
				...state,
				coupon: {
					...state.coupon,
					errorValidate: action.payload,
				},
			}
		},
		updateFolioVentaCopy: (state, action) => {
			state.folioVentaCopy = action.payload
		},
		updateError: (state, action) => {
			state.error = action.payload
		},
		eraseCoupon: (state) => {
			return {
				...state,
				coupon: couponConfig,
			}
		},
		eraseCart: (state) => {
			state.data = []
		},
		resetSale: () => {
			return initialState
		},
		removeDiscountPricesFromItems: (state) => {
			state.data = state.data.map((item) => {
				if (item.couponPrice) {
					delete item.couponPrice
					delete item.couponDiscountPercentage
					delete item.couponAmountDiscounted
				}

				return item
			})
		},
		changeStatus: (state, action) => {
			state.status = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(searchItem.pending, (state) => {
			state.loading = true
			state.error = null
		})
		builder.addCase(searchItem.fulfilled, (state, action) => {
			state.loading = false
			if (action.payload.length === 0) {
				state.error = 'No se encontraron artículos para la búsqueda.'
			} else {
				const itemToAdd = action.payload[0]
				const existingItem = state.data.find(
					(item) => item.ItemCode === itemToAdd.ItemCode
				)

				if (existingItem) {
					if (existingItem.quantity < itemToAdd.OnHand) {
						existingItem.quantity += 1
					} else {
						state.error =
							'Ya no hay stock del articulo para agregar al carrito.'
					}
				} else {
					state.data.push({ ...itemToAdd, quantity: 1 })
				}
			}
		})
		builder.addCase(searchItem.rejected, (state, action) => {
			state.loading = false
			state.error = action.error.message || 'Ocurrió un error desconocido.'
			state.status = 'error'
		})
		builder.addCase(searchFolio.pending, (state) => {
			state.isNotRegisteredOrder = false
			state.loadingFolioVenta = true
			state.error = null
		})
		builder.addCase(searchFolio.fulfilled, (state, action) => {
			const folioVenta = action.payload.folioVenta

			state.loadingFolioVenta = false
			if (action.payload.data.length === 0) {
				state.isNotRegisteredOrder = true
				state.error = null
				state.folioVenta = folioVenta
			} else {
				state.dataOrden.push({ ...action.payload.data[0] })
				state.isNotRegisteredOrder = false
				state.error =
					'Se encontró una venta registrada asociada a ese folio de banco'
			}
		})
		builder.addCase(searchFolio.rejected, (state, action) => {
			state.loadingFolioVenta = false
			state.error = action.error.message || 'Ocurrió un error desconocido.'
			state.status = 'error'
		})

		builder.addCase(verifyItemsWithCoupon.pending, (state) => {
			state.coupon.isLoadingValidate = true
			state.coupon.errorValidate = null
		})
		builder.addCase(verifyItemsWithCoupon.fulfilled, (state, action) => {
			const responseBody = action.payload

			const responseBodyDetails = responseBody.data.couponDetails[0]
			const responseBodyItems = responseBody.data.items

			if (responseBodyItems) {
				const couponInformation = {
					code: responseBodyDetails.U_code,
					type: responseBodyDetails.U_discount_type === 'P' ? 'per' : 'mon',
					amount: responseBodyDetails.U_discount_value,
				}
				console.log(couponInformation)

				state.coupon = couponInformation
				state.data = state.data.map((item) => {
					const itemToGetCouponPrice = responseBodyItems.find(
						(itemFinded) => itemFinded.itemCode === item.ItemCode
					)

					if (itemToGetCouponPrice) {
						return {
							...item,
							couponPrice: itemToGetCouponPrice.priceAfterCoupon,
							couponDiscountPercentage: itemToGetCouponPrice.percentage,
							couponAmountDiscounted: itemToGetCouponPrice.amountDiscounted,
						}
					} else {
						return {
							...item,
						}
					}
				})
			}

			state.coupon.isLoadingValidate = false
			state.coupon.errorValidate = null
		})
		builder.addCase(verifyItemsWithCoupon.rejected, (state, action) => {
			state.coupon.isLoadingValidate = false
			state.coupon.errorValidate =
				action.error.message ||
				'Ocurrió un error, recargue la página por favor.'
		})

		builder.addCase(transactSale.pending, (state) => {
			state.loading = true
			state.error = null
			state.status = 'executing'
		})
		builder.addCase(transactSale.fulfilled, (state) => {
			state.loading = false
			state.isDataSaleComplete = true
			state.error = null
			state.status = 'completed'
		})
		builder.addCase(transactSale.rejected, (state, action) => {
			console.log(action.error)

			state.error = state.error =
				action.error.message ||
				'Ocurrió un error, recargue la página por favor.'
			state.loading = false
			state.status = 'error'
		})
	},
})

export const transactSale = createAsyncThunk(
	'order/transactSale',
	async (_, { getState, dispatch }) => {
		const { coupon, data, folioVenta, cashier } = getState().sale
		const date = new Date()
		const orderObject = {
			Coupons: [{ Coupon: coupon.code }],
			Items: data.map((item) => {
				return {
					ItemCode: item.ItemCode,
					Quantity: item.quantity,
					price: item.AvgPriceLiquidation
						? item.AvgPriceLiquidation
						: item.AvgPrice,
				}
			}),
			Payment: [
				{
					id: 1,
					Timestamp: (+date).toString(),
					Folio: folioVenta,
					Type: 'Tarjeta',
					Total: selectItemsTotal(getState()),
				},
			],
			Cashier: cashier,
		}

		try {
			const url = `${apiUrl}/api/orders/createOrder`
			const response = await fetch(url, {
				method: 'POST',
				body: JSON.stringify({ ...orderObject }),
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				credentials: 'include',
			})

			const responseBody = await response.json()

			if (!response.ok) {
				let errorMessage
				try {
					const errorBody = responseBody
					errorMessage = errorBody.message || 'Error desconocido'
				} catch {
					errorMessage =
						'Ocurrió un error al traer los productos, recargue el punto de venta o contacte a un encargado.'
				}

				const errorInfo = {
					code: response.status,
					message: errorMessage,
					url: url,
				}

				throw new Error(JSON.stringify(errorInfo))
			}

			const withdrawData = responseBody.withDrawals

			dispatch(verifyNeedWithdraw(responseBody.withDrawals.needWithDrawal))
			dispatch(initWithdraw(withdrawData))

			let isPrinterAvailableTicket = false
			do {
				try {
					if (!isPrinterAvailableTicket) {
						const isHandlePrintTicket = await handlePrintTicket(
							responseBody.draft.DocEntry
						)
						if (isHandlePrintTicket) isPrinterAvailableTicket = true
					}

					if (!isPrinterAvailableTicket) {
						const title = 'Error al imprimir'
						const html =
							'No se pudo imprimir el Ticket <br/>' +
							'¿Desea intentar de nuevo?'
						const result = await Swal.fire({
							title: title,
							html: html,
							icon: 'error',
							showCancelButton: true,
							confirmButtonColor: '#3085d6',
							cancelButtonColor: '#d33',
							confirmButtonText: 'Sí',
							cancelButtonText: 'No',
						})

						if (result.isDismissed) {
							isPrinterAvailableTicket = true
						}
					}
				} catch (error) {
					const errorBody = JSON.parse(error.message)
					const typePrint = errorBody.type
					const title = 'Error al imprimir'
					const html = `No se pudo imprimir: ${typePrint} <br>¿Desea intentar de nuevo?`
					const result = await Swal.fire({
						title: title,
						html: html,
						icon: 'error',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Sí',
						cancelButtonText: 'No',
					})

					if (result.isDismissed) {
						isPrinterAvailableTicket = true
					}
				}
			} while (!isPrinterAvailableTicket)

			return responseBody
		} catch (error) {
			let errorMessage
			console.error(error)

			try {
				const errorInfo = JSON.parse(error.message)
				errorMessage = errorInfo.message
			} catch {
				errorMessage =
					'Ocurrió un error al traer los productos, recargue el punto de venta o contacte a un encargado.'
			}
			throw new Error(errorMessage)
		}
	}
)

export const verifyItemsWithCoupon = createAsyncThunk(
	'sale/verifyItemsWithCoupon',
	async (code, { getState }) => {
		const { data } = getState().sale

		const requestBody = {
			Items: data.map((item) => {
				return {
					ItemCode: item.ItemCode,
					Quantity: item.quantity,
					price: item.AvgPriceLiquidation
						? item.AvgPriceLiquidation
						: item.AvgPrice,
				}
			}),
			Coupons: [{ Coupon: code }],
		}

		try {
			const url = `${apiUrl}/api/coupons/validate`
			const response = await fetch(url, {
				method: 'POST',
				body: JSON.stringify(requestBody),
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				credentials: 'include',
			})

			if (!response.ok) {
				let errorMessage
				try {
					const errorBody = await response.json()
					errorMessage = errorBody.message || 'Error desconocido'
				} catch {
					errorMessage =
						'Ocurrió un error al validar el cupón, recargue el punto de venta o contacte a un encargado.'
				}

				const errorInfo = {
					code: response.status,
					message: errorMessage,
					url: url,
				}

				throw new Error(JSON.stringify(errorInfo))
			}

			const responseBody = await response.json()

			return responseBody
		} catch (error) {
			let errorMessage
			console.error(error)

			try {
				const errorInfo = JSON.parse(error.message)
				errorMessage = errorInfo.message
			} catch {
				errorMessage =
					'Ocurrió un error al validar el cupón, recargue el punto de venta o contacte a un encargado.'
			}
			throw new Error(errorMessage)
		}
	}
)

export const searchItem = createAsyncThunk(
	'sale/searchItem',
	async (searchQuery) => {
		const url = `${apiUrl}/api/items/getItem?param=${encodeURIComponent(
			searchQuery
		)}`

		try {
			const response = await fetch(url, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				credentials: 'include',
			})

			const jsonResponse = await response.json()
			if (!response.ok) {
				let errorMessage
				try {
					const errorBody = jsonResponse
					errorMessage =
						errorBody.message ||
						'Ocurrió un error buscar el producto, recargue el punto de venta o contacte a un encargado.'
				} catch {
					errorMessage =
						'Ocurrió un error buscar el producto, recargue el punto de venta o contacte a un encargado.'
				}

				const errorInfo = {
					code: response.status,
					message: errorMessage,
					url: url,
				}

				throw new Error(JSON.stringify(errorInfo))
			}

			return jsonResponse.items
		} catch (error) {
			let errorMessage
			console.error(error)

			try {
				const errorInfo = JSON.parse(error.message)
				errorMessage = errorInfo.message
			} catch {
				errorMessage =
					'Ocurrió un error al traer los productos, recargue el punto de venta o contacte a un encargado.'
			}
			throw new Error(errorMessage)
		}
	}
)

export const searchFolio = createAsyncThunk(
	'sale/searchFolio',
	async (searchQuery, { rejectWithValue }) => {
		const urlSearchFolio = `${apiUrl}/api/Orders/${encodeURIComponent(
			searchQuery
		)}/searchOrder`

		// Función para esperar un tiempo determinado
		const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

		try {
			const startTime = Date.now() // Marca el inicio de la petición

			const response = await fetch(urlSearchFolio, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				credentials: 'include',
			})

			const jsonResponse = await response.json()

			if (!response.ok) {
				return rejectWithValue({
					code: response.status,
					message:
						jsonResponse.message || 'Error desconocido al buscar la referencia',
					errors: jsonResponse.errors || [],
				})
			}

			const elapsedTime = Date.now() - startTime // Calcula el tiempo transcurrido
			const remainingTime = 3000 - elapsedTime // Calcula el tiempo restante para completar 3 segundos

			if (remainingTime > 0) {
				await delay(remainingTime) // Espera el tiempo restante si la petición fue demasiado rápida
			}

			// Devuelve los datos después de esperar el tiempo necesario
			return {
				data: jsonResponse.dataOrder.data,
				folioVenta: searchQuery,
			}
		} catch (error) {
			return rejectWithValue({
				message:
					'Ocurrió un error al buscar la referencia, intenta de nuevo o contacta a soporte.',
			})
		}
	}
)

export const {
	openModal,
	closeModal,
	setData,
	addItem,
	decreaseItem,
	removeItem,
	setSearchResults,
	setNumericValue,
	setFolioVenta,
	updateDataPreliminarInformation,
	updateCouponCode,
	updateCoupon,
	updateFolioVentaCopy,
	updateIsDataSaleComplete,
	updateProductQuantity,
	updateError,
	updateCouponError,
	eraseCoupon,
	eraseCart,
	removeDiscountPricesFromItems,
	resetSale,
	changeStatus,
} = saleSlice.actions

export default saleSlice.reducer
