import React, { useEffect } from 'react'
import { selectPayMethod } from '../../../redux/selectors/order'
import {
	selectCartTotal,
	selectCartSubtotal,
	selectCartDiscount,
	selectDiscontLiquidation,
} from '../../../redux/selectors/cart'
import {
	selectCouponAmount,
	selectCouponCode,
	selectCouponType,
} from '../../../redux/selectors/coupon'
import {
	selectOrderCash,
	selectOrderExchange,
} from '../../../redux/selectors/order'
import { useSelector } from 'react-redux'
import { formatCurrency } from '../../../utils/functions/money'
import { Descriptions } from 'antd'

export default function ModalOrderAmounts() {
	const cash = useSelector(selectOrderCash)
	const exchange = useSelector(selectOrderExchange)
	const total = useSelector(selectCartTotal)
	const subtotal = useSelector(selectCartSubtotal)
	const discount = useSelector(selectCartDiscount)
	const couponAmount = useSelector(selectCouponAmount)
	const couponCode = useSelector(selectCouponCode)
	const couponType = useSelector(selectCouponType)
	const payMethod = useSelector(selectPayMethod)
	const discountLiquidation = useSelector(selectDiscontLiquidation)

	useEffect(() => {
			console.log(exchange);
			
		}, [exchange])

	return (
		<Descriptions
			size='small'
			style={{
				width: '50%',
			}}
			bordered={true}
			items={[
				{
					key: 'subtotal',
					label: 'Subtotal',
					children: formatCurrency(subtotal),
					span: 5,
				},
				...(couponCode
					? [
							{
								key: 'coupon_code',
								label: 'Cupón aplicado',
								children: couponCode,
								span: 5,
							},
							{
								key: 'discount_amount',
								label: 'Descuento',
								children:<span style={{ color: 'red' }}>{
									couponType === 'per'
										? `${couponAmount}%`
										: `${formatCurrency(couponAmount)}`
								}</span>,
								span: 5,
							},
							{
								key: 'discount_value',
								label: 'Cantidad descontada',
								children: <span style={{ color: 'red' }}>{formatCurrency(discount)}</span>,
								span: 5,
							},
					]
					: []),
				...(discountLiquidation && discountLiquidation > 0
					? [
							{
								key: 'liquidation_save',
								label: 'Ahorro por liquidación',
								children: <span style={{ color: 'red' }}>{formatCurrency(discountLiquidation)}</span> ,
								span: 5,
								//labelStyle: { color: 'red' },
							},
					]
					: []),
				{
					key: 'payment_type',
					label: 'Tipo de pago',
					children: payMethod,
					span: 5,
				},
				{
					key: 'total',
					label: 'Total',
					children: <span style={{fontWeight:'bold'}}>{formatCurrency(total)}</span>,
					span: 5,
					labelStyle: {backgroundColor: '#e71f85', color: 'white', fontWeight:'bold' },
					//childrenStyle: {backgroundColor: '#e71f85', color: 'white', fontWeight:'bold' }
				},
				...(payMethod === 'Efectivo'
					? [
							{
								key: 'cash',
								label: 'Efectivo entregado',
								children: formatCurrency(cash),
							},
							{
								key: 'exchange',
								label: 'Cambio',
								children: formatCurrency(exchange),
							},
					]
					: []),
			]}
		/>
	)
}
