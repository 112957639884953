import React, { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
	changeScannedProduct,
	changeComponentShow,
} from '../../../redux/catalog'
import { showScannedProductConfig } from '../../../utils/var'
import { Button } from 'react-bootstrap'

export default function Lector() {
	const dispatch = useDispatch()
	const [scannedProduct, setScannedProduct] = useState('')
	const inputRef = useRef(null)
	const buttonRef = useRef(null)
	const [focus, setFocus] = useState(true)
	const [isScanning, setIsScanning] = useState(true)

	// Manejar el enfoque y desenfoque del input al hacer clic fuera
	useEffect(() => {
		const handleClickOutside = (e) => {
			// Ignorar el clic si ocurrió en el input o en el botón dinámico
			if (
				inputRef.current &&
				!inputRef.current.contains(e.target) &&
				buttonRef.current &&
				!buttonRef.current.contains(e.target)
			) {
				setFocus(false)
			}
		}

		// Añadir el event listener al document para detectar clics fuera del input
		document.addEventListener('click', handleClickOutside)

		// Limpiar el event listener al desmontar el componente
		return () => {
			document.removeEventListener('click', handleClickOutside)
		}
	}, [])

	// Enfocar el input cuando el estado 'focus' sea verdadero
	useEffect(() => {
		if (focus && inputRef.current) {
			inputRef.current.focus()
		}
	}, [focus])

	const getProductByCodeBar = () => {
		if (scannedProduct && scannedProduct !== '0') {
			dispatch(changeScannedProduct(scannedProduct))
			dispatch(changeComponentShow(showScannedProductConfig))
			setScannedProduct('')
			setFocus(true)
			setIsScanning(true)
		}
	}

	// Manejar el evento de "Enter" y ejecutar la función getProductByCodeBar
	useEffect(() => {
		const handleKeyPress = (e) => {
			if (e.key === 'Enter') {
				getProductByCodeBar()
			}
		}

		// Añadir el event listener para la tecla "Enter"
		window.addEventListener('keydown', handleKeyPress)

		// Limpiar el event listener al desmontar el componente
		return () => {
			window.removeEventListener('keydown', handleKeyPress)
		}
	}, [scannedProduct])

	// Manejo del clic en el botón para enfocar el input oculto
	const handleButtonClick = () => {
		setFocus(true)
		setIsScanning(true)
	}

	return (
		<>
			<div className='d-flex' style={{ marginLeft: 3 + '%' }}>
				<div className='col search-set'>
					<div className='search-input'>
						<input
							id='searchCodeBar'
							type='text'
							placeholder='Agregar por escrito'
							className='form-control form-control-sm formsearch'
							value={scannedProduct}
							onChange={(e) =>
								setScannedProduct(e.target.value.trim().toUpperCase())
							}
						/>
					</div>
				</div>
				<button
					type='button'
					className='btn btn-primary btn-icon'
					onClick={() => {
						if (scannedProduct && scannedProduct !== '0') {
							getProductByCodeBar()
						}
					}}>
					+
				</button>
				<Button
					ref={buttonRef} // Asignar la referencia al botón
					className='btn btn-info'
					style={{ marginLeft: '20px' }}
					onClick={handleButtonClick}>
					{isScanning && focus ? 'Escaneando...' : 'Escanear'}
				</Button>
			</div>
			<input
				ref={inputRef}
				id='searchCodeBar'
				type='text'
				placeholder='Código de barras'
				className='form-control form-control-sm formsearch hidden-input'
				value={scannedProduct}
				onChange={(e) => setScannedProduct(e.target.value)}
			/>
		</>
	)
}
