import React, { useEffect } from 'react'
import FormControl from '../../../components/FormControl'
import { useDispatch, useSelector } from 'react-redux'
import { eraseCart, searchItem, updateError } from '../redux/new-sale'
import { Button, Form } from 'antd'
import { selectError } from '../redux/selectors/new-sale'
import Swal from 'sweetalert2'

export default function SaleSearchProduct() {
	const dispatch = useDispatch()
	const error = useSelector(selectError)
	const [query, setQuery] = React.useState('')

	const handleSearch = (e) => {
		console.log(e)

		e.preventDefault()
		if (query.trim()) {
			dispatch(searchItem(query))
		}
	}

	useEffect(() => {
		if (error) {
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: error,
			}).then((result) => {
				if (result.isConfirmed) {
					dispatch(updateError(null))
				}
			})
		}
	}, [error])

	return (
		<>
			<div className='w-100 d-flex justify-content-between align-items-center'>
				<Button
					type='default'
					color='danger'
					size='large'
					onClick={() => {
						dispatch(eraseCart())
					}}>
					Limpiar carrito
				</Button>

				<Form.Item
					style={{
						width: '35%',
						marginBottom: '0',
						marginTop: '24px',
					}}>
					<FormControl
						name='itemSearched'
						type='search'
						attributes={{
							placeholder: 'Código de Barras o Número de parte',
							isSearchable: true,
							enablePrefix: true,
							size: 'large',
							onChange: (e) => setQuery(e.target.value),
							onKeyPress: (e) => {
								if (e.key === 'Enter') handleSearch(e)
							},
							onSearch: (_, event) => {
								handleSearch(event)
							},
							enterButton: 'Buscar',
						}}
					/>
				</Form.Item>
			</div>
		</>
	)
}
