import React, { useContext, useCallback, useMemo, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { CouponsContext } from '../provider/Coupons'
import { formCreateCoupon } from '../utils/var'
import { Form, Space, Col, Row } from 'antd'
import { formatDataUpdateCoupon } from '../utils/functions/formatData'
import CouponsFormApplicabilities from './CouponsFormApplicabilities'
import CouponsFormApplicabilitiesBrands from './CouponsFormApplicabilitiesBrands'
import CouponsFormApplicabilitiesItems from './CouponsFormApplicabilitiesItems'
import CouponsFormApplicabilitiesLinesAndSublines from './CouponsFormApplicabilitiesLinesAndSublines'
import FormControl from '../components/FormControl'
import debounce from 'lodash/debounce'

const CouponsForm = () => {
	const [form] = Form.useForm()
	const {
		applicabilities,
		isShowModalForm,
		isLoadingCreate,
		prepareDataCreateCoupon,
		updateData,
		updateIsShowModalForm,
		resetApplicabilities,
		isLoadingLinesAndSublines,
		isLoadingItems,
		isLoadingBrands,
		isLoadingShowCoupon,
		updateIsLoadingCreate,
		updateIsLoadingBrands,
		updateIsLoadingItems,
		updateIsLoadingLinesAndSublines,
		updateFeedback,
		updateError,
		updateDataUpdateCoupon,
		updateActiveCoupon,
		updateDataUpdateFormatCoupon,
		updateIsAllItems,
		updateApplicabilities,
		error,
		feedback,
		getCoupons,
		activeCoupon,
		getCoupon,
		dataActiveCoupon,
		dataUpdateFormatCoupon,
	} = useContext(CouponsContext)
	const initialValues = {
		discount_type: 'P',
		status: true,
		isSelectedBrands: false,
		isSelectedLinesAndSublines: false,
		isSelectedItems: false,
		isApplicableBrands: true,
		isApplicableItems: true,
		isApplicableLinesAndSublines: true,
		isAllItems: true,
		isEnabledTime: true,
		isEnabledMinOrderValue: true,
		isEnabledMaxDiscountValue: true,
	}

	const { brands, linesAndSublines, items } = applicabilities

	const watchDiscountType = Form.useWatch('discount_type', form)
	const watchIsEnabledTime = Form.useWatch('isEnabledTime', form)
	const watchIsEnabledMinOrderValue = Form.useWatch(
		'isEnabledMinOrderValue',
		form
	)
	const watchIsEnabledMaxDiscountValue = Form.useWatch(
		'isEnabledMaxDiscountValue',
		form
	)
	/* const watchBrands = Form.useWatch('brands', form) */

	const handleSubmit = async () => {
		prepareDataCreateCoupon()
	}

	const debouncedUpdateData = useMemo(
		() =>
			debounce((values) => {
				updateData(values)
			}, 300),
		[updateData]
	)

	const handleFormChange = useCallback(() => {
		const currentValues = form.getFieldsValue(true)
		if (!currentValues.isEnabledTime) {
			delete currentValues['start-end-time']
		}

		if (
			currentValues.discount_type !== 'P' ||
			!currentValues.isEnabledMaxDiscountValue
		) {
			delete currentValues['max_money_discount']
		}

		if (!currentValues.isEnabledMinOrderValue) {
			delete currentValues['min_order_value']
		}
		debouncedUpdateData(currentValues)
	}, [form, debouncedUpdateData])

	const leftFields = useCallback(() => {
		return formCreateCoupon(null, watchDiscountType, 'left').map((control) => {
			return <FormControl key={control.name} {...control} />
		})
	}, [watchDiscountType])

	const rightFields = useCallback(() => {
		return formCreateCoupon(null, null, 'right').map((control) => {
			if (
				(control.name === 'max_money_discount' &&
					watchDiscountType !== 'P' &&
					!watchIsEnabledMaxDiscountValue) ||
				(control.name === 'max_money_discount' &&
					watchDiscountType !== 'P' &&
					watchIsEnabledMaxDiscountValue) ||
				(control.name === 'max_money_discount' &&
					watchDiscountType === 'P' &&
					!watchIsEnabledMaxDiscountValue) ||
				(control.name === 'start-end-time' && !watchIsEnabledTime) ||
				(control.name === 'min_order_value' && !watchIsEnabledMinOrderValue)
			) {
				return null
			}
			return <FormControl key={control.name} {...control} />
		})
	}, [
		watchDiscountType,
		watchIsEnabledTime,
		watchIsEnabledMinOrderValue,
		watchIsEnabledMaxDiscountValue,
	])

	const clearFieldAndUpdateData = useCallback(
		(fieldName, condition) => {
			if (condition) {
				form.setFieldValue(fieldName, null)
				updateData((prevData) => {
					const newData = { ...prevData }
					delete newData[fieldName]
					return newData
				})
			}
		},
		[form]
	)

	useEffect(() => {
		const currentValues = form.getFieldsValue(true)
		currentValues.isSelectedBrands = brands
		if (!brands) {
			delete currentValues.brands
		} else {
			if (dataUpdateFormatCoupon && activeCoupon) {
				if (dataUpdateFormatCoupon.brands) {
					currentValues.brands = dataUpdateFormatCoupon.brands
				}
			}
		}

		form.setFieldsValue(currentValues)
		debouncedUpdateData(currentValues)
	}, [brands])

	useEffect(() => {
		const currentValues = form.getFieldsValue(true)
		currentValues.isSelectedItems = items

		if (!items) {
			delete currentValues.items
		} else {
			if (dataUpdateFormatCoupon && activeCoupon) {
				if (dataUpdateFormatCoupon.items) {
					currentValues.items = dataUpdateFormatCoupon.items
				}
			}
		}

		form.setFieldsValue(currentValues)
		debouncedUpdateData(currentValues)
	}, [items])

	useEffect(() => {
		const currentValues = form.getFieldsValue(true)
		currentValues.isSelectedLinesAndSublines = linesAndSublines
		if (!linesAndSublines) {
			delete currentValues.lines
			delete currentValues.sublines
			delete currentValues.linesAndSublines
		} else {
			if (dataUpdateFormatCoupon && activeCoupon) {
				if (dataUpdateFormatCoupon?.linesAndSublines?.length > 0) {
					currentValues.linesAndSublines =
						dataUpdateFormatCoupon.linesAndSublines
				}
			}
		}

		form.setFieldsValue(currentValues)
		debouncedUpdateData(currentValues)
	}, [linesAndSublines])

	useEffect(() => {
		if (isShowModalForm && activeCoupon) {
			getCoupon()
		}
	}, [isShowModalForm])

	useEffect(() => {
		if (isShowModalForm && dataActiveCoupon) {
			updateDataUpdateFormatCoupon({
				...initialValues,
				...formatDataUpdateCoupon(dataActiveCoupon),
			})
		}
	}, [dataActiveCoupon])

	useEffect(() => {
		if (dataUpdateFormatCoupon) {
			form.setFieldsValue(dataUpdateFormatCoupon)

			updateApplicabilities('brands', dataUpdateFormatCoupon.isSelectedBrands)
			updateApplicabilities('items', dataUpdateFormatCoupon.isSelectedItems)
			updateApplicabilities(
				'linesAndSublines',
				dataUpdateFormatCoupon.isSelectedLinesAndSublines
			)
			updateIsAllItems(dataUpdateFormatCoupon.isAllItems)
		}

		handleFormChange()
	}, [dataUpdateFormatCoupon])

	useEffect(() => {
		const currentValues = form.getFieldsValue(true)

		if (watchIsEnabledTime) {
			if (dataUpdateFormatCoupon && activeCoupon) {
				if (dataUpdateFormatCoupon['start-end-time']) {
					currentValues['start-end-time'] =
						dataUpdateFormatCoupon['start-end-time']
				}
			}
		}

		debouncedUpdateData(currentValues)
	}, [watchIsEnabledTime])

	useEffect(() => {
		const currentValues = form.getFieldsValue(true)
		if (watchDiscountType === 'P') {
			if (activeCoupon && dataUpdateFormatCoupon) {
				updateApplicabilities('brands', dataUpdateFormatCoupon.isSelectedBrands)
				updateApplicabilities('items', dataUpdateFormatCoupon.isSelectedItems)
				updateApplicabilities(
					'linesAndSublines',
					dataUpdateFormatCoupon.isSelectedLinesAndSublines
				)
				updateIsAllItems(dataUpdateFormatCoupon.isAllItems)
				currentValues.isAllItems = dataUpdateFormatCoupon.isAllItems
			}
		}

		if (watchDiscountType) {
			if (dataUpdateFormatCoupon && activeCoupon) {
				if (dataUpdateFormatCoupon.max_money_discount) {
					currentValues.max_money_discount =
						dataUpdateFormatCoupon.max_money_discount
				}
			}
		}

		debouncedUpdateData(currentValues)
	}, [watchDiscountType])

	useEffect(() => {
		const currentValues = form.getFieldsValue(true)

		if (watchIsEnabledMinOrderValue) {
			if (dataUpdateFormatCoupon && activeCoupon) {
				if (dataUpdateFormatCoupon['min_order_value']) {
					currentValues['min_order_value'] =
						dataUpdateFormatCoupon['min_order_value']
				}
			}
		}

		debouncedUpdateData(currentValues)
	}, [watchIsEnabledMinOrderValue])

	useEffect(() => {
		const currentValues = form.getFieldsValue(true)

		if (watchDiscountType === 'P' && watchIsEnabledMaxDiscountValue) {
			if (dataUpdateFormatCoupon && activeCoupon) {
				if (dataUpdateFormatCoupon.max_money_discount) {
					currentValues.max_money_discount =
						dataUpdateFormatCoupon.max_money_discount
				}
			}
		}

		debouncedUpdateData(currentValues)
	}, [watchDiscountType, watchIsEnabledMaxDiscountValue])

	useEffect(() => {
		clearFieldAndUpdateData('start-end-time', !watchIsEnabledTime)
		clearFieldAndUpdateData(
			'max_money_discount',
			(watchDiscountType !== 'P' && !watchIsEnabledMaxDiscountValue) ||
				(watchDiscountType !== 'P' && watchIsEnabledMaxDiscountValue) ||
				(watchDiscountType === 'P' && !watchIsEnabledMaxDiscountValue)
		),
			clearFieldAndUpdateData('min_order_value', !watchIsEnabledMinOrderValue)
	}, [
		watchIsEnabledTime,
		watchDiscountType,
		watchIsEnabledMinOrderValue,
		watchIsEnabledMaxDiscountValue,
		clearFieldAndUpdateData,
	])

	return (
		<Modal
			show={isShowModalForm}
			centered={feedback}
			size={feedback ? 'sm' : 'xl'}
			backdrop='static'
			scrollable>
			<Modal.Header>
				<Modal.Title as={'h2'}>
					{!activeCoupon ? 'Crear' : 'Actualizar'} Cupón
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{!feedback ? (
					<Form
						disabled={
							isLoadingShowCoupon ||
							isLoadingCreate ||
							isLoadingBrands ||
							isLoadingItems ||
							isLoadingLinesAndSublines
						}
						form={form}
						name='create-edit-coupon'
						onFinish={handleSubmit}
						onFieldsChange={handleFormChange}
						initialValues={initialValues}>
						<Space size={'large'}>
							<FormControl
								type='switch'
								name='isEnabledTime'
								label='Habilitar Hora Inicio/Fin'
							/>
							<FormControl
								type='switch'
								name='isEnabledMinOrderValue'
								label='Habilitar ordén mínima'
							/>
							<FormControl
								type='switch'
								name='isEnabledMaxDiscountValue'
								label='Habilitar descuento máximo'
							/>
						</Space>
						<Row gutter={24}>
							<Col span={12}>{leftFields()}</Col>
							<Col span={12}>{rightFields()}</Col>
						</Row>
						{watchDiscountType === 'P' ? (
							<>
								<CouponsFormApplicabilities />
								<div
									className='d-flex flex flex-wrap justify-content-between'
									style={{ gap: '2rem 0' }}>
									{brands ? <CouponsFormApplicabilitiesBrands /> : <></>}
									{items ? <CouponsFormApplicabilitiesItems /> : <></>}
									{linesAndSublines ? (
										<CouponsFormApplicabilitiesLinesAndSublines />
									) : (
										<></>
									)}
								</div>
							</>
						) : (
							<></>
						)}
						<Form.Item>
							<Space>
								{!feedback ? (
									<Button
										type='primary'
										className='me-2 mt-4 py-3 px-4'
										disabled={
											isLoadingShowCoupon ||
											isLoadingCreate ||
											isLoadingBrands ||
											isLoadingItems ||
											isLoadingLinesAndSublines
										}>
										Enviar
										{(isLoadingShowCoupon || isLoadingCreate) && (
											<div
												className='spinner-border spinner-border-sm ms-2'
												role='status'>
												<span className='sr-only'>Loading...</span>
											</div>
										)}
									</Button>
								) : (
									<></>
								)}
								<Button
									disabled={
										isLoadingShowCoupon ||
										isLoadingCreate ||
										isLoadingBrands ||
										isLoadingItems ||
										isLoadingLinesAndSublines
									}
									className='me-2 mt-4 py-3 px-4'
									onClick={() => {
										form.resetFields()
										resetApplicabilities()
										updateIsShowModalForm(false)
										updateIsLoadingCreate(false)
										updateDataUpdateCoupon(null)
										updateIsLoadingBrands(false)
										updateIsLoadingItems(false)
										updateActiveCoupon(null)
										updateIsLoadingLinesAndSublines(false)
										updateFeedback(null)
										updateError(null)
										updateIsAllItems(true)
									}}
									variant='pape_cancel'>
									Cancelar
								</Button>
							</Space>
						</Form.Item>

						{error ? (
							<span className='text-danger'>{error.message}</span>
						) : (
							<></>
						)}
					</Form>
				) : (
					<span>{feedback}</span>
				)}
			</Modal.Body>
			{feedback ? (
				<Modal.Footer>
					<Button
						className='me-2 mt-4 py-3 px-4'
						onClick={() => {
							form.resetFields()
							updateIsShowModalForm(false)
							resetApplicabilities()
							updateIsLoadingCreate(false)
							updateDataUpdateCoupon(null)
							updateIsLoadingBrands(false)
							updateIsLoadingItems(false)
							updateActiveCoupon(null)
							updateIsLoadingLinesAndSublines(false)
							updateFeedback(null)
							updateError(null)
							getCoupons()
							updateIsAllItems(true)
						}}
						variant='pape_cancel'>
						Cerrar
					</Button>
				</Modal.Footer>
			) : (
				<></>
			)}
		</Modal>
	)
}

export default CouponsForm
