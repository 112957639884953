import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { Plus, CreditCard, DollarSign } from 'react-feather'; // Ajusta según tus íconos
import ImageWithBasePath from '../../../core/img/imagewithbasebath';

const DashCount = ({
    title,
    subtitle,
    iconType,
    iconSrc,
    className,
    onButtonClick
}) => {
    const renderIcon = () => {
        switch (iconType) {
            case 'plus':
                return <Plus />;
            case 'card':
                return <CreditCard />;
            case 'dollar':
                return <DollarSign />;
            case 'image':
                return <ImageWithBasePath src={iconSrc} className="img-fluid" alt="icon" />;
            default:
                return null;
        }
    };

    return (
        <div className="col-xl-3 col-sm-6 col-12 d-flex cashInfo">
            <div className={`dash-count ${className}`}>
                <div className="dash-counts">
                    <h4>{title}</h4>
                    <h5>{subtitle}</h5>
                </div>
                <div className="dash-imgs">
                    {iconType === 'button' ? (
                        <Button type="text" onClick={onButtonClick} style={{ width: 'auto', height: 'auto' }}>
                            <div style={{ border: 'solid', color: 'white' }}>
                                <Plus />
                            </div>
                        </Button>
                    ) : (
                        renderIcon()
                    )}
                </div>
            </div>
        </div>
    );
};

DashCount.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // Título, puede ser texto o número
    subtitle: PropTypes.string.isRequired, // Subtítulo del elemento
    iconType: PropTypes.oneOf(['plus', 'user', 'userCheck', 'image', 'dollar', 'card','button']).isRequired, // Tipo de icono a renderizar
    iconSrc: PropTypes.string, // Ruta del icono si es una imagen
    className: PropTypes.string, // Clase adicional para estilos
    onButtonClick: PropTypes.func, // Función para manejar el clic en el botón
};

export default DashCount;
