import React from 'react'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import SaleDetails from './SaleDetails'
import { useSelector } from 'react-redux'
import SaleProducts from './SaleProducts'
import SaleSearchProduct from './SaleSearchProduct'

const SaleDataSection = () => {
	const { loading } = useSelector((state) => state.sale)

	return (
		<>
			{loading ? (
				<Spin spinning={true} />
			) : (
				<div className='barcode-content-list' style={{ marginBottom: '15px' }}>
					<div className='page-title'>
						<h4>CARRITO DE LA VENTA</h4>
					</div>
					<SaleSearchProduct />
					<SaleProducts />
					<br />
					<SaleDetails />
				</div>
			)}
		</>
	)
}

SaleDataSection.propTypes = {
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			dataIndex: PropTypes.string.isRequired,
			key: PropTypes.string,
		})
	),
}

export default SaleDataSection
