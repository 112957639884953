import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import SelectDevice from '../features/history-reports/cash-counts/components/SelectDevice'
import { useDispatch } from 'react-redux'
import { updateDevice } from '../features/cash-count/redux/cash-count'
import DashboardLayout from '../layouts/DashboardLayout'
import PageTitle from '../layouts/PagesTitle'
import Toolbar from '../components/Toolbar'

export default function Settings() {
	const dispatch = useDispatch()
	const [device, setDevice] = useState(null)
	const [devices, setDevices] = useState([])

	useEffect(() => {
		if (devices && devices.length > 0) {
			const deviceInformation = JSON.stringify(
				devices.find((devi) => devi.value === device)
			)
			if (deviceInformation) {
				localStorage.setItem('device', deviceInformation)
				dispatch(updateDevice(device))
			}
		}
	}, [device])

	return (
		<DashboardLayout>
			<PageTitle
				maintitle="Configuración de punto de venta"
				subtitle="Selector de dispositivo"
				toolbar={
					<Toolbar/>		
				}
			/>
			<Form className='pt-4'>
				<SelectDevice changeDevice={setDevice} changeDevices={setDevices} />
			</Form>
		</DashboardLayout>
	)
}
