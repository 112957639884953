import React from 'react'
import { Button } from 'antd'
import ImageWithBasePath from '../../../core/img/imagewithbasebath'
import { apiUrl } from '../../../utils/var'
import SaleQuantityInput from '../components/SaleQuantityInput'
import { Minus, Plus } from 'react-feather'

const columns = (handleDecrease, handleIncrease, handleRemoveItem) => [
	{
		title: 'Imagen',
		dataIndex: '',
		render: (_, record) => (
			<ImageWithBasePath
				// url={`${apiUrl}/proxy/empleados/imagenes/ITEMS/${record.PicturName}`}
				url={`${apiUrl}/proxy/empleados/web/index.php?r=AppMovil/proxy/compress&filename=${record.PicturName}`}
				width={450}
				alt='product'
			/>
		),
	},
	{
		title: 'Numero de parte',
		dataIndex: 'ItemCode',
		render: (text) => <span className='badge badge-linesuccess'>{text}</span>,
		sorter: (a, b) => a.ItemCode.localeCompare(b.ItemCode),
	},
	{
		title: 'Descripcion',
		dataIndex: 'ItemName',
		sorter: (a, b) => a.ItemName.localeCompare(b.ItemName),
		render: (text) => (
			<div>
				<span>{text}</span>
			</div>
		),
	},
	{
		title: 'Codigo de barras',
		dataIndex: 'CodeBars',
		sorter: (a, b) => a.CodeBars.localeCompare(b.CodeBars),
	},
	{
		title: 'Stock',
		dataIndex: 'OnHand',
		sorter: (a, b) => a.OnHand.localeCompare(b.OnHand),
	},
	{
		title: 'Cantidad',
		dataIndex: 'quantity',
		sorter: (a, b) => a.Quantity - b.Quantity,
		render: (_, record) => (
			<div style={{ display: 'flex', alignItems: 'center', width: '10rem' }}>
				<button
					style={{ borderRadius: '6px' }}
					type='button'
					onClick={() => handleDecrease(record)}
					className='bg-info border-0 p-1 btn'>
					<Minus />
				</button>

				<SaleQuantityInput product={record} currentQuantity={record.quantity} />

				<button
					style={{ borderRadius: '6px' }}
					type='button'
					onClick={() => handleIncrease(record)}
					className='bg-info border-0 p-1'>
					<Plus />
				</button>
			</div>
		),
	},
	{
		title: 'Precio unitario',
		dataIndex: 'unitPrice',
		key: 'unitPrice',
		render: (_, record) => {
			const prices = record.prices

			if (prices.priceWithDiscount) {
				if (prices.priceLiquidation) {
					return (
						<div className='d-flex flex-column gap-2'>
							<span className='text-decoration-line-through'>
								{prices.priceLiquidation}
							</span>
							<span>{prices.priceWithDiscount}</span>
						</div>
					)
				} else {
					return (
						<div className='d-flex flex-column gap-2'>
							<span className='text-decoration-line-through'>
								{prices.price}
							</span>
							<span>{prices.priceWithDiscount}</span>
						</div>
					)
				}
			} else if (prices.priceLiquidation) {
				return (
					<div className='d-flex flex-column gap-2'>
						<span className='text-decoration-line-through'>{prices.price}</span>
						<span>{prices.priceLiquidation}</span>
					</div>
				)
			} else {
				return <span>{prices.price}</span>
			}
		},
	},
	{
		title: 'Total',
		dataIndex: 'totalByProduct',
		key: 'totalByProduct',
		sorter: (a, b) => a.LineTotal - b.LineTotal,
	},
	{
		title: 'Acciones',
		dataIndex: '',
		render: (_, record) => (
			<Button onClick={() => handleRemoveItem(record)} danger ghost>
				<i className='feather-trash-2' />
			</Button>
		),
	},
]

export default columns
