import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiUrl, initialStateCatalog } from '../utils/var'
import { initialSarchCriteria } from '../utils/var'

export const fetchCatalog = createAsyncThunk(
	'catalog/fetchProducts',
	async (_, { getState }) => {
		const { filter, sorting, search, pagination } = getState().catalog.contents
		const { category, subcategory } = filter
		const { criteria, order } = sorting
		const { offset, itemsPerPage } = pagination

		const params = new URLSearchParams({
			[criteria]: order,
			Offset: offset,
			Limit: itemsPerPage,
			...(search && { Item: search.toUpperCase() }),
			...(category && { Linea: category === 'NA' ? '' : category }),
			...(subcategory && { SubLinea: subcategory === 'NA' ? '' : subcategory }),
		})

		const url = `${apiUrl}/api/items/getItems?${params}`

		try {
			const response = await fetch(url, {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				credentials: 'include',
			})

			if (!response.ok) {
				let errorMessage
				try {
					const errorBody = await response.json()
					console.error(errorBody)

					errorMessage =
						errorBody.message ||
						'Error desconocido o de red al consultar el catalogo, recargue el punto de venta o contacte a soporte.'
				} catch {
					errorMessage =
						'Ocurrió un error al traer los productos, recargue el punto de venta o contacte a un encargado.'
				}

				const errorInfo = {
					code: response.status,
					message: errorMessage,
					url: url,
				}

				throw new Error(JSON.stringify(errorInfo))
			}

			const responseBody = await response.json()
			return responseBody
		} catch (error) {
			console.error(error)
			let errorMessage

			try {
				const errorInfo = JSON.parse(error.message)
				errorMessage = errorInfo.message
			} catch {
				errorMessage =
					'Error desconocido o de red al consultar el catalogo, recargue el punto de venta o contacte a soporte.'
			}
			throw new Error(errorMessage)
		}
	}
)

export const fetchCategories = createAsyncThunk(
	'catalog/fetchCategories',
	async () => {
		try {
			const urlCategories = `${apiUrl}/api/items/getCategories`
			const categoriesResponse = await fetch(urlCategories, {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				credentials: 'include',
			})

			if (!categoriesResponse.ok) {
				let errorMessage
				try {
					const errorBody = await categoriesResponse.json()
					console.error(errorBody)
					errorMessage =
						errorBody.message ||
						'Error desconocido o de red al consultar las categorías, recargue el punto de venta o contacte a soporte.'
				} catch {
					errorMessage =
						'Ocurrió un error al traer las categorias, recargue el punto de venta o contacte a un encargado.'
				}

				const errorInfo = {
					code: categoriesResponse.status,
					message: errorMessage,
					url: urlCategories,
				}

				throw new Error(JSON.stringify(errorInfo))
			}

			const categories = await categoriesResponse.json()
			return categories
		} catch (error) {
			console.error(error)
			let errorMessage

			try {
				const errorInfo = JSON.parse(error.message)
				errorMessage = errorInfo.message
			} catch {
				errorMessage =
					'Error desconocido o de red al consultar las categorías, recargue el punto de venta o contacte a soporte.'
			}
			throw new Error(errorMessage)
		}
	}
)

const catalogSlice = createSlice({
	name: 'catalog',
	initialState: initialStateCatalog,
	reducers: {
		resetCatalog: () => {
			return initialStateCatalog
		},
		changeComponentShow: (state, action) => {
			const statesComponents = action.payload
			return {
				...state,
				contents: {
					...state.contents,
					...statesComponents,
				},
			}
		},
		changeScannedProduct: (state, action) => {
			const scannedProduct = action.payload

			return {
				...state,
				contents: {
					...state.contents,
					ScannedProduct: scannedProduct,
					scanCount: state.contents.scanCount + 1,
				},
			}
		},
		changeScannedProductWithoutCount: (state, action) => {
			const scannedProduct = action.payload
			return {
				...state,
				contents: {
					...state.contents,
					ScannedProduct: scannedProduct,
				},
			}
		},
		changeFilter: (state, action) => {
			const filter = action.payload

			return {
				...state,
				contents: {
					...state.contents,
					filter: {
						...state.contents.filter,
						...filter,
					},
					pagination: {
						...state.contents.pagination,
						page: 1,
						offset: 0,
					},
					search: '',
				},
			}
		},
		changeSorting: (state, action) => {
			const sorting = action.payload
			return {
				...state,
				contents: {
					...state.contents,
					sorting: sorting,
				},
			}
		},
		changeSearch: (state, action) => {
			let initialSarchCriteriaWithoutSearch = initialSarchCriteria
			delete initialSarchCriteriaWithoutSearch.search
			const search = action.payload
			return {
				...state,
				contents: {
					...state.contents,
					filter: {
						category: '',
						subcategory: '',
					},
					search: search,
					pagination: {
						...state.contents.pagination,
						page: 1,
						offset: 0,
					},
				},
			}
		},
		changeAllSearchCriteria: (state) => {
			return {
				...state,
				contents: {
					...state.contents,
					...initialSarchCriteria,
				},
			}
		},
		nextPage: (state) => {
			const totalPages = state.contents.pagination.totalPages
			const currentPage = state.contents.pagination.page
			const currentOffset = state.contents.pagination.offset

			let newPage = currentPage
			let newOffset = currentOffset
			if (currentPage < totalPages) {
				newPage = currentPage + 1
				newOffset = currentOffset + state.contents.pagination.itemsPerPage
			}

			return {
				...state,
				contents: {
					...state.contents,
					pagination: {
						...state.contents.pagination,
						page: newPage,
						offset: newOffset,
					},
				},
			}
		},
		previousPage: (state) => {
			const currentPage = state.contents.pagination.page
			const currentOffset = state.contents.pagination.offset

			let newPage, newOffset
			if (currentPage - 1 < 1) {
				newPage = 1
				newOffset = 0
			} else {
				newPage = currentPage - 1
				newOffset = currentOffset - state.contents.pagination.itemsPerPage
			}

			return {
				...state,
				contents: {
					...state.contents,
					pagination: {
						...state.contents.pagination,
						page: newPage,
						offset: newOffset,
					},
				},
			}
		},
		updateIsShowScannedProduct: (state, actions) => {
			state.contents.isShowScanedProduct = actions.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchCatalog.pending, (state) => {
			state.isLoading = true
			state.error = null
		})
		builder.addCase(fetchCatalog.fulfilled, (state, action) => {
			const itemsPerPage = state.contents.pagination.itemsPerPage
			const totalItems = action.payload.pagination[0].TOTAL
			const totalPages = Math.ceil(totalItems / itemsPerPage)

			state.isLoading = false
			state.error = null
			state.contents.items = action.payload.items
			state.contents.pagination.totalItems = totalItems
			state.contents.pagination.totalPages = totalPages
		})
		builder.addCase(fetchCatalog.rejected, (state, action) => {
			state.isLoading = false
			state.error =
				action.error.message ||
				'Ocurrió un error, recargue la página por favor.'
		})
		builder.addCase(fetchCategories.pending, (state) => {
			state.contents.categories.isLoading = true
			state.contents.categories.error = null
		})
		builder.addCase(fetchCategories.fulfilled, (state, action) => {
			const currentItems = state.contents.categories.contents.items
			const updatedItems = [...currentItems, ...action.payload].sort((a, b) =>
				a.LineName.localeCompare(b.LineName)
			)
			state.contents.categories.isLoading = false
			state.contents.categories.contents.items = updatedItems
			state.contents.categories.error = null
		})
		builder.addCase(fetchCategories.rejected, (state, action) => {
			state.contents.categories.isLoading = false
			state.contents.categories.error =
				action.error.message || 'Ocurrió un error, recargue la página de nuevo.'
		})
	},
})

export const {
	resetCatalog,
	changeComponentShow,
	changeScannedProduct,
	changeScannedProductWithoutCount,
	changeFilter,
	changeSorting,
	changeSearch,
	changePagination,
	changeAllSearchCriteria,
	nextPage,
	previousPage,
	updateIsShowScannedProduct,
} = catalogSlice.actions

export default catalogSlice.reducer
