import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { apiUrl } from '../../../utils/var';

const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
    const [value, setValue] = useState("Valor inicial");
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    // Función para actualizar el estado general
    const updateValue = (newValue) => setValue(newValue);

    // Función para obtener los valores del dashboard
    const getDashboardValues = async () => {
        setIsLoading(true);
        setError(null); // Limpiar errores anteriores

        try {
            const response = await fetch(`${apiUrl}/api/dash/`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
            });

            const data = await response.json();

            if (!response.ok) {
                console.error(data.message);
                throw new Error(data.message);
            }
            console.log(data.data)

            // Actualizar estado con los datos formateados
            setData(data.data);
        } catch (error) {
            console.error("Error fetching dashboard values:", error.message);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <DashboardContext.Provider
            value={{
                value,
                updateValue,
                data,
                isLoading,
                error,
                getDashboardValues,
            }}
        >
            {children}
        </DashboardContext.Provider>
    );
};

DashboardProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DashboardContext;
