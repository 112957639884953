import React from 'react'
import PropTypes from 'prop-types'
import { apiUrl } from '../../../utils/var'
import ImageWithBasePath from '../../../core/img/imagewithbasebath'
import { formatCurrency } from '../../../utils/functions/money'

SaleResumeProduct.propTypes = {
	item: PropTypes.object,
}

export default function SaleResumeProduct({ item }) {
	return (
		<div className='d-flex justify-content-between align-items-center'>
			<ImageWithBasePath
				// url={`${apiUrl}/proxy/empleados/imagenes/ITEMS/${item.PicturName}`}
				url={`${apiUrl}/proxy/empleados/web/index.php?r=AppMovil/proxy/compress&filename=${item.PicturName}`}
				width={60}
				alt='product'
			/>
			<span
				className='text-start'
				style={{
					width: '65%',
				}}>
				{item.ItemName}
			</span>
			<span>{`${item.quantity} x ${formatCurrency(
				item.couponPrice
					? item.couponPrice
					: item.AvgPriceLiquidation
					? item.AvgPriceLiquidation
					: item.AvgPrice
			)}`}</span>
		</div>
	)
}
