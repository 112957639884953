import React, {   } from 'react'
import DashboardLayout from '../../../../layouts/DashboardLayout'
import CashCountHistoryTable from '../components/CashCountHistoryTable'
import PageTitle from '../../../../layouts/PagesTitle'
import Filter from '../components/Filter'
import Toolbar from '../../../../components/Toolbar'



export default function CashCountHistory() {
	return (
		<DashboardLayout>
			<PageTitle
				maintitle="Cortes de Caja"
				subtitle="Consulta todas los cortes de caja realizados en La Pape"
				toolbar={
				<Toolbar/>		
				}
			/>
			<div className='card table-list-card '>

				<div className='card-body'>
					<Filter/>
					<div className='table-responsive'>
						{/* <SelectDevice changeDevice={setDevice} /> */}
						<br/>
						<div className="card table-list-card">
							<div className="card-body">
							</div>
							<CashCountHistoryTable  />
						</div>
					</div>
				</div>
			</div>
		</DashboardLayout>
	)
}
