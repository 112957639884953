import React from 'react'
import { selectOrderIsPaying } from '../../../redux/selectors/order'
import { useSelector, useDispatch } from 'react-redux'
import { cleanWithdraw } from '../../../redux/withdraw'
import {
	initPaying,
	changeStatus,
	toggleShowModalOrder,
} from '../../../redux/order'
import PayMethod from '../components/PayMethod'

export default function PayMethodSection() {
	const isPaying = useSelector(selectOrderIsPaying)
	const dispatch = useDispatch()

	const generateOrder = () => {
		dispatch(initPaying())
		dispatch(changeStatus('inproccess'))
		dispatch(toggleShowModalOrder(true))
		// dispatch(toggleShowModalTicket(true))
	}

	return (
		<>
			<div className='payment-method'>
				<h6>Metodo de pago</h6>
				<div className='row d-flex align-items-center justify-content-center methods'>
					<PayMethod
						icon={'assets/img/icons/cash-pay.svg'}
						paymethod={'Efectivo'}
						key={'Efectivo'}
					/>
					<PayMethod
						icon={'assets/img/icons/credit-card.svg'}
						paymethod={'Tarjeta'}
						key={'Tarjeta'}
					/>
				</div>
			</div>
			<div className='px-5 py-3 btn-row d-sm-flex align-items-center justify-content-between'>
				<button
					disabled={isPaying}
					className='btn btn-icon flex-fill'
					onClick={() => {
						generateOrder()
						dispatch(cleanWithdraw())
					}}
					style={{
						backgroundColor: '#E71F85',
					}}>
					{isPaying ? 'Generando' : 'Generar orden'}
					{isPaying && (
						<div
							className='spinner-border spinner-border-sm ms-2'
							role='status'>
							<span className='sr-only'>Loading...</span>
						</div>
					)}
				</button>
			</div>
		</>
	)
}
