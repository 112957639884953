import React, { } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { SalesInfo } from "../components/SalesInfo";
import { SalesTables } from "../components/SalesTables";
import { DashboardProvider } from "../provider/Dashboard";
import { SalesFilters } from "../components/SalesFilters";
const Dashboard = () => {
	return (
		<div>
			<DashboardLayout>
				<DashboardProvider>
					
						<SalesFilters />
						<SalesInfo />
						<SalesTables />
				
				</DashboardProvider>
			</DashboardLayout >
		</div>
	);
};

export default Dashboard;
