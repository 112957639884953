import React from 'react'
import { PropTypes } from 'prop-types'
import { formatCurrency } from '../../../utils/functions/money'
import { apiUrl } from '../../../utils/var'

ProductItem.propTypes = {
	ItemCode: PropTypes.string,
	ItemName: PropTypes.string,
	AvgPrice: PropTypes.string,
	PicturName: PropTypes.string,
	OnHand: PropTypes.string,
	AvgPriceLiquidation: PropTypes.string,
}

export default function ProductItem({
	ItemCode,
	ItemName,
	AvgPrice,
	PicturName,
	OnHand,
	AvgPriceLiquidation,
}) {
	return (
		<>
			<div
				style={{
					background: 'white',
					paddingRight: 5 + '%',
					paddingTop: 10 + '%',
					paddingBottom: 10 + '%',
				}}
				className='product-list d-flex align-items-center justify-content-between'>
				<div className='d-flex align-items-center product-info'>
					<img
						// src={`http://thoro-ent.dyndns.info/empleados/imagenes/ITEMS/${PicturName}`}
						src={`${apiUrl}/proxy/empleados/web/index.php?r=AppMovil/proxy/compress&filename=${PicturName}`}
						alt='Productos'
					/>
				</div>
				<div className='info'>
					<span
						style={{
							color: 'white',
							background: '#e71f85',
							display: 'inline-block',
							padding: '5px 10px',
							borderRadius: 10 + 'px',
						}}>
						{ItemCode}
					</span>
					<h3 style={{ paddingTop: 10 + 'px' }}>{ItemName}</h3>
					<h3 style={{ paddingTop: 10 + 'px', color: '#e71f85' }}>
						{AvgPriceLiquidation ? (
							<div>
								Precio{' '}
								<span className='text-decoration-line-through'>
									{formatCurrency(AvgPrice)}
								</span>{' '}
								<span>{formatCurrency(AvgPriceLiquidation)}</span>
							</div>
						) : (
							<span>Precio {formatCurrency(AvgPrice)}</span>
						)}
					</h3>
					<h3 style={{ paddingTop: 10 + 'px', color: '#17a2b8' }}>
						Stock disponible: {parseInt(OnHand)}
					</h3>
				</div>
			</div>
		</>
	)
}
