import { Col, Form, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import FormControl from '../../../components/FormControl'
import {
	selectCashier,
	selectIsDataSaleComplete,
	selectIsLoadingFolioVenta,
	selectIsNotRedisteredOrder,
} from '../redux/selectors/new-sale'
import { useDispatch, useSelector } from 'react-redux'
import { apiUrl } from '../../../utils/var'
import {
	updateDataPreliminarInformation,
	updateIsDataSaleComplete,
} from '../redux/new-sale'

export default function StoreDataSelectionStoreInformationForm() {
	const dispatch = useDispatch()
	const isNotRegisteredOrder = useSelector(selectIsNotRedisteredOrder)
	const loadingFolioVenta = useSelector(selectIsLoadingFolioVenta)
	const cashier = useSelector(selectCashier)
	const isDataSaleComplete = useSelector(selectIsDataSaleComplete)
	const [form] = Form.useForm()
	const [cashiers, setCashiers] = useState(null)
	const [isLoadingEmployees, setIsLoadingEmployees] = useState(false)
	const [errorEmployees, setErrorEmployees] = useState(null)

	const handleConfirmSaleData = async (event) => {
		dispatch(
			updateDataPreliminarInformation({
				status: true,
				cashier: {
					EmployeeID: event.cashier,
					name: cashiers.find((cashier) => cashier.value === event.cashier)
						.label,
				},
			})
		)
	}

	const getEmployees = async () => {
		setErrorEmployees(null)
		try {
			setIsLoadingEmployees(true)
			const requestGetEmployees = await fetch(
				`${apiUrl}/api/cash-count/get-cashiers`,
				{
					method: 'GET',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin': '*',
					},
				}
			)

			const responseGetEmployees = await requestGetEmployees.json()

			if (!requestGetEmployees.ok) {
				console.error(responseGetEmployees.message)

				setErrorEmployees({
					status: responseGetEmployees.status,
					message: responseGetEmployees.message,
				})
			} else {
				setCashiers(responseGetEmployees.data)
			}
		} catch (error) {
			console.error(error)

			setErrorEmployees({
				status: 500,
				message:
					'Error al obtener los cajeros, contacte al administrador del sistema.',
			})
		} finally {
			setIsLoadingEmployees(false)
		}
	}

	useEffect(() => {
		getEmployees()
	}, [])

	useEffect(() => {
		if (isNotRegisteredOrder) {
			form.setFieldsValue({
				store: 'La Pape',
				companyName: 'Thoro',
				cashier: null,
			})
		} else {
			form.resetFields()
		}
	}, [isNotRegisteredOrder, form])

	useEffect(() => {
		if (cashiers && cashiers.length > 0) {
			if (cashier) {
				const cashierFinded = cashiers.find(
					(cashierSelected) => cashierSelected.value === cashier.EmployeeID
				)
				if (cashierFinded) {
					form.setFieldsValue({
						cashier: cashierFinded.value,
					})
				}
			}
		}
	}, [cashiers])

	return (
		<Form
			form={form}
			style={{
				marginTop: '24px',
			}}
			initialValues={{
				store: !isNotRegisteredOrder ? '' : 'La Pape',
				companyName: !isNotRegisteredOrder ? '' : 'Thoro',
			}}
			onFinish={handleConfirmSaleData}>
			<Row justify={'space-between'}>
				<Col style={{ width: '49%' }}>
					<Form.Item style={{ marginBottom: '0px' }}>
						<FormControl
							type='input'
							name='store'
							rules={[
								{
									required: true,
									message: 'La tienda es requerida.',
								},
							]}
							attributes={{
								placeholder: 'Tienda',
								disabled: true,
								className: 'w-100',
								size: 'large',
							}}
						/>
					</Form.Item>
				</Col>
				<Col style={{ width: '49%' }}>
					<Form.Item style={{ marginBottom: '0px' }}>
						<FormControl
							type='input'
							name='companyName'
							rules={[
								{
									required: true,
									message: 'La razón es requerida.',
								},
							]}
							attributes={{
								placeholder: 'Razón',
								disabled: true,
								className: 'w-100 ',
								size: 'large',
							}}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Form.Item className='w-100' style={{ marginBottom: '0px' }}>
				<FormControl
					type='select'
					name='cashier'
					rules={[
						{
							required: true,
							message: 'El cajero es requerido.',
						},
					]}
					attributes={{
						placeholder: `${
							isLoadingEmployees
								? 'Cargando cajeros...'
								: errorEmployees
								? 'Error al cargar cajeros'
								: 'Cajero'
						}`,
						disabled:
							!isNotRegisteredOrder ||
							isLoadingEmployees ||
							errorEmployees ||
							isDataSaleComplete,
						options: cashiers,
						size: 'large',
					}}
				/>
			</Form.Item>

			<div className='text-end'>
				<button
					type='button'
					className='btn btn-outline-secondary me-3'
					disabled={!isDataSaleComplete}
					onClick={() => {
						dispatch(updateIsDataSaleComplete(false))
					}}
					style={{
						height: 'auto',
					}}>
					Editar información
				</button>

				<button
					type='primary'
					className='btn btn-submit'
					disabled={
						!isNotRegisteredOrder || loadingFolioVenta || isDataSaleComplete
					}
					style={{ height: 'auto' }}>
					Confirmar
					{loadingFolioVenta && (
						<div
							className='spinner-border spinner-border-sm ms-2'
							role='status'>
							<span className='sr-only'>Loading...</span>
						</div>
					)}
				</button>
			</div>
			{errorEmployees && (
				<p className='text-danger'>{errorEmployees.message}</p>
			)}
		</Form>
	)
}
