import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { convertMilitaryTimeToStandardTimeFormat } from '../../../utils/functions/date'
import { formatCurrency } from '../../../utils/functions/money'
import { changeStatus } from '../../../redux/withdraw'
import { useDispatch, useSelector } from 'react-redux'
import {
	selectDataCashCount,
	selectErrorCashCount,
	selectFeedbackCashCount,
	selectIsShowFeedbackCashCount,
} from '../redux/selectors/cash-count'
import { updateIsShowFeedbackCashCount } from '../redux/cash-count'
import { Descriptions } from 'antd'

export default function FeedbackExecuteCashCount() {
	const dispatch = useDispatch()
	const isShowFeedbackCashCount = useSelector(selectIsShowFeedbackCashCount)
	const feedbackCashCount = useSelector(selectFeedbackCashCount)
	const errorCashCount = useSelector(selectErrorCashCount)
	const dataCashCount = useSelector(selectDataCashCount)

	return (
		<Modal centered size='md' show={isShowFeedbackCashCount}>
			<Modal.Header>
				<Modal.Title as={'h4'}>
					{errorCashCount ? 'Error' : 'Correcto'}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{errorCashCount ? (
					<span>{errorCashCount}</span>
				) : (
					<>
						<p>{feedbackCashCount}</p>
						{dataCashCount && (
							<Descriptions bordered column={1}>
								{dataCashCount.date_created && dataCashCount.time_created && (
									<Descriptions.Item label='Apertura'>
										{`${
											dataCashCount.date_created
										} ${convertMilitaryTimeToStandardTimeFormat(
											dataCashCount.time_created
										)}`}
									</Descriptions.Item>
								)}
								{dataCashCount.date_closed && dataCashCount.time_closed && (
									<Descriptions.Item label='Cierre'>
										{`${
											dataCashCount.date_closed
										} ${convertMilitaryTimeToStandardTimeFormat(
											dataCashCount.time_closed
										)}`}
									</Descriptions.Item>
								)}
								{dataCashCount.user && (
									<Descriptions.Item label='Usuario'>
										{dataCashCount.user}
									</Descriptions.Item>
								)}
								{dataCashCount.device && (
									<Descriptions.Item label='Dispositivo'>
										{dataCashCount.device}
									</Descriptions.Item>
								)}
								{dataCashCount.total_due && (
									<Descriptions.Item label='Total Ventas'>
										{formatCurrency(dataCashCount.total_due)}
									</Descriptions.Item>
								)}
								{dataCashCount?.total_surrendered && (
									<Descriptions.Item label='Total Entregado'>
										{formatCurrency(dataCashCount?.total_surrendered)}
									</Descriptions.Item>
								)}
								{dataCashCount.difference && (
									<Descriptions.Item
										label={
											dataCashCount.difference > -1 ? 'Faltante' : 'Sobrante'
										}>
										{formatCurrency(
											dataCashCount.difference > -1
												? dataCashCount.difference
												: Math.abs(dataCashCount.difference)
										)}
									</Descriptions.Item>
								)}
								{dataCashCount.fund && (
									<Descriptions.Item label='Fondo de Caja'>
										{formatCurrency(dataCashCount.fund)}
									</Descriptions.Item>
								)}
							</Descriptions>
						)}
					</>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button
					onClick={() => {
						dispatch(updateIsShowFeedbackCashCount(false))
						dispatch(changeStatus('noinit'))
					}}
					variant='pape_cancel'>
					Cerrar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
