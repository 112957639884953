import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { formatCurrency } from '../../../utils/functions/money'
import { selectCart } from '../../../redux/selectors/cart'
import { Table } from 'antd'
import { tableOrderResumeItems } from '../../../utils/datatablesVar'
import { apiUrl } from '../../../utils/var'

export default function ModalOrderList() {
	const cart = useSelector(selectCart)
	const contenedorRef = useRef(null)

	useEffect(() => {
		if (contenedorRef.current) {
			contenedorRef.current.scrollTop = contenedorRef.current.scrollHeight
		}
	}, [cart])

	return (
		<div className='table-responsive no-pagination'>
			<div className='table-responsive'>
				<h3 className='mb-2'>Productos</h3>
				<Table
					pagination={false}
					bordered={true}
					columns={tableOrderResumeItems}
					rowKey={'itemCode'}
					dataSource={cart.map((item) => {
						const cartFormatted = {
							itemCode: item.ItemCode,
							// image: `http://thoro-ent.dyndns.info/empleados/imagenes/ITEMS/${item.PicturName}`,
							image: `${apiUrl}/proxy/empleados/web/index.php?r=AppMovil/proxy/compress&filename=${item.PicturName}`,
							name: item.ItemName,
							quantity: item.quantity,
							prices: {
								priceLiquidation:
									item.AvgPriceLiquidation && item.couponPrice ? (
										<span style={{ color: 'red' }}>
											{formatCurrency(item.AvgPriceLiquidation)}
										</span>
									) : item.AvgPriceLiquidation ? (
										<span style={{ color: 'black' }}>
											{formatCurrency(item.AvgPriceLiquidation)}
										</span>
									) : null,
								priceWithDiscount: item.couponPrice ? (
									<span style={{ color: 'black' }}>
										{formatCurrency(item.couponPrice)}
									</span>
								) : null,
								price: (
									<span style={{ color: 'red' }}>
										{formatCurrency(item.AvgPrice)}
									</span>
								),
							},
							totalByProduct: formatCurrency(
								item.quantity *
									(item.couponPrice
										? item.couponPrice
										: item.AvgPriceLiquidation
										? item.AvgPriceLiquidation
										: item.AvgPrice)
							),
						}

						return cartFormatted
					})}
				/>
			</div>
		</div>
	)
}
