import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../../../layouts/DashboardLayout'
import Swal from 'sweetalert2'
import SettingSwitch from '../components/SettingSwitch'
import {
	Print,
	clearPrinterSettings,
	verifyConnectionToPrinter,
} from '../../../../utils/functions/printTicket'
import { apiUrl } from '../../../../utils/var'
import {
	showSweetAlertError,
	showSweetAlertSuccess,
	handleDeleteConfirmation,
	showErrorPrint,
	showSweetAlertLoadingAnimation,
	showSweetAlertSuccess2,
	showSweetAlertErrorConnecting,
	showSweetAlerrSuccessConnection,
} from '../components/Alerts'
import PrinterSettingsForm from '../components/PrinterSettingsForm'
import ButtonHeader from '../components/ButtonHeader'

const PrinterSettings = () => {
	const [errorPrinting, setErrorPrinting] = useState(null)
	const [loadingPrinting, setLoadingPrinting] = useState(null)
	const [successPrinting, setSuccessPrinting] = useState(null)
	const [verifingConnection, setVerifingConnection] = useState(false)
	const [isConfigured, setIsConfigured] = useState(false)
	const [usePrinter, setUsePrinter] = useState(false)
	const [selections, setSelections] = useState({
		printer: null,
		logo: null,
		razon: null,
		tienda: null,
		rfc: null,
		ubicacion: null,
		telefono: null,
		selectedMethods: [],
		usePrinter: false,
	})

	const printers = [
		{ value: '192.168.1.28', label: 'POS-PRT-01' },
		{ value: '192.168.1.29', label: 'POS-PRT-02' },
	]
	const logos = [{ value: 'LA_PAPE', label: 'LOGO LA PAPE' }]
	const razones = [
		{
			value: 'THORO ENTERPRISES DE MEXICO S.A. DE C.V.',
			label: 'THORO ENTERPRISES DE MEXICO S.A. DE C.V.',
		},
	]
	const tiendas = [{ value: 'LA PAPE', label: 'LA PAPE' }]
	const rfc = [{ value: 'TEM110114EM8', label: 'TEM110114EM8' }]
	const ubicaciones = [
		{
			value:
				'AV. MEXICO-JAPON #412, BODEGA 31 COL. CIUDAD INDUSTRIAL, C.P. 38010, CELAYA, GUANAJUATO, MEXICO',
			label: 'THORO',
		},
	]
	const telefonos = [{ value: '4612031000', label: 'LA PAPE' }]
	const paymentMethods = [
		'Tickets de venta',
		'Orden con efectivo',
		'Orden con terminal Inbursa',
		'Orden con terminal BBVA',
		'Retiro cajero',
		'Retiro encargado',
		'Corte',
	]

	const handleSwitchChange = (checked) => {
		console.log('checked', checked)
		setUsePrinter(checked)
		setSelections((prevSelections) => ({
			...prevSelections,
			usePrinter: checked,
		}))
	}

	const handleSelectChange = (value, field) => {
		setSelections((prevSelections) => ({
			...prevSelections,
			[field]: field === 'usePrinter' ? value.target.checked : value,
		}))
	}

	const handleSubmit = async (e) => {
		e.preventDefault()

		const allSelected = Object.values(selections).every(
			(value) => value !== null
		)
		if (!allSelected & usePrinter) {
			await showSweetAlertError()
			return
		}

		const configData = {
			...selections,
			isConfigured: true,
		}

		localStorage.setItem('printerSettings', JSON.stringify(configData))
		setIsConfigured(true)
		await showSweetAlertSuccess()
	}

	const restoreConfigurations = async () => {
		//Limpiamos los datos del localStorage
		clearPrinterSettings()

		//Limpiamos las selecciones
		setSelections({
			printer: null,
			logo: null,
			razon: null,
			tienda: null,
			rfc: null,
			ubicacion: null,
			telefono: null,
			usePrinter: false,
		})

		//Confiuguramos los switches en desactivado
		setIsConfigured(false)
	}

	const handlePaymentMethodChange = (method) => {
		setSelections((prev) => {
			const isSelected = prev.selectedMethods.includes(method)
			const updatedMethods = isSelected
				? prev.selectedMethods.filter((m) => m !== method)
				: [...prev.selectedMethods, method]

			return { ...prev, selectedMethods: updatedMethods }
		})
	}

	const handlePrintTicket = async () => {
		setLoadingPrinting(true)
		setErrorPrinting(null)

		try {
			// Mostrar la animación de carga inmediatamente
			showSweetAlertLoadingAnimation()

			const responsePrint = await Print(
				{
					Type_XML: 'TEST',
					QrCode: 'www.thoro.com.mx',
					BarCode: '600000001',
				},
				`${apiUrl}/api/print/ticket/test`,
				'POST'
			)

			if (responsePrint.statusCode !== 200) {
				console.log(responsePrint)
				throw new Error(
					`Error al imprimir el ticket: ${responsePrint.message} - ${
						responsePrint.errors ?? ''
					}`
				)
			}

			setSuccessPrinting(true)
			console.log('Respuesta de impresión:', responsePrint)
		} catch (error) {
			console.error('Error en catch:', error)
			setErrorPrinting(
				error.message + '. Intentelo de nuevo o contacte a soporte.'
			) // Esto activará el useEffect para mostrar el error
		} finally {
			setLoadingPrinting(false) // Actualizamos loadingPrinting al finalizar
		}
	}

	const verifyPrinter = async () => {
		setVerifingConnection(true)

		try {
			const printerResponse = await verifyConnectionToPrinter(
				selections.printer,
				'9100'
			)

			if (printerResponse.statusCode != 200) {
				console.error(printerResponse)
				await showSweetAlertErrorConnecting(selections.printer, '9100')
			} else {
				await showSweetAlerrSuccessConnection()
			}
		} catch (error) {
			console.error(error)
			Swal.fire({
				icon: 'error',
				title: 'Error con el servicio de impresion',
				text: 'Ocurrión un error al tratar de comunicarse con el servicio de impresion, intentelo de nuevo o llame a soporte técnico.',
			})
		}
		setVerifingConnection(false)
	}

	useEffect(() => {
		const savedConfig = JSON.parse(localStorage.getItem('printerSettings'))
		if (savedConfig && savedConfig.isConfigured) {
			setSelections(savedConfig)
			setIsConfigured(true)
			setUsePrinter(savedConfig.usePrinter)
			console.log('esta configurada la impresora: ', isConfigured)
		}
	}, [])

	useEffect(() => {
		if (errorPrinting) {
			Swal.close()
			showErrorPrint(errorPrinting)
		}
	}, [errorPrinting])

	useEffect(() => {
		if (loadingPrinting && !errorPrinting) {
			showSweetAlertLoadingAnimation()
		} else if (!loadingPrinting && !errorPrinting && successPrinting) {
			Swal.close()
			showSweetAlertSuccess2()
		}
	}, [loadingPrinting, errorPrinting, successPrinting])

	return (
		<DashboardLayout>
				
				<div className='row'>
					<div className='col-xl-12'>
						<div className='settings-wrapper d-flex'>
							<div className='settings-page-wrap'>
								<div className='col-xl-12'>
									<div className='settings-wrapper d-flex'>
										<div className='settings-page-wrap'>
											<div className='setting-title'>
												<h4>Configurar impresora Térmica</h4>
											</div>
											<ButtonHeader handlePrintTicket={handlePrintTicket} />
											<br></br>
											<SettingSwitch
												label='Activar impresora térmica:'
												description='Permite activar o desactivar completemente el uso de la impresora en todos los lugares donde se utilice'
												checked={selections.usePrinter}
												onChange={(e) => handleSwitchChange(e)}
											/>
											<br></br>
											<PrinterSettingsForm
												printers={printers}
												logos={logos}
												razones={razones}
												tiendas={tiendas}
												ubicaciones={ubicaciones}
												telefonos={telefonos}
												rfc={rfc}
												selections={selections}
												paymentMethods={paymentMethods}
												verifingConnection={verifingConnection}
												handleSelectChange={handleSelectChange}
												verifyPrinter={verifyPrinter}
												handleSwitchChange={handleSwitchChange}
												handlePaymentMethodChange={handlePaymentMethodChange}
												handleDeleteConfirmation={handleDeleteConfirmation}
												restoreConfigurations={restoreConfigurations}
												onSubmit={handleSubmit}
												usePrinter={!usePrinter}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
		</DashboardLayout>
	)
}

export default PrinterSettings
