import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { eraseCoupon } from '../../../redux/coupon'
import { verifyCartWithCoupon } from '../../../redux/coupon'
import { Form, Button as ButtonAnt } from 'antd'
import { Button } from 'react-bootstrap'
import FormControl from '../../../components/FormControl'
import {
	selectCouponErrorValidate,
	selectCouponIsLoadingValidate,
	selectCouponCode,
} from '../../../redux/selectors/coupon'
import { removeDiscountPricesFromCart } from '../redux/cart'
import { selectIsLoadingTerminalPay } from '../../../redux/selectors/order'

export default function Coupon() {
	const dispatch = useDispatch()
	const [form] = Form.useForm()
	const couponErrorValidate = useSelector(selectCouponErrorValidate)
	const couponIsLoadingValidate = useSelector(selectCouponIsLoadingValidate)
	const couponCodeStoraged = useSelector(selectCouponCode)
	const isLoadingValidate = useSelector(selectCouponIsLoadingValidate)
	const isLoadingTerminalPay = useSelector(selectIsLoadingTerminalPay)

	const handleApplyCoupon = async (values) => {
		const { code } = values
		dispatch(verifyCartWithCoupon(code.toUpperCase()))
	}

	return (
		<div className='d-flex flex-column'>
			<div className='d-flex'>
				<Form
					form={form}
					name='apply-coupon'
					onFinish={handleApplyCoupon}
					className='w-100'>
					<div className='d-flex justify-content-between gap-2'>
						<FormControl
							key='code'
							name='code'
							type='input'
							rules={[
								{
									required: true,
									message: 'El código es requerido',
								},
							]}
							attributes={{
								disabled: isLoadingTerminalPay,
								placeholder: 'Código de cupón',
								size: 'large',
								className: 'w-100',
								style: {
									'margin-bottom': '-10px',
									'textTransform': 'uppercase'
								},
								onChange: (e) => {
									e.target.value = e.target.value.toUpperCase();
									console.log(e.target.value)
								},
							}}
						/>
						{couponCodeStoraged && (
							<ButtonAnt
								onClick={() => {
									dispatch(eraseCoupon())
									dispatch(removeDiscountPricesFromCart())
									form.setFieldValue('code', null)
								}}
								danger
								type='primary'
								size='large'>
								Eliminar cupón
							</ButtonAnt>
						)}
					</div>

					<Form.Item>
						<Button
							disabled={isLoadingValidate || isLoadingTerminalPay}
							type='primary'
							className='w-100 py-2 mt-0'
							variant='pape_blue'>
							Aplicar cupón
							{couponIsLoadingValidate && (
								<div
									className='spinner-border spinner-border-sm ms-2'
									role='status'>
									<span className='sr-only'>Loading...</span>
								</div>
							)}
						</Button>
					</Form.Item>
				</Form>
			</div>
			{couponErrorValidate && (
				<span className='text-danger'>{couponErrorValidate}</span>
			)}
		</div>
	)
}
