import React from 'react'
import { useSelector } from 'react-redux'
import {
	selectCatalog,
	selectIsCatalogLoading,
	selectCatalogError,
} from '../../../redux/selectors/catalog'
import Search from './Search'
import SkeletonCatalog from '../components/SkeletonCatalog'
import CardItem from '../components/CardItem'
import Pagination from '../components/Pagination'

export default function Catalog() {
	const products = useSelector(selectCatalog)
	const isCatalogLoading = useSelector(selectIsCatalogLoading)
	const catalogError = useSelector(selectCatalogError)

	return (
		<>
			<div className='w-100 p-2'>
				<Search />
				<div
					className='scroll-pape p-3 mt-3'
					style={{ height: '70vh', overflowX: 'hidden', overflowY: 'scroll' }}>
					{isCatalogLoading ? (
						<SkeletonCatalog />
					) : catalogError ? (
						<div className='text-danger'>{catalogError}</div>
					) : products.length ? (
						<div className='d-flex flex-column w-100 gap-1 pb-3'>
							<div className='row overflow-hidden'>
								{products.map((product, index) => (
									<CardItem product={product} key={index} />
								))}
							</div>
							<Pagination />
						</div>
					) : (
						<div>Sin Productos</div>
					)}
				</div>
			</div>
		</>
	)
}
