import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiUrl, idDevice } from '../utils/var'
import { deleteCookie } from '../utils/functions/cookies'
import { resetChangableStatesWithdraw } from './withdraw'

export const login = createAsyncThunk(
	'authentication/login',
	async ({ userId, userPass }) => {
		try {
			const device = idDevice()
			const url = `${apiUrl}/api/auth/login`
			const response = await fetch(url, {
				method: 'POST',
				body: JSON.stringify({
					userId,
					userPass,
					device,
				}),
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				credentials: 'include',
			})

			const body = await response.json()

			if (!response.ok) {
				let errorMessage
				try {
					errorMessage = body.message || 'Error desconocido'
				} catch {
					errorMessage = 'Ocurrió un error, intentelo de nuevo.'
				}

				const errorInfo = {
					code: response.status,
					message: errorMessage,
					url: url,
				}

				throw new Error(JSON.stringify(errorInfo))
			}

			return body.user
		} catch (error) {
			console.error(error.message)

			let errorMessage

			try {
				const errorInfo = JSON.parse(error.message)
				errorMessage = errorInfo.message
			} catch {
				errorMessage = `Ocurrió un error, intentelo de nuevo o contacte a soporte: ${error}`
			}
			throw new Error(errorMessage)
		}
	}
)

export const logout = createAsyncThunk(
	'authentication/logout',
	async (_, { dispatch }) => {
		try {
			const url = `${apiUrl}/api/auth/logout`

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				credentials: 'include',
			})

			if (!response.ok) {
				let errorMessage
				try {
					const errorBody = await response.json()
					errorMessage = errorBody.message || 'Error desconocido'
				} catch {
					errorMessage = 'Ocurrió un error, intentelo de nuevo.'
				}

				const errorInfo = {
					code: response.status,
					message: errorMessage,
					url: url,
				}

				throw new Error(JSON.stringify(errorInfo))
			}

			dispatch(resetChangableStatesWithdraw())
		} catch (error) {
			let errorMessage

			try {
				const errorInfo = JSON.parse(error.message)
				errorMessage = errorInfo.message
			} catch {
				errorMessage = 'Ocurrió un error, intentelo de nuevo.'
			}
			throw new Error(errorMessage)
		}
	}
)

export const authenticationSlice = createSlice({
	name: 'authentication',
	initialState: {
		isLoading: false,
		isAuthenticated: false,
		user: null,
		expires: null,
		error: {
			code: null,
			message: null,
		},
		logout: {
			isLoading: false,
			error: {
				code: null,
				message: null,
			},
		},
	},
	reducers: {
		changeError: (state, action) => {
			state.error = action.payload
		},
		destroyJWT: () => {
			deleteCookie('connect.sid')
		},
		resetLoadingStates: (state) => {
			state.isLoading = false
			state.error = {
				code: null,
				message: null,
			}
			state.logout = {
				isLoading: false,
				error: {
					code: null,
					message: null,
				},
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(login.pending, (state) => {
			state.isLoading = true
		})
		builder.addCase(login.fulfilled, (state, data) => {
			const user = data.payload
			state.isLoading = false
			state.isAuthenticated = true
			state.user = user
			state.expires = user.exp
		})
		builder.addCase(login.rejected, (state, action) => {
			state.isLoading = false
			state.error.message =
				action.error.message || 'Ocurrió un error, intentelo de nuevo'
			state.error.code = action.error.code || 500
		})
		builder.addCase(logout.pending, (state) => {
			state.logout.isLoading = true
		})
		builder.addCase(logout.fulfilled, (state) => {
			state.isLoading = false
			state.isAuthenticated = false
			state.user = null
			state.expires = null
			state.error = {
				code: null,
				message: null,
			}

			state.logout.isLoading = false
			state.logout.error = {
				code: null,
				message: null,
			}
		})
		builder.addCase(logout.rejected, (state, action) => {
			state.logout.isLoading = false
			state.logout.error.message =
				action.error.message || 'Ocurrió un error, intentelo de nuevo'
			state.logout.error.code = action.error.code || 500
		})
	},
})

export const { changeError, destroyJWT, changeUserData, resetLoadingStates } =
	authenticationSlice.actions

export default authenticationSlice.reducer
