import { Form } from 'antd'
import React, { /* useContext, */ useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	selectError,
	selectFolioVenta,
	selectFolioVentaCopy,
	selectIsDataSaleComplete,
	selectIsLoadingFolioVenta,
} from '../redux/selectors/new-sale'
import FormControl from '../../../components/FormControl'
import {
	searchFolio,
	setFolioVenta,
	updateFolioVentaCopy,
} from '../redux/new-sale'
import { Button } from 'react-bootstrap'
//import { PinpadContext } from '../../../provider/Pinpad'

export default function StoreDataSelectionFolioForm() {
	const dispatch = useDispatch()
	const loadingFolioVenta = useSelector(selectIsLoadingFolioVenta)
	const error = useSelector(selectError)
	const stateFolio = useSelector(selectFolioVenta)
	const stateFolioConfirmation = useSelector(selectFolioVentaCopy)
	const isDataSaleComplete = useSelector(selectIsDataSaleComplete)
	const [form] = Form.useForm()
	const [folio, setFolio] = useState(stateFolio)
	const [confirmFolio, setConfirmFolio] = useState(stateFolioConfirmation)
	//const { isLoadingMovements, consultation, consultations } = useContext(PinpadContext)

	const handleSubmit = () => {
		dispatch(searchFolio(folio))
	}

	const handleFolio = (event) => {
		setFolio(event.target.value)
	}

	const handleConfirmFolio = (event) => {
		setConfirmFolio(event.target.value)
	}

	useEffect(() => {
		dispatch(setFolioVenta({ folioVenta: folio }))
		form.setFieldValue('folio', folio)
	}, [folio])

	useEffect(() => {
		dispatch(updateFolioVentaCopy(confirmFolio))
		form.setFieldValue('confirmFolio', confirmFolio)
	}, [confirmFolio])

	return (
		<div>
			<Form
				className='d-flex align-items-center gap-3 mb-1'
				form={form}
				name='validar_folio'
				onFinish={handleSubmit}
				autoComplete='off'
				initialValues={{
					folio: folio,
					confirmFolio: confirmFolio,
				}}>
				<div
					className='d-flex align-items-center gap-3 mb-1'
					style={{
						marginTop: '24px',
					}}>
					<FormControl
						name='folio'
						type='input'
						label={'Folio de venta'}
						attributes={{
							value: folio,
							placeholder: 'Folio de venta',
							onChange: handleFolio,
							size: 'large',
							disabled: loadingFolioVenta || isDataSaleComplete,
						}}
						rules={[
							{
								required: true,
								message: 'El folio es requerido.',
							},
						]}
					/>

					<FormControl
						name='confirmFolio'
						type='input'
						label={'Confirmar folio de venta'}
						attributes={{
							value: confirmFolio,
							placeholder: 'Confirmar folio de venta',
							onChange: handleConfirmFolio,
							size: 'large',
							disabled: loadingFolioVenta || isDataSaleComplete,
						}}
						rules={[
							{
								required: true,
								message: 'Confirmación de folio requerida.',
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue('folio') === value) {
										return Promise.resolve()
									}
									return Promise.reject(new Error('Los folios no coinciden'))
								},
							}),
						]}
					/>

					{/* <FormControl
						name='dateSale'
						type='date'
						label='Fecha del cobro'
						rules={[

						]}
						attributes={{
							placeholder: 'Fecha de consulta'
						}}

						placeholder='Fecha de consulta'
								className='mb-4 w-25'
								onChange={(date) => setDateSearched(date)}
								value={dateSearched}
					/> */}
				</div>

				<Button
					type='primary'
					className='btn-submit'
					disabled={loadingFolioVenta || isDataSaleComplete}
					style={{ height: 'auto' }}>
					Buscar
					{loadingFolioVenta && (
						<div
							className='spinner-border spinner-border-sm ms-2'
							role='status'>
							<span className='sr-only'>Loading...</span>
						</div>
					)}
				</Button>
			</Form>
			{error && <p className='text-danger'>{error}</p>}
		</div>
	)
}
