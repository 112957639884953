import React, { useContext, useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {
	selectOrderIsShowModalOrderInformation,
	selectOrderError,
	selectOrderPayData,
	selectOrderStatus,
	selectPayMethod,
} from '../../../redux/selectors/order'
import {
	changeCash,
	changeExchange,
	changeIsLoadingTerminalPay,
	cleanError,
	cleanPinpaPayBody,
	hideModalOrderInformation,
	updateVouchers,
} from '../../../redux/order'
import { useSelector, useDispatch } from 'react-redux'
import {
	AlertTriangle,
	Check,
	Info,
} from 'feather-icons-react/build/IconComponents'
import { PinpadContext } from '../../../provider/Pinpad'
import { CreditCard } from 'react-feather'
import { fetchCatalog } from '../../../redux/catalog'

export default function InformationModal() {
	const { cancelReadCard, isPinpadConfigured } = useContext(PinpadContext)
	const [content, setContent] = useState('')
	const [textButton, setTextButton] = useState('')
	const [title, setTitle] = useState('')
	const [icon, setIcon] = useState(<Info />)
	const dispatch = useDispatch()
	const error = useSelector(selectOrderError)
	const payData = useSelector(selectOrderPayData)
	const payMethod = useSelector(selectPayMethod)
	const status = useSelector(selectOrderStatus)
	const isShowModalOrderInformation = useSelector(
		selectOrderIsShowModalOrderInformation
	)

	useEffect(() => {
		switch (status) {
			case 'readingcard':
				setContent('Leyendo tarjeta')
				setTextButton('Cancelar')
				setTitle('Pago con tarjeta')
				setIcon(<CreditCard />)
				break
			case 'cancelled':
				setContent('Pago cancelado')
				setIcon(<AlertTriangle />)
				break
			case 'comprobatemerchant':
				setContent('Comprobando informacion de la terminal.')
				setTitle('Pago con tarjeta')
				setIcon(<CreditCard />)
				break
			case 'executingpay':
				setContent('Ejecutanto pago')
				setTitle('Pago con tarjeta')
				setIcon(<CreditCard />)
				break
			case 'executingpaywithconsultation':
				setContent('Ejecutando pago con consulta')
				setTitle('Pago con tarjeta')
				setIcon(<CreditCard />)
				break
			case 'payed':
				setContent('Pago realizado, procediendo con la orden...')
				setTitle('Procesando orden')
				setIcon(<Check />)
				break
			case 'complete':
				setContent('Orden realizada correctamente')
				setTextButton('Cerrar')
				setTitle('Completado')
				setIcon(<Check />)
				break
			case 'error':
				setContent(error)
				setTextButton('Cerrar')
				setTitle('Error')
				setIcon(<AlertTriangle />)
				break
			default:
				setContent('Procesando')
				setTextButton('Cerrar')
				setTitle('Procesando')
				setIcon(<Info />)
				break
		}
	}, [status])

	useEffect(() => {
		if (payData && status == 'complete') {
			setContent(
				<div className='d-flex flex-column gap-2'>
					<p>Orden correctamente procesada</p>
					{Object.keys(payData).map((keyObject, index) => (
						<span key={index}>
							<b>{`${keyObject}`}:&nbsp;</b>
							{payData[keyObject]}
						</span>
					))}
				</div>
			)
		}
	}, [payData, status])

	return (
		<Modal size='sm' centered show={isShowModalOrderInformation}>
			<Modal.Header>
				{icon}
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{error ? <>{error}</> : <>{content}</>}</Modal.Body>
			{status === 'readingcard' ||
			status === 'complete' ||
			status === 'error' ? (
				<Modal.Footer>
					<Button
						onClick={() => {
							dispatch(hideModalOrderInformation())
							dispatch(changeCash(''))
							dispatch(changeExchange(''))
							dispatch(cleanPinpaPayBody())
							dispatch(changeIsLoadingTerminalPay(false))
							dispatch(cleanError())
							dispatch(
								updateVouchers({
									voucherBusiness: null,
									voucherClient: null,
								})
							)
							dispatch(fetchCatalog())

							if (
								payMethod === 'Tarjeta' &&
								isPinpadConfigured &&
								status === 'readingcard'
							) {
								cancelReadCard()
							}
						}}>
						{textButton}
					</Button>
				</Modal.Footer>
			) : (
				<></>
			)}
		</Modal>
	)
}
