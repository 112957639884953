import React from 'react'
import { selectPayMethod } from '../../../redux/selectors/order'
import {
	selectCartTotal,
	selectCartSubtotal,
	selectCartDiscount,
	selectDiscontLiquidation,
} from '../../../redux/selectors/cart'
import {
	selectCouponAmount,
	selectCouponCode,
	selectCouponType,
} from '../../../redux/selectors/coupon'
import {
	selectOrderCash,
	selectOrderExchange,
} from '../../../redux/selectors/order'
import { useSelector } from 'react-redux'
import { formatCurrency } from '../../../utils/functions/money'
import { Descriptions } from 'antd'

export default function OrderListClientAmounts() {
	const cash = useSelector(selectOrderCash)
	const exchange = useSelector(selectOrderExchange)
	const total = useSelector(selectCartTotal)
	const subtotal = useSelector(selectCartSubtotal)
	const discount = useSelector(selectCartDiscount)
	const couponAmount = useSelector(selectCouponAmount)
	const couponCode = useSelector(selectCouponCode)
	const couponType = useSelector(selectCouponType)
	const discountLiquidation = useSelector(selectDiscontLiquidation)
	const payMethod = useSelector(selectPayMethod)

	

	return (
		<Descriptions
			bordered={true}
			items={[
				{
					key: 'subtotal',
					label: 'Subtotal',
					children: formatCurrency(subtotal),
					span: 5				
				},
				...(couponCode
					? [
							{
								key: 'coupon_code',
								label: 'Cupón aplicado',
								children: couponCode,
								span: 5
							},
							{
								key: 'discount_amount',
								label: 'Descuento',
								children:
									couponType === 'per'
										? `${couponAmount}%`
										: `${formatCurrency(couponAmount)}`,
								span: 5
							},
							{
								key: 'discount_value',
								label: 'Cantidad descontada',
								children: formatCurrency(discount),
								span: 5
							},
					]
					: []),
				...(discountLiquidation && discountLiquidation > 0
					? [
							{
								key: 'liquidation_save',
								label: 'Ahorro por liquidación',
								children: formatCurrency(discountLiquidation),
								span: 5
							},
					]
					: []),
				{
					key: 'payment_type',
					label: 'Tipo de pago',
					children: payMethod,
					span: 5
				},
				{
					key: 'total',
					label: 'Total',
					children: formatCurrency(total),
					span: 5
				},
				...(payMethod === 'Efectivo'
					? [
							{
								key: 'cash',
								label: 'Efectivo entregado',
								children: formatCurrency(cash),
								span: 5
							},
							{
								key: 'exchange',
								label: 'Cambio',
								children: formatCurrency(exchange),
								span: 5
							},
					]
					: []),
			]}
		/>
	)
}
