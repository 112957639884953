import React, { } from "react";
import {
    RotateCcw,
    Calendar,
} from "feather-icons-react/build/IconComponents";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import dayjs from "dayjs";
import { Cascader, DatePicker } from "antd";
const { RangePicker } = DatePicker;

export const SalesFilters = () => {
    const options = [
        {
            value: 'zhejiang',
            label: 'Zhejiang',
            children: [
                {
                    value: 'hangzhou',
                    label: 'Hanzhou',
                    children: [
                        {
                            value: 'xihu',
                            label: 'West Lake',
                        },
                    ],
                },
            ],
        },
        {
            value: 'jiangsu',
            label: 'Jiangsu',
            children: [
                {
                    value: 'nanjing',
                    label: 'Nanjing',
                    children: [
                        {
                            value: 'zhonghuamen',
                            label: 'Zhong Hua Men',
                        },
                    ],
                },
            ],
        },
    ];
    const onChange = (value) => {
        console.log(value);
    };

    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            console.log('From: ', dates[0], ', to: ', dates[1]);
            console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
        } else {
            console.log('Clear');
        }
    };
    const rangePresets = [
        {
            label: 'Last 7 Days',
            value: [dayjs().add(-7, 'd'), dayjs()],
        },
        {
            label: 'Last 14 Days',
            value: [dayjs().add(-14, 'd'), dayjs()],
        },
        {
            label: 'Last 30 Days',
            value: [dayjs().add(-30, 'd'), dayjs()],
        },
        {
            label: 'Last 90 Days',
            value: [dayjs().add(-90, 'd'), dayjs()],
        },
    ];
    const renderRefreshTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Refresh
        </Tooltip>
    );
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="welcome d-lg-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center welcome-text">
                        <h3 className="d-flex align-items-center">
                            <ImageWithBasePath src="assets/img/icons/hi.svg" alt="img" />
                            &nbsp;Hola INNOVACIÓN,
                        </h3>
                        &nbsp;
                        <h6>esto es lo que ha estado pasando en la tienda hoy. </h6>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="row">
                            <div className="col-md-5">
                                <RangePicker
                                    presets={rangePresets}
                                    onChange={onRangeChange}
                                    nextIcon={<Calendar className="feather-14" />}

                                    style={{

                                    }}
                                />
                            </div>
                            <div className="col-md-5">
                                <Cascader options={options}
                                    onChange={onChange}
                                    placeholder="Seleccionar"
                                    // loading={isLoading}
                                    // disabled={isLoading}
                                    style={{ minWidth: '250px', width: '100%', height: '40px' }}
                                    changeOnSelect
                                />
                            </div>
                            <div className="col-md-2">
                                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                                    <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                        <RotateCcw className="feather feather-rotate-ccw feather-16" />
                                    </Link>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}