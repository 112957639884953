import React from 'react'
import StoreDataSelectionFolioForm from '../layout/StoreDataSelectionFolioForm'
import StoreDataSelectionStoreInformationForm from '../layout/StoreDataSelectionStoreInformationForm'

const StoreDataSection = () => {
	return (
		<div className='barcode-content-list' style={{ marginBottom: '15px' }}>
			<h4 className='page-title'>BUSCAR FOLIO</h4>
			<StoreDataSelectionFolioForm />
			<br />
			<h4 className='page-title'>DATOS DE LA TIENDA</h4>
			<StoreDataSelectionStoreInformationForm />
		</div>
	)
}

export default StoreDataSection
