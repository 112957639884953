import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { Filter, Sliders, Box, } from "react-feather";
import Select from "react-select";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import PageTtle from "../../../../layouts/PagesTitle";
import { apiUrl } from '../../../../utils/var'
import { Button, Table } from "antd";
import Error404 from "../../../../feature-module/pages/errorpages/error404";
import { formatCurrency } from "../../../../utils/functions/money";
import { convertMilitaryTimeToStandardTimeFormat } from "../../../../utils/functions/date";
import ModalDetails from "../../../../components/modalSales";


const OrdersReports = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [orders, setorders] = useState({ orders: [] })
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [sortOrder, setSortOrder] = useState("");
    const [searchText, setSearchText] = useState("");

    const handleShowModal = (record) => {
        console.log(record)
        setSelectedRecord(record);
        setModalVisible(true);
    };

    const handleCloseModal = () => {
        setModalVisible(false);
        setSelectedRecord(null);
    };

    const getorders = async () => {
        setIsLoading(true)

        const requestorders = await fetch(`${apiUrl}/api/Orders/`, {
            method: 'GET',
            credentials: "include",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            }
        })

        const responseorders = await requestorders.json();
        if (!requestorders.ok) {
            setError(responseorders.message)
        } else {
            setError(null)
            setorders(responseorders)
        }
        setIsLoading(false)
    }

    const filteredData = orders.orders
        .filter((entry) => Object.keys(entry).some((key) => String(entry[key]).toLowerCase().includes(searchText.toLowerCase())))
        .sort((a, b) => {
            if (sortOrder === "sortByCashier") return a.empleado.localeCompare(b.empleado);
            if (sortOrder === "sortByCreateDate") return (new Date(b.date).getTime() || 0) - (new Date(a.date).getTime() || 0)
            if (sortOrder === "sortByCreateDate1") return (new Date(a.date).getTime() || 0) - (new Date(b.date).getTime() || 0)
            if (sortOrder === "sortByPayment") return a.PeyMethod.localeCompare(b.PeyMethod);
            if (sortOrder === "sortByPayment1") return b.PeyMethod.localeCompare(a.PeyMethod);
            return 0; // Orden por defecto
        });
    // (dateA || 0) - (dateB || 0)
    useEffect(() => {
        getorders()
    }, [])

    console.log(error)


    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const toggleFilterVisibility = () => {
        setIsFilterVisible((prevVisibility) => !prevVisibility);
    };

    const options = [
        { value: "sortByCashier", label: "Ordenar por cajero" },
        { value: "sortByCreateDate", label: "Ordenar por creación mas nuevo" },
        { value: "sortByCreateDate1", label: "Ordenar por creación mas antiguo" },
        { value: "sortByPayment", label: "Ordenar por método de pago" },
        { value: "sortByPayment1", label: "Ordenar por Monto mas alto" },
    ];

    const productOptions = [
        { value: "chooseProduct", label: "Choose Product" },
        { value: "boldV3.2", label: "Bold V3.2" },
        { value: "nikeJordan", label: "Nike Jordan" },
    ];

    const handleSortOrderChange = (selectedOption) => {
        setSortOrder(selectedOption.value);
    };

    const columns = [
        {
            title: "Corte",
            dataIndex: 'DocEntryCorte',
            align: 'center',
            sorter: (a, b) => a.DocEntryCorte - b.DocEntryCorte,
        },
        {
            title: "ID Orden",
            dataIndex: 'U_CSM_IdOrden',
            align: 'center',
            sorter: (a, b) => a.U_CSM_IdOrden - b.U_CSM_IdOrden,
        },
        {
            title: 'Estatus',
            dataIndex: 'CANCELED',
            align: 'center',
            sorter: (a, b) => a.CANCELED.localeCompare(b.CANCELED),
            render: (canceled, record) => {
                if (canceled === 'Y') {
                    return <span className="badge badge-linepape">Cancelado</span>;
                }
                else if (record.DocStatus === 'O') {
                    return <span className="badge badge-lineinfo">Abierto</span>;
                } else if (record.DocStatus === 'C') {
                    return <span className="badge badge-linesuccess">Cerrado</span>;
                }
            },
        },
        {
            title: "Fecha de creación",
            dataIndex: "DocDate",
            align: 'center',
            sorter: (a, b) => {
                const dateA = new Date(a.DocDate).getTime();
                const dateB = new Date(b.DocDate).getTime();
                return (dateA || 0) - (dateB || 0);
            },
            render: (text) => (
                <div>
                    <span>{(text ?? '').substring(0, 10)}</span>
                </div>
            ),
        },
        {
            title: "Hora de creacion",
            dataIndex: "DocTime",
            align: 'center',
            sorter: (a, b) => a.DocTime.localeCompare(b.DocTime),
            render: (record) => convertMilitaryTimeToStandardTimeFormat(record)
        },
        {
            title: "Cajero",
            dataIndex: "empleado",
            align: 'center',
            sorter: (a, b) => a.empleado.localeCompare(b.empleado),
        },
        {
            title: "Metodo de pago",
            dataIndex: "PeyMethod",
            align: 'center',
            render: (text) => (
                <div>
                    {text === "01" && (
                        <span className="badge badge-linepape">Efectivo</span>
                    )}
                    {text === "99" && (
                        <span className="badge badge-lineinfo">Tarjeta</span>
                    )}
                </div>
            ),
            sorter: (a, b) => a.PeyMethod.localeCompare(b.PeyMethod),
        },
        {
            title: "Total",
            dataIndex: "DocTotal",
            align: 'center',
            sorter: (a, b) => a.DocTotal - b.DocTotal,
            render: (record) => formatCurrency(record)
        },
        {
            title: "Acciones",
            dataIndex: "DocTotal",
            align: 'center',
            render: (_, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <div className="input-block add-lists"></div>            
                        <Button
                            onClick={() => {
                                handleShowModal(record)
                            }}
                            type='primary'
                            ghost>
                            <i className='feather-eye' />
                        </Button>
                        {/* <Link
                            className="confirm-text p-2"
                            to="#"
                        // onClick={showConfirmationAlert}
                        >
                            <Trash2 className="feather-trash-2" />
                        </Link> */}
                    </div>
                </div>
            ),
        },

    ];
    return (
        <DashboardLayout>
            <PageTtle
                maintitle="Ordenes"
                subtitle="Consulta todas las ventas realizadas en La Pape"
            />

            {/* /product list */}
            <div className="card table-list-card">
                <div className="card-body">
                    <div className="table-top">
                        <div className="search-set">
                            <div className="search-input">
                                <input
                                    type="text"
                                    placeholder="Cajero"
                                    className="form-control form-control-sm formsearch"
                                    aria-controls="DataTables_Table_0"
                                    value={searchText}
                                    onChange={handleSearch}
                                />
                                <Link to className="btn btn-searchset">
                                    <i data-feather="search" className="feather-search" />
                                </Link>
                            </div>
                        </div>
                        <div className="search-path">
                            <Link
                                className={`btn btn-filter ${isFilterVisible ? "setclose" : ""
                                    }`}
                                id="filter_search"
                            >
                                <Filter
                                    className="filter-icon"
                                    onClick={toggleFilterVisibility}
                                />
                                <span onClick={toggleFilterVisibility}>
                                    <ImageWithBasePath
                                        src="assets/img/icons/closes.svg"
                                        alt="img"
                                    />
                                </span>
                            </Link>
                        </div>
                        <div className="form-sort stylewidth">
                            <Sliders className="info-img" />

                            <Select
                                classNamePrefix="react-select"
                                className="img-select"
                                options={options}
                                placeholder="Ordenar"
                                onChange={handleSortOrderChange}
                            />
                        </div>

                    </div>
                    {/* /Filter */}
                    <div
                        className={`card${isFilterVisible ? " visible" : ""}`}
                        id="filter_inputs"
                        style={{ display: isFilterVisible ? "block" : "none" }}
                    >
                        <div className="card-body pb-0">
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="input-blocks">
                                        <Box className="info-img" />
                                        <Select
                                            className="img-select"
                                            classNamePrefix="react-select"
                                            options={productOptions}
                                        />
                                    </div>
                                </div>
                                {/* <div className="col-lg-3">
                                    <div className="input-blocks">
                                        <Zap className="info-img" />
                                        <Select
                                            className="img-select"
                                            classNamePrefix="react-select"
                                            options={categoryOptions}
                                        />
                                    </div>
                                </div> */}
                                <div className="col-lg-6 col-sm-6 col-12">
                                    <div className="input-blocks">
                                        <Link className="btn btn-filters ms-auto">
                                            {" "}
                                            <i
                                                data-feather="search"
                                                className="feather-search"
                                            />{" "}
                                            Search{" "}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /Filter */}
                    <div className="table-responsive">
                        {(!error) ? <Table
                            columns={columns}
                            loading={isLoading}
                            dataSource={filteredData}
                            rowKey={(record) => record.U_CSM_IdOrden}
                        /> :

                            <Error404 />}

                    </div>
                </div>
            </div>
            <ModalDetails visible={modalVisible} onClose={handleCloseModal} record={selectedRecord} />

        </DashboardLayout>

    );
};

export default OrdersReports;
