import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import {
    Clock,
    ArrowRight,
    Box
} from "feather-icons-react/build/IconComponents";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { apiUrl } from "../../../utils/var";
import DashboardContext from "../provider/Dashboard";
import { formatCurrency } from "../../../utils/functions/money";
export const SalesTables = () => {

    const { isLoading, data } = useContext(DashboardContext);

    const columns = [
        {
            title: 'Articulo',
            dataIndex: 'ItemCode',
            render: (_, record) => (
                <div className="row">
                    <div className="col-md-2" style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <span className='productimgname'>
                            <Link to='#' className='product-img stock-img'>
                                <img
                                    alt='img'
                                    src={`${apiUrl}/proxy/empleados/web/index.php?r=AppMovil/proxy/compress&filename=${record.PicturName}`}
                                />
                            </Link>
                        </span>
                    </div>
                    <div className="col-md-9">
                        <div className="row">
                            
                            <span className="dull-text d-flex align-items-center">
                                <Box className="feather-14" />
                                {record.CodeBars ?? ''} | {record.ItemCode}
                            </span>
                        </div>
                        <div className="row">
                            <p className="paragraph-cell" style={{
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                wordBreak: 'break-word',
                                maxWidth: '400px',
                                lineHeight: '1.5',
                                padding: '8px',
                                fontWeight: 'bold'
                            }}>{record.ItemName}</p>
                        </div>
                    </div>
                </div>
            ),
            width: 450,
        },
        {
            title: 'Ventas brutas',
            dataIndex: 'VentasBrutas',
            key: 'age',
            render: (address) => (
                <span>{formatCurrency(address)}</span>
            )
        },
        {
            title: '%',
            dataIndex: '%Participacion',
            key: 'address',
        },
        {
            title: 'U',
            key: 'tags',
            dataIndex: 'UnidadesVendidas',

        },

    ];

    const [state] = React.useState({

        series: [{
            name: 'series1',
            data: [31, 40, 28, 51, 42, 109, 100]
        }, {
            name: 'series2',
            data: [11, 32, 45, 32, 34, 52, 41]
        }],
        options: {
            chart: {
                height: 350,
                type: 'area'
            },
            dataLabels: {
                enabled: true
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',
                categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
            },
            tooltip: {

                x: {
                    format: 'dd/MM/yy HH:mm'
                },
            },
        },


    });

    return (
        (isLoading) ? <div className="row "></div> :
            <div className="row sales-board">
                <div className="row ">
                    <div className="col-md-5">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Ventas</h5>
                            </div>
                            <div className="card-body">
                                <div id="s-line" />
                                <ReactApexChart
                                    options={state.options}
                                    series={state.series}
                                    type="area"
                                    height={350}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Productos mas vendidos</h4>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive dataview">
                                    <table className="table dashboard-expired-products">
                                        <Table
                                            columns={columns}
                                            dataSource={data.infoTableTopProducts}
                                            pagination={{
                                                pageSize: 4, // Limita a 5 elementos por página
                                            }}

                                        />
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-4 d-flex">
                        <div className="card flex-fill default-cover w-100 mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h4 className="card-title mb-0">Best Seller</h4>
                                <div className="dropdown">
                                    <Link to="#" className="view-all d-flex align-items-center">
                                        View All
                                        <span className="ps-2 d-flex align-items-center">
                                            <ArrowRight className="feather-16" />
                                        </span>
                                    </Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-borderless best-seller">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="product-info">
                                                        <Link

                                                            className="product-img"
                                                        >
                                                            <ImageWithBasePath
                                                                src="assets/img/products/stock-img-01.png"
                                                                alt="product"
                                                            />
                                                        </Link>
                                                        <div className="info">
                                                            <Link >
                                                                Lenovo 3rd Generation
                                                            </Link>
                                                            <p className="dull-text">$4420</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="head-text">Sales</p>
                                                    6547
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="product-info">
                                                        <Link

                                                            className="product-img"
                                                        >
                                                            <ImageWithBasePath
                                                                src="assets/img/products/stock-img-06.png"
                                                                alt="product"
                                                            />
                                                        </Link>
                                                        <div className="info">
                                                            <Link >Bold V3.2</Link>
                                                            <p className="dull-text">$1474</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="head-text">Sales</p>
                                                    3474
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="product-info">
                                                        <Link

                                                            className="product-img"
                                                        >
                                                            <ImageWithBasePath
                                                                src="assets/img/products/stock-img-02.png"
                                                                alt="product"
                                                            />
                                                        </Link>
                                                        <div className="info">
                                                            <Link >Nike Jordan</Link>
                                                            <p className="dull-text">$8784</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="head-text">Sales</p>
                                                    1478
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="product-info">
                                                        <Link

                                                            className="product-img"
                                                        >
                                                            <ImageWithBasePath
                                                                src="assets/img/products/stock-img-03.png"
                                                                alt="product"
                                                            />
                                                        </Link>
                                                        <div className="info">
                                                            <Link >
                                                                Apple Series 5 Watch
                                                            </Link>
                                                            <p className="dull-text">$3240</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="head-text">Sales</p>
                                                    987
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="product-info">
                                                        <Link

                                                            className="product-img"
                                                        >
                                                            <ImageWithBasePath
                                                                src="assets/img/products/stock-img-04.png"
                                                                alt="product"
                                                            />
                                                        </Link>
                                                        <div className="info">
                                                            <Link >
                                                                Amazon Echo Dot
                                                            </Link>
                                                            <p className="dull-text">$597</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="head-text">Sales</p>
                                                    784
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xl-8 d-flex">
                        <div className="card flex-fill default-cover w-100 mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h4 className="card-title mb-0">Recent Transactions</h4>
                                <div className="dropdown">
                                    <Link to="#" className="view-all d-flex align-items-center">
                                        View All
                                        <span className="ps-2 d-flex align-items-center">
                                            <ArrowRight className="feather-16" />
                                        </span>
                                    </Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-borderless recent-transactions">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Order Details</th>
                                                <th>Payment</th>
                                                <th>Status</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>
                                                    <div className="product-info">
                                                        <Link

                                                            className="product-img"
                                                        >
                                                            <ImageWithBasePath
                                                                src="assets/img/products/stock-img-05.png"
                                                                alt="product"
                                                            />
                                                        </Link>
                                                        <div className="info">
                                                            <Link >Lobar Handy</Link>
                                                            <span className="dull-text d-flex align-items-center">
                                                                <Clock className="feather-14" />
                                                                15 Mins
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className="d-block head-text">Paypal</span>
                                                    <span className="text-blue">#416645453773</span>
                                                </td>
                                                <td>
                                                    <span className="badge background-less border-success">
                                                        Success
                                                    </span>
                                                </td>
                                                <td>$1,099.00</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>
                                                    <div className="product-info">
                                                        <Link

                                                            className="product-img"
                                                        >
                                                            <ImageWithBasePath
                                                                src="assets/img/products/expire-product-01.png"
                                                                alt="product"
                                                            />
                                                        </Link>
                                                        <div className="info">
                                                            <Link >
                                                                Red Premium Handy
                                                            </Link>
                                                            <span className="dull-text d-flex align-items-center">
                                                                <Clock className="feather-14" />
                                                                10 Mins
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className="d-block head-text">Apple Pay</span>
                                                    <span className="text-blue">#147784454554</span>
                                                </td>
                                                <td>
                                                    <span className="badge background-less border-danger">
                                                        Canceled
                                                    </span>
                                                </td>
                                                <td>$600.55</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>
                                                    <div className="product-info">
                                                        <Link

                                                            className="product-img"
                                                        >
                                                            <ImageWithBasePath
                                                                src="assets/img/products/expire-product-02.png"
                                                                alt="product"
                                                            />
                                                        </Link>
                                                        <div className="info">
                                                            <Link >
                                                                Iphone 14 Pro
                                                            </Link>
                                                            <span className="dull-text d-flex align-items-center">
                                                                <Clock className="feather-14" />
                                                                10 Mins
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className="d-block head-text">Stripe</span>
                                                    <span className="text-blue">#147784454554</span>
                                                </td>
                                                <td>
                                                    <span className="badge background-less border-primary">
                                                        Pending
                                                    </span>
                                                </td>
                                                <td>$1,099.00</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>
                                                    <div className="product-info">
                                                        <Link

                                                            className="product-img"
                                                        >
                                                            <ImageWithBasePath
                                                                src="assets/img/products/expire-product-03.png"
                                                                alt="product"
                                                            />
                                                        </Link>
                                                        <div className="info">
                                                            <Link >
                                                                Black Slim 200
                                                            </Link>
                                                            <span className="dull-text d-flex align-items-center">
                                                                <Clock className="feather-14" />
                                                                10 Mins
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className="d-block head-text">PayU</span>
                                                    <span className="text-blue">#147784454554</span>
                                                </td>
                                                <td>
                                                    <span className="badge background-less border-success">
                                                        Success
                                                    </span>
                                                </td>
                                                <td>$1,569.00</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>
                                                    <div className="product-info">
                                                        <Link

                                                            className="product-img"
                                                        >
                                                            <ImageWithBasePath
                                                                src="assets/img/products/expire-product-04.png"
                                                                alt="product"
                                                            />
                                                        </Link>
                                                        <div className="info">
                                                            <Link >
                                                                Woodcraft Sandal
                                                            </Link>
                                                            <span className="dull-text d-flex align-items-center">
                                                                <i
                                                                    data-feather="clock"
                                                                    className="feather-14"
                                                                />
                                                                15 Mins
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className="d-block head-text">Paytm</span>
                                                    <span className="text-blue">#147784454554</span>
                                                </td>
                                                <td>
                                                    <span className="badge background-less border-success">
                                                        Success
                                                    </span>
                                                </td>
                                                <td>$1,478.00</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
    )
}