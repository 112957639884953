import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiUrl } from '../utils/var'
import { clean as cleanCart } from '../features/pos/redux/cart'
import { eraseCoupon } from './coupon'
import { verifyNeedWithdraw, initWithdraw } from './withdraw'
import {
	handlePrintTicket,
	handlePrintVoucher,
	/* handlePrintVoucher, */
} from '../utils/functions/printTicket'
import Big from 'big.js'
import Swal from 'sweetalert2'

export const getNextOrderId = createAsyncThunk(
	'order/getNextIdOrder',
	async () => {
		try {
			const url = `${apiUrl}/api/orders/get-identifier-last-order`
			const response = await fetch(url, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				credentials: 'include',
			})

			if (!response.ok) {
				let errorMessage
				try {
					const errorBody = await response.json()
					errorMessage = errorBody.message || 'Error desconocido'
				} catch {
					errorMessage =
						'Ocurrió un error al traer los productos, recargue el punto de venta o contacte a un encargado. lol'
				}

				const errorInfo = {
					code: response.status,
					message: errorMessage,
					url: url,
				}

				throw new Error(JSON.stringify(errorInfo))
			}

			const responseBody = await response.json()
			return responseBody.identifier
		} catch (error) {
			let errorMessage
			console.error(error)

			try {
				const errorInfo = JSON.parse(error.message)
				errorMessage = errorInfo.message
			} catch {
				errorMessage =
					'Ocurrió un error al traer los productos, recargue el punto de venta o contacte a un encargado.'
			}
			throw new Error(errorMessage)
		}
	}
)

export const verifyOrder = createAsyncThunk(
	'order/verifyOrder',
	async (sheet, { getState, dispatch }) => {
		const { order } = getState().order

		try {
			const url = `${apiUrl}/api/orders/verifyOrder`
			const response = await fetch(url, {
				method: 'POST',
				body: JSON.stringify({ ...order, sheet }),
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				credentials: 'include',
			})

			if (!response.ok) {
				let errorMessage
				try {
					const errorBody = await response.json()
					errorMessage = errorBody.message || 'Error desconocido'
				} catch {
					errorMessage =
						'Ocurrió un error al traer los productos, recargue el punto de venta o contacte a un encargado. lol'
				}

				const errorInfo = {
					code: response.status,
					message: errorMessage,
					url: url,
				}

				throw new Error(JSON.stringify(errorInfo))
			}

			const responseBody = await response.json()

			dispatch(eraseCoupon())
			dispatch(cleanCart())

			return responseBody
		} catch (error) {
			let errorMessage
			console.error(error)

			try {
				const errorInfo = JSON.parse(error.message)
				errorMessage = errorInfo.message
			} catch {
				errorMessage =
					'Ocurrió un error al traer los productos, recargue el punto de venta o contacte a un encargado. xddxdx'
			}
			throw new Error(errorMessage)
		}
	}
)

export const transactOrder = createAsyncThunk(
	'order/transactOrder',
	async (sheet, { getState, dispatch }) => {
		const { order } = getState().order

		try {
			const url = `${apiUrl}/api/orders/createOrder`
			console.log(JSON.stringify({ ...order, sheet }))

			const response = await fetch(url, {
				method: 'POST',
				body: JSON.stringify({ ...order, sheet }),
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				credentials: 'include',
			})

			if (!response.ok) {
				let errorMessage
				try {
					const errorBody = await response.json()
					console.error(errorBody)
					errorMessage = errorBody.message || 'Error desconocido'
				} catch {
					errorMessage =
						'Ocurrió un error al traer los productos, recargue el punto de venta o contacte a un encargado.'
				}

				const errorInfo = {
					code: response.status,
					message: errorMessage,
					url: url,
				}

				throw new Error(JSON.stringify(errorInfo))
			}

			const responseBody = await response.json()
			const withdrawData = responseBody.withDrawals

			const voucherBusiness = order.Voucher?.Business
			const voucherClient = order.Voucher?.Client
			let isPrinterAvailableTicket = false
			let isPrinterAvailableVoucherBusiness = false
			let isPrinterAvailableVoucherClient = false

			do {
				try {
					if (!isPrinterAvailableTicket) {
						const isHandlePrintTicket = await handlePrintTicket(
							responseBody.draft.DocEntry
						)
						if (isHandlePrintTicket) isPrinterAvailableTicket = true
					}

					if (
						voucherBusiness &&
						voucherBusiness !== '' &&
						voucherClient !== undefined
					) {
						if (!isPrinterAvailableVoucherBusiness) {
							const isHandlePrintVoucherBusiness = await handlePrintVoucher(
								voucherBusiness,
								'Comercio'
							)
							if (isHandlePrintVoucherBusiness)
								isPrinterAvailableVoucherBusiness = true
						}
					} else {
						isPrinterAvailableVoucherBusiness = true
					}

					if (
						voucherClient &&
						voucherClient !== '' &&
						voucherClient !== undefined
					) {
						if (!isPrinterAvailableVoucherClient) {
							const isHandlePrintVoucherClient = await handlePrintVoucher(
								voucherClient,
								'Cliente'
							)
							if (isHandlePrintVoucherClient)
								isPrinterAvailableVoucherClient = true
						}
					} else {
						isPrinterAvailableVoucherClient = true
					}

					if (
						!isPrinterAvailableTicket ||
						!isPrinterAvailableVoucherBusiness ||
						!isPrinterAvailableVoucherClient
					) {
						const title = 'Error al imprimir'
						const html =
							'No se pudo imprimir: <br/>' +
							(!isPrinterAvailableTicket ? '*Ticket<br/>' : '') +
							(!isPrinterAvailableVoucherBusiness
								? '*Voucher Comercio<br/>'
								: '') +
							(!isPrinterAvailableVoucherClient
								? '*Voucher Cliente<br/>'
								: '') +
							'¿Desea intentar de nuevo?'
						const result = await Swal.fire({
							title: title,
							html: html,
							icon: 'error',
							showCancelButton: true,
							confirmButtonColor: '#3085d6',
							cancelButtonColor: '#d33',
							confirmButtonText: 'Sí',
							cancelButtonText: 'No',
						})

						if (result.isDismissed) {
							isPrinterAvailableTicket = true
							isPrinterAvailableVoucherBusiness = true
							isPrinterAvailableVoucherClient = true
						}
					}
				} catch (error) {
					const errorBody = JSON.parse(error.message)
					const typePrint = errorBody.type
					const title = 'Error al imprimir'
					const html = `No se pudo imprimir: ${typePrint} <br>¿Desea intentar de nuevo?`
					const result = await Swal.fire({
						title: title,
						html: html,
						icon: 'error',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Sí',
						cancelButtonText: 'No',
					})

					if (result.isDismissed) {
						isPrinterAvailableTicket = true
						isPrinterAvailableVoucherBusiness = true
						isPrinterAvailableVoucherClient = true
					}
				}
			} while (
				!isPrinterAvailableTicket ||
				!isPrinterAvailableVoucherBusiness ||
				!isPrinterAvailableVoucherClient
			)

			dispatch(eraseCoupon())
			dispatch(cleanCart())
			dispatch(verifyNeedWithdraw(responseBody.withDrawals.needWithDrawal))
			dispatch(initWithdraw(withdrawData))

			return responseBody
		} catch (error) {
			let errorMessage
			console.error(error)

			try {
				const errorInfo = JSON.parse(error.message)
				errorMessage = errorInfo.message
			} catch {
				errorMessage =
					'Ocurrió un error al traer los productos, recargue el punto de venta o contacte a un encargado.'
			}
			throw new Error(errorMessage)
		}
	}
)

export const orderSlice = createSlice({
	name: 'order',
	initialState: {
		order: null,
		method: 'Efectivo',
		status: 'noinit',
		isShowModalOrder: false,
		isShowModalOrderInformation: false,
		isShowModalOrderFolioCardPay: false,
		isShowModalOrderClientView: false,
		isShowModalTicket: false,
		isPaying: false,
		isLoadingNextOrderId: false,
		isLoadingOrder: false,
		isLoadingOrderClientView: false,
		isLoadingTerminalPay: false,
		cash: '',
		folio: '',
		exchange: '',
		errorNextOrderId: null,
		error: null,
		cardPaySheet: null,
		orderId: null,
		payData: null,
		voucherBusiness: null,
		voucherClient: null,
	},
	reducers: {
		initPaying: (state) => {
			state.isLoadingOrderClientView = true
			state.isShowModalOrderClientView = true
			state.isPaying = true
		},
		stopPaying: (state) => {
			state.isPaying = false
		},
		toggleShowModalFolioCardPay: (state, action) => {
			state.isShowModalOrderFolioCardPay = action.payload
			state.isShowModalOrder = false
		},
		changeStatus: (state, action) => {
			const status = action.payload
			state.status = status
		},
		changePaymentMethod: (state, action) => {
			const paymentMethod = action.payload
			state.method = paymentMethod
		},
		toggleShowModalOrder: (state, action) => {
			const isShow = action.payload
			state.isShowModalOrder = isShow
		},
		toggleShowModalTicket: (state, action) => {
			const isShow = action.payload
			state.isShowModalTicket = isShow
		},
		generateOrderBodyEfective: (state, action) => {
			const items = action.payload.items.map((item) => {
				return {
					ItemCode: item.ItemCode,
					Quantity: item.quantity,
					price: item.AvgPriceLiquidation
						? item.AvgPriceLiquidation
						: item.AvgPrice,
				}
			})

			let coupons = []
			if (action.payload.coupons && action.payload.coupons.length > 0) {
				coupons = action.payload.coupons.map((coupon) => {
					return {
						Coupon: coupon,
					}
				})
			}

			const payment = [
				{
					id: 1,
					Type: state.method,
					Total: action.payload.total,
					Timestamp: action.payload.timestamp,
					Cash: new Big(state.cash).toNumber().toFixed(2),
					Exchange: new Big(state.exchange).toFixed(2),
				},
			]

			state.order = {
				Items: items,
				Coupons: coupons,
				Payment: payment,
			}
		},
		generateOrderBodyCard: (state, action) => {
			const items = action.payload.items.map((item) => {
				return {
					ItemCode: item.ItemCode,
					Quantity: item.quantity,
					price: item.AvgPriceLiquidation
						? item.AvgPriceLiquidation
						: item.AvgPrice,
				}
			})

			let coupons = []
			if (action.payload.coupons && action.payload.coupons.length > 0) {
				coupons = action.payload.coupons.map((coupon) => {
					return {
						Coupon: coupon,
					}
				})
			}

			const payment = [
				{
					id: 1,
					Type: state.method,
					Total: action.payload.total,
					Folio: action.payload.folio,
					Timestamp: action.payload.timestamp,
				},
			]
			const voucherBusiness = state.voucherBusiness
			const voucherClient = state.voucherClient

			state.order = {
				Voucher: {
					Business: voucherBusiness,
					Client: voucherClient,
				},
				Items: items,
				Coupons: coupons,
				Payment: payment,
			}
		},
		updateVouchers: (state, action) => {
			state.voucherBusiness = action.payload.voucherBusiness
			state.voucherClient = action.payload.voucherClient
		},
		generatePinpadPayBody: (state, actions) => {
			state.payData = {
				Estatus: actions.payload.status,
				Fecha: actions.payload.datetime,
				Folio: actions.payload.folio,
				Referencia: actions.payload.reference,
				Autorización: `#${actions.payload.authorization}`,
			}
		},
		cleanPinpaPayBody: (state) => {
			state.payData = null
		},
		cleanOrderBody: (state) => {
			state.order = null
		},
		cleanError: (state) => {
			state.error = null
		},
		hideModalOrderInformation: (state) => {
			state.isShowModalOrderInformation = false
		},
		hideModalOrderClientView: (state) => {
			state.isShowModalOrderClientView = false
		},
		showModalOrderInformation: (state) => {
			state.isShowModalOrderInformation = true
		},
		changeCardPaySheet: (state, action) => {
			state.cardPaySheet = action.payload
		},
		changeCash: (state, action) => {
			state.cash = action.payload
		},
		changeFolio: (state, action) => {
			state.folio = action.payload
		},
		changeExchange: (state, action) => {
			state.exchange = action.payload
		},
		resetChangeableStates: (state) => {
			state.order = null
			state.isShowModalOrder = false
			state.isShowModalOrderInformation = false
			state.isShowModalOrderFolioCardPay = false
			state.isShowModalOrderClientView = false
			state.isShowModalTicket = false
			state.isPaying = false
			state.isLoadingOrder = false
			state.isLoadingOrderClientView = false
			state.error = null
			state.status = 'noinit'
		},
		changeIsLoadingTerminalPay: (state, action) => {
			state.isLoadingTerminalPay = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(verifyOrder.pending, (state) => {
			state.isLoadingOrderClientView = true
			state.isShowModalOrderClientView = true
			state.isLoadingOrder = true
			state.status = 'verifying'
		})
		builder.addCase(verifyOrder.fulfilled, (state) => {
			state.error = null
			state.isLoadingOrder = false
			state.isPaying = false
			state.status = 'complete'
			state.order = null
			state.isShowModalOrder = false
			state.isShowModalOrderFolioCardPay = true
			state.isLoadingOrderClientView = false
		})
		builder.addCase(verifyOrder.rejected, (state, action) => {
			state.error = state.error =
				action.error.message ||
				'Ocurrió un error, recargue la página por favor.'
			state.isLoadingOrder = false
			state.isPaying = false
			state.isShowModalOrder = false
			state.order = null
			state.status = 'error'
			state.isShowModalOrderFolioCardPay = false
			state.isLoadingOrderClientView = false
		})

		builder.addCase(transactOrder.pending, (state) => {
			state.isLoadingOrderClientView = true
			state.isLoadingOrder = true
			state.status = 'processing'
		})
		builder.addCase(transactOrder.fulfilled, (state) => {
			state.error = null
			state.isLoadingOrder = false
			state.isPaying = false
			state.status = 'complete'
			state.order = null
			state.isShowModalOrder = false
			state.isShowModalOrderInformation = true
			state.isShowModalOrderFolioCardPay = false
			state.isLoadingOrderClientView = false
		})
		builder.addCase(transactOrder.rejected, (state, action) => {
			state.error = state.error =
				action.error.message ||
				'Ocurrió un error, recargue la página por favor.'
			state.isLoadingOrder = false
			state.isPaying = false
			state.isShowModalOrder = false
			state.order = null
			state.status = 'error'
			state.isShowModalOrderInformation = true
			state.isLoadingOrderClientView = false
		})
		builder.addCase(getNextOrderId.pending, (state) => {
			state.isLoadingNextOrderId = true
			state.errorNextOrderId = null
		})
		builder.addCase(getNextOrderId.fulfilled, (state, action) => {
			state.isLoadingNextOrderId = false
			state.orderId = action.payload
			state.errorNextOrderId = null
		})
		builder.addCase(getNextOrderId.rejected, (state, action) => {
			state.errorNextOrderId = state.errorNextOrderId =
				action.error.message ||
				'Ocurrió un error, recargue la página por favor.'
			state.isLoadingNextOrderId = false
			state.orderId = null
		})
	},
})

export const {
	initPaying,
	stopPaying,
	changeStatus,
	changePaymentMethod,
	toggleShowModalOrder,
	toggleShowModalTicket,
	toggleShowModalFolioCardPay,
	generateOrderBodyEfective,
	generateOrderBodyCard,
	generatePinpadPayBody,
	cleanOrderBody,
	cleanPinpaPayBody,
	cleanError,
	hideModalOrderInformation,
	changeCardPaySheet,
	hideModalOrderClientView,
	resetChangeableStates,
	changeCash,
	changeFolio,
	changeExchange,
	changeIsLoadingTerminalPay,
	showModalOrderInformation,
	updateVouchers,
} = orderSlice.actions

export default orderSlice.reducer
