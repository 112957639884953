import React from 'react'
import { Button } from 'antd'
import { convertMilitaryTimeToStandardTimeFormat } from './functions/date'
import ImageWithBasePath from '../core/img/imagewithbasebath'
import { formatCurrency } from './functions/money'

export const tableOrdersCashCountColumns = (
	handleShowModal,
	showCancelFunction
) => {
	return [
		{
			title: 'ID Orden',
			dataIndex: 'idOrder',
			key: 'idOrder',
			align: 'center',
			sorter: (a, b) => a.idOrder - b.idOrder,
		},
		{
			title: 'Estatus',
			dataIndex: 'CANCELED',
			align: 'center',
			sorter: (a, b) => a.CANCELED.localeCompare(b.CANCELED),
			render: (canceled, record) => {
				if (canceled === 'Y') {
					return <span className='badge badge-linepape'>Cancelado</span>
				} else if (record.DocStatus === 'O') {
					return <span className='badge badge-lineinfo'>Abierto</span>
				} else if (record.DocStatus === 'C') {
					return <span className='badge badge-linesuccess'>Cerrado</span>
				}
			},
		},
		{
			title: 'Fecha de creación',
			dataIndex: 'DocDate',
			align: 'center',
			sorter: (a, b) => {
				const dateA = new Date(a.DocDate).getTime()
				const dateB = new Date(b.DocDate).getTime()
				return (dateA || 0) - (dateB || 0)
			},
			render: (text) => (
				<div>
					<span>{(text ?? '').substring(0, 10)}</span>
				</div>
			),
		},
		{
			title: 'Hora de creacion',
			dataIndex: 'DocTime',
			align: 'center',
			sorter: (a, b) => a.DocTime.localeCompare(b.DocTime),
		},
		{
			title: 'Metodo de pago',
			dataIndex: 'PeyMethod',
			align: 'center',
			sorter: (a, b) => a.PeyMethod.localeCompare(b.PeyMethod),
			render: (text) => (
				<div>
					{text === '01' && (
						<span className='badge badge-linesuccess'>Efectivo</span>
					)}
					{text === '99' && (
						<span className='badge badge-lineinfo'>Tarjeta</span>
					)}
				</div>
			),
		},
		{
			title: 'Total',
			dataIndex: 'DocTotal',
			sorter: (a, b) => a.DocTotal - b.DocTotal,
			render: (_, render) => {
				return <span>{formatCurrency(render.DocTotal)}</span>
			},
		},
		{
			title: 'Acciones',
			dataIndex: 'action',
			key: 'action',
			render: (_, record) => {
				return (
					<div className='action-table-data'>
						<div
							className='edit-delete-action gap-2'
							style={{
								justifyContent: 'start',
							}}>
							<Button
								onClick={() => {
									handleShowModal(record)
								}}
								type='primary'
								ghost>
								<i className='feather-eye' />
							</Button>

							{record.CANCELED !== 'Y' ? (
								<Button
									onClick={() => showCancelFunction(record.idOrder)}
									danger
									ghost>
									<i className='feather-slash' />
								</Button>
							) : (
								<></>
							)}
						</div>
					</div>
				)
			},
		},
	]
}

export const tableCashCountColumns = () => {
	return [
		{
			title: 'Folio',
			dataIndex: 'folio',
			align: 'center',
			key: 'folio',
		},
		{
			title: 'Fecha y Hora de Apertura',
			dataIndex: 'dateTimeCreated',
			align: 'center',
			key: 'dateTimeCreated',
		},
		{
			title: 'Usuario',
			dataIndex: 'user',
			align: 'center',
			key: 'user',
		},
		{
			title: 'Fecha y Hora de Cierre',
			dataIndex: 'dateTimeClose',
			align: 'center',
			key: 'dateTimeClose',
		},
		{
			title: 'Total de Ventas',
			dataIndex: 'totalSales',
			align: 'center',
			key: 'totalSales',
		},
		{
			title: 'Total Entregado',
			dataIndex: 'totalSurrended',
			align: 'center',
			key: 'totalSurrended',
		},
		{
			title: 'Diferencia',
			dataIndex: 'difference',
			align: 'center',
			key: 'difference',
		},
		{
			title: 'Estatus',
			dataIndex: 'status',
			align: 'center',
			key: 'status',
		},
	]
}

export const tableCouponColumns = (showCoupon, modifyCoupon, disableCoupon) => {
	return [
		{
			title: 'Código',
			dataIndex: 'U_code',
			key: 'U_code',
			align: 'center',
			render: (code) => <span className='badge badge-lineinfo'>{code}</span>,
			sorter: (a, b) => a.U_code.length - b.U_code.length,
		},
		{
			title: 'Descripcion',
			dataIndex: 'U_description',
			key: 'U_description',
			align: 'center',
			render: (U_description) => <span>{U_description}</span>,
			sorter: (a, b) => a.U_status.length - b.U_status.length,
		},
		{
			title: 'Tipo',
			dataIndex: 'U_discount_type',
			key: 'U_discount_type',
			align: 'center',
			render: (discount_type) => (
				<span>{discount_type === 'M' ? 'Dinero' : 'Porcentaje'}</span>
			),
			sorter: (a, b) => {
				const getSortValue = (type) => (type === 'M' ? 1 : 2)
				return getSortValue(a.U_discount_type) - getSortValue(b.U_discount_type)
			},
		},
		{
			title: 'Descuento',
			dataIndex: 'U_discount_value',
			key: 'U_discount_value',
			align: 'center',
			sorter: (a, b) => {
				const getNumericValue = (value) => {
					if (value.includes('$')) {
						return parseFloat(value.replace(/[$,]/g, ''))
					} else if (value.includes('%')) {
						return parseFloat(value.replace(/[%]/g, '')) / 100
					}
					return parseFloat(value)
				}

				const numA = getNumericValue(a.U_discount_value)
				const numB = getNumericValue(b.U_discount_value)

				return numA - numB
			},
		},
		{
			title: 'Inicio',
			dataIndex: 'U_start_date',
			key: 'U_start_date',
			align: 'center',
			render: (_, record) => {
				const formatStart = `${record.U_start_date.substring(0, 10)} ${
					record.U_start_time
						? convertMilitaryTimeToStandardTimeFormat(record.U_start_time)
						: ''
				}`

				return <span>{formatStart}</span>
			},
			sorter: (a, b) => {
				const formatStartA = `${a.U_start_date.substring(0, 10)} ${
					a.U_start_time || ''
				}`
				const formatStartB = `${b.U_start_date.substring(0, 10)} ${
					b.U_start_time || ''
				}`

				return formatStartA.localeCompare(formatStartB)
			},
		},
		{
			title: 'Fin',
			dataIndex: 'U_end_date',
			key: 'U_end_date',
			align: 'center',
			render: (_, record) => {
				const formatStart = `${record.U_end_date.substring(0, 10)} ${
					record.U_end_time
						? convertMilitaryTimeToStandardTimeFormat(record.U_end_time)
						: ''
				}`

				return <span>{formatStart}</span>
			},
			sorter: (a, b) => {
				const formatStartA = `${a.U_end_date.substring(0, 10)} ${
					a.U_end_time || ''
				}`
				const formatStartB = `${b.U_end_date.substring(0, 10)} ${
					b.U_end_time || ''
				}`

				return formatStartA.localeCompare(formatStartB)
			},
		},
		{
			title: 'Usos',
			dataIndex: 'Uso',
			key: 'Uso',
			align: 'center',
			render: (_, record) => {
				return (
					<span>
						{record.Uso} / {record.U_max_usage}{' '}
					</span>
				)
			},
			sorter: (a, b) => a.Uso - b.Uso,
		},
		{
			title: 'Estatus',
			dataIndex: 'U_status',
			key: 'U_status',
			align: 'center',
			sorter: (a, b) => a.U_status.length - b.U_status.length,
			render: (status) => (
				<span
					className={`badge ${
						status === 'Activo' ? 'badge-linesuccess' : 'badge-linedanger'
					}`}>
					{status}
				</span>
			),
		},
		{
			title: 'Acciones',
			dataIndex: 'actions',
			key: 'actions',
			align: 'center',
			render: (_, record) => (
				<div className='action-table-data'>
					<div
						className='edit-delete-action gap-2'
						style={{
							justifyContent: 'start',
						}}>
						<Button
							onClick={() => {
								showCoupon(record.idCoupon)
							}}
							type='primary'
							ghost>
							<i className='feather-eye' />
						</Button>
						<Button onClick={() => modifyCoupon(record.idCoupon)} type='text'>
							<i className='feather-edit' />
						</Button>
						<Button onClick={() => disableCoupon(record.idCoupon)} danger ghost>
							<i className='feather-slash' />
						</Button>
					</div>
				</div>
			),
		},
	]
}

export const tableOrderResumeItems = [
	{
		title: 'Número de parte',
		dataIndex: 'itemCode',
		key: 'itemCode',
		align: 'center',
	},
	{
		title: 'Imagen',
		dataIndex: 'image',
		key: 'image',
		align: 'center',
		onCell: () => ({
			style: {
				padding: 0,
			},
		}),
		render: (_, record) => (
			// <div className='overflow-hidden position-relative'>
			// 	{record.prices.priceLiquidation && (
			// 		<div className='ribbone ribbone-top-left text-danger'>
			// 			<span className='bg-danger'>Liquidación</span>
			// 		</div>
			// 	)}
			<div
				className='d-flex justify-content-center align-items-center'
				style={{
					height: '150px',
					width: '150px',
				}}>
				<ImageWithBasePath url={record.image} width={100} alt='product' />
			</div>
			//</div>
		),
	},
	{ title: 'Nombre', dataIndex: 'name', key: 'name', align: 'center' },
	{
		title: 'Precio unitario',
		dataIndex: 'unitPrice',
		key: 'unitPrice',
		align: 'center',
		render: (_, record) => {
			const prices = record.prices

			if (prices.priceWithDiscount) {
				if (prices.priceLiquidation) {
					return (
						<div className='d-flex flex-column gap-2'>
							<span className='text-decoration-line-through'>
								{prices.priceLiquidation}
							</span>
							<span>{prices.priceWithDiscount}</span>
						</div>
					)
				} else {
					return (
						<div className='d-flex flex-column gap-2'>
							<span className='text-decoration-line-through'>
								{prices.price}
							</span>
							<span>{prices.priceWithDiscount}</span>
						</div>
					)
				}
			} else if (prices.priceLiquidation) {
				return (
					<div className='d-flex flex-column gap-2'>
						<span className='text-decoration-line-through'>{prices.price}</span>
						<span>{prices.priceLiquidation}</span>
					</div>
				)
			} else {
				return <span>{prices.price}</span>
			}
		},
	},
	{
		title: 'Cantidad',
		dataIndex: 'quantity',
		key: 'quantity',
		align: 'center',
	},
	{
		title: 'Total',
		dataIndex: 'totalByProduct',
		key: 'totalByProduct',
		align: 'center',
	},
]

export const tablePinpadConsultations = (getVoucher) => [
	{
		title: 'Nombre',
		dataIndex: 'cc_nombre',
		key: 'cc_nombre',
		sorter: (a, b) => a.cc_nombre - b.cc_nombre,
		render: (name) => {
			return <span>{name ? name : 'No especificado'}</span>
		},
	},
	{
		title: 'Referencia',
		dataIndex: 'nb_referencia',
		key: 'nb_referencia',
		sorter: (a, b) => a.nb_referencia - b.nb_referencia,
	},
	{
		title: 'Folio',
		dataIndex: 'nu_operaion',
		key: 'nu_operaion',
		sorter: (a, b) => parseFloat(a.nu_operaion) - parseFloat(b.nu_operaion),
	},
	{
		title: 'Fecha y hora',
		dataIndex: 'fh_registro',
		key: 'fh_registro',
		sorter: (a, b) => {
			const parseDateTime = (datetime) => {
				const [date, time] = datetime.split(' ')
				const [day, month, year] = date.split('/').map(Number)
				const [hours, minutes] = time.split(':').map(Number)
				return new Date(year, month - 1, day, hours, minutes).getTime()
			}
			return parseDateTime(a.fh_registro) - parseDateTime(b.fh_registro)
		},
	},
	{
		title: 'Importe',
		dataIndex: 'nu_importe',
		key: 'nu_importe',
		render: (monto) => {
			return <span>{formatCurrency(monto)}</span>
		},
		sorter: (a, b) => parseFloat(a.nu_importe) - parseFloat(b.nu_importe),
	},
	{
		title: 'Estatus',
		dataIndex: 'nb_response',
		key: 'nb_response',
		render: (status) => {
			return <span>{status === 'approved' ? 'Aprobado' : 'Declinado'}</span>
		},
	},
	{
		title: 'Acciones',
		dataIndex: 'actions',
		key: 'actions',
		render: (_, record) => (
			<div className='action-table-data'>
				<div
					className='edit-delete-action gap-2'
					style={{
						justifyContent: 'start',
					}}>
					<Button
						onClick={() => {
							getVoucher(record.nu_operaion, 'getRspVoucherCliente')
						}}>
						Imprimir Voucher Cliente
					</Button>
					<Button
						onClick={() => {
							getVoucher(record.nu_operaion, 'getRspVoucherComercio')
						}}>
						Imprimir Voucher Comercio
					</Button>
				</div>
			</div>
		),
	},
]
