import React, { createContext, useEffect, useRef, useState } from 'react'
import {
	pinpadBrand,
	pinpadCurrency,
	pinpadCurrencyCode,
	pinpadEnviroment,
	pinpadModel,
	pinpadOperationType,
} from '../utils/var'
import Swal from 'sweetalert2'
import { formatCurrency } from '../utils/functions/money'
import {
	changeCash,
	changeExchange,
	changeIsLoadingTerminalPay,
	changeStatus,
	cleanPinpaPayBody,
	generateOrderBodyCard,
	generatePinpadPayBody,
	hideModalOrderInformation,
	showModalOrderInformation,
	transactOrder,
	updateVouchers,
} from '../redux/order'
import { useDispatch, useSelector } from 'react-redux'
import { selectCart, selectCartTotal } from '../redux/selectors/cart'
import { selectCouponCode } from '../redux/selectors/coupon'
import { formatDateToDDMMYYYY } from '../utils/functions/date'
import { handlePrintVoucher } from '../utils/functions/printTicket'
import { isNull } from 'lodash'

export const PinpadContext = createContext(undefined)

// eslint-disable-next-line react/prop-types
const PinpadProvider = ({ children }) => {
	const initValues = {
		Ambiente: pinpadEnviroment,
		dataUserConfig: '',
		dataPpConfig: '',
		name: 'initValues',
	}
	const objDataLogin = {
		Ambiente: '',
		Usuario: '',
		Pass: '',
		name: 'login',
	}
	let objDataKeys = {
		Ambiente: '',
		Usuario: '',
		Pass: '',
		Country: '',
		IdBranch: '',
		IdCompany: '',
		name: '',
	}
	const dispatch = useDispatch()
	const cartTotal = useSelector(selectCartTotal)
	const cart = useSelector(selectCart)
	const coupon = useSelector(selectCouponCode)
	let dataUser = () => localStorage.getItem('datosusuario')
	let dataPinpad = () => localStorage.getItem('datosPinPad')
	const iframe = document.getElementById('iframeTo')
	const messageChannelRef = useRef(new MessageChannel())
	const [isLoadingMovements, setIsLoadingMovements] = useState(false)
	const [portSelected, setPortSelected] = useState(null)
	const [consultations, setConsultations] = useState([])
	const [isPinpadConfigured, setIsPinpadConfigured] = useState(() => {
		const isPinpadConfiguredStorage = localStorage.getItem('isPinpadConfigured')
		return isPinpadConfiguredStorage === 'true'
	})

	useEffect(() => {
		navigator.serial.addEventListener('connect', pinpadConnected)
		initializeConnectionPinPadService()
	}, [])

	useEffect(() => {
		localStorage.setItem('isPinpadConfigured', isPinpadConfigured)
	}, [isPinpadConfigured])

	const getSerialPortSelected = () => {
		try {
			const informationSerialPinPadStorage = localStorage.getItem(
				'informationSerialPinPad'
			)
			const informationSerialPinPadParsed = JSON.parse(
				informationSerialPinPadStorage
			)

			if (
				!('usbProductId' in informationSerialPinPadParsed) ||
				!('usbVendorId' in informationSerialPinPadParsed)
			) {
				throw new Error('No se ha seleccionado un puerto serial.')
			}

			return informationSerialPinPadParsed
				? informationSerialPinPadParsed
				: null
		} catch (error) {
			console.error(error)
			return null
		}
	}

	const getIsPinPadConfigured = () => {
		try {
			const isPinpadConfiguredStorage =
				localStorage.getItem('isPinpadConfigured')

			return isPinpadConfiguredStorage === 'true'
		} catch (error) {
			console.error(error)
			return false
		}
	}

	const login = (usuario, contra, port1, setActiveStep) => {
		if (usuario === '' || contra === '') {
			console.log('Error!', 'Usuario/Password no debe ir vacío', 'error')
			return {
				message: 'Usuario/Password no debe ir vacío',
				statusCode: 403,
			}
		}

		objDataLogin.Ambiente = pinpadEnviroment
		objDataLogin.Usuario = usuario
		objDataLogin.Pass = contra

		port1.postMessage(objDataLogin)
		port1.onmessage = (event) => {
			const data = event.data

			const obj = JSON.parse(data)
			const aux = obj.RESPUESTA

			if (aux === null || aux === 'error') {
				console.log('Error!', `${obj.ERROR}`, 'error')
				setIsPinpadConfigured(false)
				setActiveStep(1)
				Swal.fire({
					icon: 'error',
					title: 'Error de autenticación',
					text: `${obj.ERROR}`,
					confirmButtonText: 'Ok',
				})
				return
			} else {
				localStorage.setItem('datosusuario', data)
				localStorage.setItem('user', `${usuario}|${contra}`)

				const objUser = JSON.parse(localStorage.getItem('datosusuario'))
				objDataKeys = {
					...objDataKeys,
					Ambiente: 'qa',
					Usuario: usuario,
					Pass: contra,
					Country: objUser.bs_country,
					IdBranch: objUser.bs_branch,
					IdCompany: objUser.bs_company,
					name: 'getKeysRSA',
				}

				// Obtener llaves RSA
				port1.postMessage(objDataKeys)
				port1.onmessage = (event) => {
					const keyResponse = event.data

					const keyObj = JSON.parse(keyResponse)
					const keyAux = keyObj.RESPUESTA

					if (keyAux === null || keyAux === 'error') {
						console.log('Error!', `${keyObj.ERROR} ${keyObj.codError}`, 'error')
						setActiveStep(1)
						setIsPinpadConfigured(false)
						Swal.fire({
							icon: 'error',
							title: 'Error de autenticación',
							text: `${keyObj.ERROR} ${keyObj.codError}`,
							confirmButtonText: 'Ok',
						})
						return
					} else {
						localStorage.setItem(
							'informationSerialPinPad',
							JSON.stringify(portSelected)
						)
						localStorage.setItem('datosPinPad', keyResponse)
						localStorage.setItem('isPinpadConfigured', true)
						console.log('Login y obtención de llaves RSA exitoso.')
						setIsPinpadConfigured(true)
						setActiveStep(3)
					}
				}
			}
		}
	}

	const initCommunication = () => {
		if (iframe && iframe.contentWindow) {
			iframe.contentWindow.postMessage('message', '*', [
				messageChannelRef.current.port2,
			])
		}
	}

	const sendMessageToPort1 = (message) => {
		messageChannelRef.current.port1.postMessage(message)
	}

	const sendMessageToPort2 = (message) => {
		messageChannelRef.current.port2.postMessage(message)
	}

	const updateIsPinpadConfigured = (value) => {
		setIsPinpadConfigured(value)
	}

	function pinpadDisconnected(event) {
		if (getSerialPortSelected()) {
			const serialDeviceInfo = event.target.getInfo()
			if (
				getSerialPortSelected().usbProductId == serialDeviceInfo.usbProductId &&
				getSerialPortSelected().usbVendorId == serialDeviceInfo.usbVendorId
			) {
				dispatch(hideModalOrderInformation())
				dispatch(changeCash(''))
				dispatch(changeExchange(''))
				dispatch(cleanPinpaPayBody())
				dispatch(changeIsLoadingTerminalPay(false))
				setIsPinpadConfigured(false)
				Swal.fire({
					icon: 'error',
					title: 'Error de autenticación de la terminal.',
					text: 'Se ha desconectado el dispositivo de la terminal, conectelo nuevamente o contacte a soporte para corregirlo.',
					confirmButtonText: 'Ok',
				})
			}
			setIsPinpadConfigured(false)
			navigator.serial.removeEventListener('disconnect', pinpadDisconnected)
		} else {
			setIsPinpadConfigured(false)
			Swal.fire({
				icon: 'error',
				title: 'Error de conexión de la terminal.',
				text: 'Se ha perdido la conexión con la terminal, contacte a soporte.',
				confirmButtonText: 'Ok',
			})
		}
	}

	function pinpadConnected(event) {
		if (getSerialPortSelected()) {
			const serialDeviceInfo = event.target.getInfo()
			if (
				getSerialPortSelected().usbProductId == serialDeviceInfo.usbProductId &&
				getSerialPortSelected().usbVendorId == serialDeviceInfo.usbVendorId
			) {
				let timerInterval
				Swal.fire({
					icon: 'success',
					title: 'Terminal conectada',
					html: 'La conexion con la terminal se ha restablecido. Espere <b></b> <b>segundos</b> para que se configure.',
					timer: 31000,
					timerProgressBar: true,
					allowOutsideClick: false,
					didOpen: () => {
						Swal.showLoading()
						const timer = Swal.getPopup().querySelector('b')
						timerInterval = setInterval(() => {
							timer.textContent = `${Math.trunc(Swal.getTimerLeft() / 1000)}`
						}, 1000)
					},
					willClose: () => {
						clearInterval(timerInterval)
					},
				})
				initializeConnectionPinPadService()
				setIsPinpadConfigured(true)
			}
		} else {
			setIsPinpadConfigured(false)
			Swal.fire({
				icon: 'error',
				title: 'Error de conexión de la terminal.',
				text: 'No se he podido establece.',
				confirmButtonText: 'Ok',
			})
		}
	}

	const initializeConnectionPinPadService = () => {
		if (iframe) {
			iframe.addEventListener('load', initCommunication)
		}

		const datosU = localStorage.getItem('datosPinPad')
		const datosUsuario = localStorage.getItem('datosusuario')
		const user = localStorage.getItem('user')

		let obj

		if (datosU != null && datosUsuario != null && user != null) {
			obj = JSON.parse(datosU)
			const objUser = JSON.parse(datosUsuario)

			initValues.dataUserConfig = objUser.dataUserConfig
			initValues.dataPpConfig = obj.dataPpConfig

			messageChannelRef.current.port1.postMessage(initValues)
			messageChannelRef.current.port1.onmessage = (event) => {
				try {
					const response = event.data
					obj = JSON.parse(response)
					const aux = obj['RESPUESTA']
					console.log('respWP-' + response)
					if (aux == null || aux == 'error') {
						setIsPinpadConfigured(false)
						console.error(
							'Error!',
							obj['ERROR'],
							'error: Credenciales de terminal incorrectas o alteradas, contacte a soporte para corregirlo.'
						)
						return
					} else {
						navigator.serial.addEventListener('disconnect', pinpadDisconnected)
						setIsPinpadConfigured(true)
					}
				} catch (error) {
					setIsPinpadConfigured(false)
					console.error(
						'Error!',
						obj['ERROR'],
						'error: Credenciales de terminal incorrectas o alteradas, contacte a soporte para corregirlo.'
					)
					messageChannelRef.current.port1.removeEventListener('message', this)
					return
				}
			}
		} else {
			setIsPinpadConfigured(false)
			console.error('Se necesita autenticar la terminal, contacte a soporte.')
		}
	}

	const clearPinpad = () => {
		setIsPinpadConfigured(false)
		localStorage.removeItem('datosusuario')
		localStorage.removeItem('datosPinPad')
		localStorage.removeItem('user')
		localStorage.removeItem('isPinpadConfigured')
		localStorage.removeItem('informationSerialPinPad')
	}

	const showPortsToPermission = async () => {
		try {
			const port = await navigator.serial.requestPort()
			if (!port) {
				throw new Error('Puerto de comunicación invalido.')
			} else {
				setPortSelected(port.getInfo())
			}
		} catch (error) {
			console.log('Error al configurar el puerto COM', '', 'error')
		}
	}

	const consultation = async (date = new Date(), reference = '') => {
		setIsLoadingMovements(true)
		if (!dataUser() || !dataPinpad()) {
			setIsPinpadConfigured(false)
			Swal.fire({
				title: 'Error',
				text: `Se necesita volver a configurar la terminal, datos de usuario o de terminal inexistentes o erroneos, contacte a soporte.`,
				icon: 'error',
			})
			setIsLoadingMovements(false)
			return
		}
		const port1 = messageChannelRef.current.port1
		const dataUserObject = JSON.parse(dataUser())

		const user = localStorage.getItem('user')
		const paramsUser = user.split('|')

		const objectConsulta = {
			Ambiente: pinpadEnviroment,
			User: dataUserObject['bs_user'],
			Pwd: paramsUser[1],
			IdBranch: dataUserObject['bs_branch'],
			IdCompany: dataUserObject['bs_company'],
			Country: dataUserObject['bs_country'],
			Tx_Date: formatDateToDDMMYYYY(date),
			Reference: reference,
			name: 'sndConsulta',
		}

		port1.postMessage(objectConsulta)
		port1.onmessage = (event) => {
			console.log(event.data)
			/* const response = event.data
			const objTRX = JSON.parse(response)
			const aux = objTRX['RESPUESTA'] */

			let aux, objTRX
			const response = event.data

			if (typeof response === 'object' && !isNull(response)) {
				const responseValues = Object.keys(response)
				console.log(responseValues)

				if (responseValues[0] === 'error') {
					aux = responseValues[0]
					objTRX = {
						RESPUESTA: responseValues[0],
						ERROR: response[responseValues[0]],
						codError: response[responseValues[1]],
					}
				} else {
					aux = response
					objTRX = response
				}
			}

			if (typeof response === 'string') {
				try {
					objTRX = JSON.parse(response)
					if (typeof objTRX === 'object' && !isNull(objTRX)) {
						aux = objTRX.RESPUESTA
					}
				} catch (error) {
					console.log(error)
					Swal.fire({
						title: 'Error',
						text: `Ocurrió un error: ${error}`,
						icon: 'error',
					})
					return
				}
			}

			const responseVoid = Object.keys(objTRX).length === 0

			if (aux == 'error' || responseVoid) {
				console.error(response)

				let resp, code
				if (responseVoid) {
					resp = 'Error desconoicido, intentelo de nuevo. Respuesta vacia'
					code = 'Error: Desconocido'
				} else {
					resp = 'Respuesta: ' + objTRX['RESPUESTA']
					resp += '\n' + 'DescError: ' + objTRX['ERROR']
					resp += '\n' + 'CodError: ' + objTRX['codError']
					code = `Error: ${objTRX['codError']}`
				}

				Swal.fire({
					title: code,
					text: resp,
					icon: 'error',
				})
				setIsLoadingMovements(false)
				setConsultations([])
				return
			} else {
				const consultationsParsed = JSON.parse(response).transaccion

				setConsultations(
					Array.isArray(consultationsParsed)
						? consultationsParsed
						: consultationsParsed === null || consultationsParsed === undefined
						? []
						: [consultationsParsed]
				)
				setIsLoadingMovements(false)
			}
		}
	}

	const getVoucher = async (operationNumber, type) => {
		if (!dataUser() || !dataPinpad()) {
			Swal.fire({
				title: 'Error',
				text: `Se necesita volver a configurar la terminal, datos de usuario o de terminal inexistentes o erroneos, contacte a soporte.`,
				icon: 'error',
			})
			setIsPinpadConfigured(false)
			return
		}
		const dataUserObject = JSON.parse(dataUser())
		const user = localStorage.getItem('user')
		const paramsUser = user.split('|')

		const port1 = messageChannelRef.current.port1
		const objDataReprint = {
			Ambiente: pinpadEnviroment,
			User: dataUserObject['bs_user'],
			Pwd: paramsUser[1],
			IdBranch: dataUserObject['bs_branch'],
			IdCompany: dataUserObject['bs_company'],
			Country: dataUserObject['bs_country'],
			Tx_OperationNumber: operationNumber,
			name: 'sndReimpresion',
		}

		port1.postMessage(objDataReprint)
		port1.onmessage = (event) => {
			const response = event.data
			//console.log('respWP Reimp-' + response)
			const objTRX = JSON.parse(response)
			const aux = objTRX['RESPUESTA']

			if (objTRX['response'] == 'approved') {
				port1.postMessage({ name: type })
				port1.onmessage = (event) => {
					const response = event.data
					const objPrint = JSON.parse(response)
					const voucher =
						type === 'getRspVoucherCliente'
							? objPrint.voucher_cliente
							: objPrint.voucher_comercio
					printVoucher(voucher, type)
				}
			} else {
				let resp
				if (aux == null || aux == 'error') {
					resp = 'Respuesta: ' + objTRX['RESPUESTA']
					resp += '\n' + 'DescError: ' + objTRX['ERROR']
					resp += '\n' + 'CodError: ' + objTRX['codError']
					Swal.fire({
						title: `Error: ${objTRX['codError']}`,
						text: resp,
						icon: 'error',
					})
					setIsLoadingMovements([])
					return
				}
			}
		}
	}

	const printVoucher = async (voucher, type) => {
		let isPrinterAvailableVoucher = false
		do {
			try {
				const isHandlePrintVoucher = await handlePrintVoucher(
					voucher,
					type === 'getRspVoucherCliente' ? 'Cliente' : 'Comercio'
				)
				if (!isHandlePrintVoucher) {
					const title = 'Error al imprimir'
					const html =
						'No se pudo imprimir. <br/>' + '¿Desea intentar de nuevo?'
					const result = await Swal.fire({
						title: title,
						html: html,
						icon: 'error',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Sí',
						cancelButtonText: 'No',
					})

					if (result.isDismissed) {
						isPrinterAvailableVoucher = true
					}
				} else {
					isPrinterAvailableVoucher = true
				}
			} catch (error) {
				const title = 'Error al imprimir'
				const html = `No se pudo imprimir. <br>¿Desea intentar de nuevo?`
				const result = await Swal.fire({
					title: title,
					html: html,
					icon: 'error',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Sí',
					cancelButtonText: 'No',
				})

				if (result.isDismissed) {
					isPrinterAvailableVoucher = true
				}
			}
		} while (!isPrinterAvailableVoucher)
	}

	const cancelReadCard = async () => {
		const port1 = messageChannelRef.current.port1
		try {
			if (!port1 || port1 === undefined) {
				throw new Error('Puerto de comunicación invalido.')
			}

			port1.postMessage({
				name: 'cancelReadCard',
				modeloTerminalPP: pinpadModel,
			})
			port1.onmessage = (event) => {
				const responseCancelReadCard = event.data
				console.log(responseCancelReadCard)

				const objectCancelReadCard = JSON.parse(responseCancelReadCard)
				console.log(objectCancelReadCard)
			}
		} catch (error) {
			console.error(error.message)
			Swal.fire({
				icon: 'error',
				title: 'Error al cancelar',
				text: `Error al momento de cancelar la orden.`,
				confirmButtonText: 'Ok',
			})
		}
	}

	const readCard = async () => {
		dispatch(changeStatus('readingcard'))
		dispatch(showModalOrderInformation())
		if (!dataUser() || !dataPinpad()) {
			setIsPinpadConfigured(false)
			Swal.fire({
				title: 'Error',
				text: `Se necesita volver a configurar la terminal, datos de usuario o de terminal inexistentes o erroneos, contacte a soporte.`,
				icon: 'error',
			})
			dispatch(hideModalOrderInformation())
			dispatch(changeStatus('cancelled'))
			dispatch(changeIsLoadingTerminalPay(false))
			return
		}
		const port1 = messageChannelRef.current.port1
		try {
			if (!port1 || port1 === undefined) {
				throw new Error('Puerto de comunicación invalido.')
			}

			if (
				cartTotal < 1 ||
				!cartTotal ||
				isNaN(cartTotal) ||
				cartTotal === undefined
			) {
				throw new Error('Cantidad invalida.')
			}

			const cartTotalFormatted = formatCurrency(cartTotal)
				.substring(1)
				.replaceAll(',', '')

			const objectDataTRX = {
				Ambiente: pinpadEnviroment,
				Currency: pinpadCurrency,
				CurrencyCode: pinpadCurrencyCode,
				Amount: cartTotalFormatted,
				TimeOutPinPad: '60',
				MarcaTerminal: pinpadBrand,
				ModeloTerminal: pinpadModel,
				name: 'readCard',
			}

			console.log(objectDataTRX, event)
			port1.postMessage(objectDataTRX)
			port1.onmessage = (event) => {
				let auxResponse, objectTRX
				const responseReadCard = event.data
				/* objectTRX = JSON.parse(responseReadCard)
				auxResponse = objectTRX.RESPUESTA */

				if (typeof responseReadCard === 'object' && !isNull(responseReadCard)) {
					const responseValues = Object.keys(responseReadCard)
					console.log(responseValues)

					if (responseValues[0] === 'error') {
						auxResponse = responseValues[0]
						objectTRX = {
							RESPUESTA: responseValues[0],
							ERROR: responseReadCard[responseValues[0]],
							codError: responseReadCard[responseValues[1]],
						}
					} else {
						auxResponse = responseReadCard
						objectTRX = responseReadCard
					}
				}

				if (typeof responseReadCard === 'string') {
					try {
						objectTRX = JSON.parse(responseReadCard)
						if (typeof objectTRX === 'object' && !isNull(objectTRX)) {
							auxResponse = objectTRX.RESPUESTA
						}
					} catch (error) {
						console.log(error)
						dispatch(hideModalOrderInformation())
						dispatch(changeStatus('cancelled'))
						dispatch(changeIsLoadingTerminalPay(false))
						Swal.fire({
							title: 'Error',
							text: `Ocurrió un error: ${error}`,
							icon: 'error',
						})
						return
					}
				}

				if (auxResponse == null || auxResponse == 'error') {
					console.error('respWP readCard-' + responseReadCard)
					dispatch(hideModalOrderInformation())
					dispatch(changeStatus('cancelled'))
					dispatch(changeIsLoadingTerminalPay(false))
					if (objectTRX.codError === 'PPE03') {
						setIsPinpadConfigured(false)
					}
					Swal.fire({
						title: 'Error',
						text: `${objectTRX['ERROR']} - Código: ${objectTRX.codError}`,
						icon: 'error',
					})
					return
				} else {
					console.log(responseReadCard, auxResponse, objectTRX)
					getMerchant(objectTRX)
				}
			}
		} catch (error) {
			console.error(error.message)
			Swal.fire({
				title: 'Error',
				text: `${error.message}`,
				icon: 'error',
			})
		}
	}

	const getMerchant = async (objectTRX) => {
		dispatch(changeStatus('comprobatemerchant'))
		dispatch(showModalOrderInformation())
		if (!dataUser() || !dataPinpad()) {
			setIsPinpadConfigured(false)
			Swal.fire({
				title: 'Error',
				text: `Se necesita volver a configurar la terminal, datos de usuario o de terminal inexistentes o erroneos, contacte a soporte.`,
				icon: 'error',
			})
			dispatch(hideModalOrderInformation())
			dispatch(changeStatus('cancelled'))
			dispatch(changeIsLoadingTerminalPay(false))
			cancelReadCard()
			return
		}

		const dataUserParsed = JSON.parse(dataUser())
		const port1 = messageChannelRef.current.port1
		const maskPan = objectTRX.maskPan
		let BIN = maskPan.length > 6 ? maskPan.substring(0, 6) : maskPan
		const objectDataMerchant = {
			Ambiente: pinpadEnviroment,
			BIN: BIN,
			User: dataUserParsed['bs_user'],
			Currency: pinpadCurrency,
			Tx_OperationType: pinpadOperationType,
			name: 'getMerchant',
		}

		port1.postMessage(objectDataMerchant)
		port1.onmessage = (event) => {
			const responseGetMerchant = event.data
			const objectTRX = JSON.parse(responseGetMerchant)
			const auxResponse = objectTRX.respuesta

			if (auxResponse == null || auxResponse == '0') {
				let respMer = objectTRX['nb_respuesta']

				if (respMer == '' || respMer == null || respMer == undefined)
					respMer = objectTRX['ERROR']

				dispatch(hideModalOrderInformation())
				dispatch(changeStatus('cancelled'))
				dispatch(changeIsLoadingTerminalPay(false))
				Swal.fire({
					title: 'Error',
					text: `${respMer}`,
					icon: 'error',
				})
				return
			} else {
				const merchant = objectTRX.contado.af.merchant
				executePay(merchant)
			}
		}
	}

	const executePay = async (merchant) => {
		dispatch(changeStatus('executingpay'))
		dispatch(showModalOrderInformation())
		if (!dataUser() || !dataPinpad()) {
			setIsPinpadConfigured(false)
			Swal.fire({
				title: 'Error',
				text: `Se necesita volver a configurar la terminal, datos de usuario o de terminal inexistentes o erroneos, contacte a soporte.`,
				icon: 'error',
			})
			dispatch(hideModalOrderInformation())
			dispatch(changeStatus('cancelled'))
			dispatch(changeIsLoadingTerminalPay(false))
			cancelReadCard()
			return
		}

		const date = new Date()
		const reference = (+date).toString()
		const port1 = messageChannelRef.current.port1
		const dataUserObject = JSON.parse(dataUser())
		const dataPinpadObject = JSON.parse(dataPinpad())

		const user = localStorage.getItem('user')
		const paramsUser = user.split('|')

		const objectDataCheckout = {
			Ambiente: pinpadEnviroment,
			Country: dataUserObject['bs_country'],
			IdBranch: dataUserObject['bs_branch'],
			IdCompany: dataUserObject['bs_company'],
			pwd: paramsUser[1],
			User: dataUserObject['bs_user'],
			UserTRX: 'userPinpadWeb',
			EMV: dataPinpadObject['EMV'],
			ModeloTerminal: dataPinpadObject['modelo'],
			SerieTerminal: dataPinpadObject['serie'],

			Printer: dataPinpadObject['impresora'],
			VersionTerminal: dataPinpadObject['versionApp'],
			TpOperation: pinpadOperationType,
			Reference: reference,
			Currency: pinpadCurrency,
			Merchant: merchant,
			name: 'sndVentaDirectaEMV',
		}

		port1.postMessage(objectDataCheckout)
		port1.onmessage = (event) => {
			let responseTRX

			const responseExecutePay = event.data
			const objectResponseExecutePayData = JSON.parse(responseExecutePay)

			if (objectResponseExecutePayData['response'] !== 'approved') {
				dispatch(hideModalOrderInformation())
				dispatch(changeStatus('cancelled'))
				dispatch(changeIsLoadingTerminalPay(false))
				let codeError
				let messageError

				if (objectResponseExecutePayData['response'] === 'denied') {
					codeError = objectResponseExecutePayData['cd_response']
					messageError = objectResponseExecutePayData['friendly_response']
				} else {
					if (objectResponseExecutePayData['response'] === 'error') {
						codeError = objectResponseExecutePayData['cd_error']
						messageError = objectResponseExecutePayData['nb_error']
						responseTRX =
							'Response: ' +
							objectResponseExecutePayData['response'] +
							'  /  No. Operación: ' +
							objectResponseExecutePayData['foliocpagos'] +
							'  /  CodeError: ' +
							objectResponseExecutePayData['cd_error'] +
							'  /  DesError: ' +
							objectResponseExecutePayData['nb_error']
					} else {
						if (objectResponseExecutePayData['codError']) {
							codeError = objectResponseExecutePayData['codError']
							messageError = objectResponseExecutePayData['ERROR']
							responseTRX =
								'Response: ' +
								objectResponseExecutePayData['RESPUESTA'] +
								'  /  Error: ' +
								objectResponseExecutePayData['ERROR'] +
								'  /  CodeError: ' +
								objectResponseExecutePayData['codError'] +
								'  /  DesError: ' +
								objectResponseExecutePayData['ERROR']
							Swal.fire({
								title: `Error: ${codeError}`,
								text: `${messageError}\n${responseTRX ? responseTRX : ''}`,
								icon: 'error',
							})
							return
						} else {
							dispatch(changeStatus('executingpaywithconsultation'))
							dispatch(showModalOrderInformation())

							const objectConsulta = {
								Ambiente: pinpadEnviroment,
								User: dataUserObject['bs_user'],
								Pwd: paramsUser[1],
								IdBranch: dataUserObject['bs_branch'],
								IdCompany: dataUserObject['bs_company'],
								Country: dataUserObject['bs_country'],
								Tx_Date: formatDateToDDMMYYYY(date),
								Reference: reference,
								name: 'sndConsulta',
							}

							port1.postMessage(objectConsulta)
							port1.onmessage = (event) => {
								var response = event.data

								console.log('respWP-' + response)
								const objTRX = JSON.parse(response)
								const aux = objTRX['RESPUESTA']

								if (aux == 'error') {
									dispatch(hideModalOrderInformation())
									dispatch(changeStatus('cancelled'))
									dispatch(changeIsLoadingTerminalPay(false))
									let resp
									resp = 'Respuesta: ' + objTRX['RESPUESTA']
									resp += '\n' + 'DescError: ' + objTRX['ERROR']
									resp += '\n' + 'CodError: ' + objTRX['codError']
									Swal.fire({
										title: `Error: ${objTRX['codError']}`,
										text: resp,
										icon: 'error',
									})
									return
								} else {
									const payDetails = {
										status: 'APROBADA',
										datetime: `${objTRX['date']} ${objTRX['time']}`,
										folio: objTRX['foliocpagos'],
										reference: objTRX['reference'],
										authorization: objTRX['auth'],
									}

									dispatch(
										generateOrderBodyCard({
											items: cart,
											coupons: coupon ? [coupon] : [],
											total: cartTotal,
											folio: objTRX['foliocpagos'],
											timestamp: reference,
										})
									)
									dispatch(generatePinpadPayBody(payDetails))
									dispatch(transactOrder(payDetails))
								}
							}

							dispatch(changeIsLoadingTerminalPay(false))
							return
						}
					}
				}
			} else {
				responseTRX =
					'Response: ' +
					objectResponseExecutePayData['response'] +
					'  /  No. Operación: ' +
					objectResponseExecutePayData['foliocpagos'] +
					'  /  No. Auth: ' +
					objectResponseExecutePayData['auth'] +
					'  /  CdResponse: ' +
					objectResponseExecutePayData['cd_response'] +
					'  /  Arqc: ' +
					objectResponseExecutePayData['arqc'] +
					'  /  Aid: ' +
					objectResponseExecutePayData['appid'] +
					'  /  AidLabel: ' +
					objectResponseExecutePayData['appidlabel']

				const payDetails = {
					status: 'APROBADA',
					datetime: `${objectResponseExecutePayData['date']} ${objectResponseExecutePayData['time']}`,
					folio: objectResponseExecutePayData['foliocpagos'],
					reference: objectResponseExecutePayData['reference'],
					authorization: objectResponseExecutePayData['auth'],
				}
				dispatch(
					updateVouchers({
						voucherBusiness: objectResponseExecutePayData.voucher_comercio,
						voucherClient: objectResponseExecutePayData.voucher_cliente,
					})
				)
				dispatch(
					generateOrderBodyCard({
						items: cart,
						coupons: coupon ? [coupon] : [],
						total: cartTotal,
						folio: objectResponseExecutePayData['foliocpagos'],
						timestamp: reference,
					})
				)
				dispatch(generatePinpadPayBody(payDetails))
				dispatch(transactOrder(payDetails))
				dispatch(changeIsLoadingTerminalPay(false))
				dispatch(changeStatus('payed'))
				return
			}
		}
	}

	/* const executePostMessage = (body) => {
		const port1 = messageChannelRef.current.port1
		return new Promise(function (resolve) {
			port1.postMessage(body)
			port1.onmessage = (event) => {
				var response = event.data
				resolve(response)
			}
		})
	} */

	return (
		<PinpadContext.Provider
			value={{
				port1: messageChannelRef.current.port1,
				port2: messageChannelRef.current.port2,
				messageChannel: messageChannelRef.current,
				isPinpadConfigured,
				isLoadingMovements,
				portSelected,
				consultations,
				login,
				updateIsPinpadConfigured,
				initCommunication,
				sendMessageToPort1,
				sendMessageToPort2,
				clearPinpad,
				consultation,
				showPortsToPermission,
				cancelReadCard,
				readCard,
				getSerialPortSelected,
				getIsPinPadConfigured,
				getVoucher,
			}}>
			{children}
		</PinpadContext.Provider>
	)
}

export default PinpadProvider
