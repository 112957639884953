import React, { useEffect, useState } from 'react'
import { Input } from 'antd'
import { useDispatch } from 'react-redux'
import { updateProductQuantity } from '../redux/new-sale'
import PropTypes from 'prop-types'

const SaleQuantityInput = ({ product, currentQuantity }) => {
	const dispatch = useDispatch()
	const [inputValue, setInputValue] = useState(currentQuantity.toString())
	const [previousQuantity, setPreviousQuantity] = useState(currentQuantity)

	const handleChange = (e) => {
		const value = e.target.value
		const numericValue = value.replace(/[^0-9]/g, '')
		setInputValue(numericValue)
	}

	const handleBlur = () => {
		let newQuantity = parseInt(inputValue) || 0

		newQuantity = Math.min(newQuantity, product.OnHand)

		newQuantity = Math.max(1, newQuantity)

		dispatch(
			updateProductQuantity({
				ItemCode: product.ItemCode,
				quantity: newQuantity,
			})
		)

		setInputValue(newQuantity.toString())
		setPreviousQuantity(newQuantity)
	}

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			setInputValue(previousQuantity.toString())
			e.target.blur()
		}
	}

	useEffect(() => {
		setInputValue(currentQuantity.toString())
		setPreviousQuantity(currentQuantity)
	}, [currentQuantity])

	return (
		<>
			<Input
				value={inputValue}
				onChange={handleChange}
				onBlur={handleBlur}
				onKeyDown={handleKeyDown}
				type='text'
				style={{
					width: '80px',
					textAlign: 'center',
					marginLeft: '10px',
					marginRight: '10px',
				}}
			/>
		</>
	)
}

SaleQuantityInput.propTypes = {
	product: PropTypes.object,
	currentQuantity: PropTypes.number,
}

export default SaleQuantityInput
