import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
	createStateSyncMiddleware,
	initMessageListener,
} from 'redux-state-sync'

// Importaciones de reducers
import cartReducer, { clean } from '../features/pos/redux/cart'
import couponReducer from './coupon'
import catalogReducer from './catalog'
import scannerReducer from './scanner'
import authenticationReducer, {
	resetLoadingStates as resetChangableStatesAuth,
} from './authentication'
import orderReducer, {
	resetChangeableStates as resetChangableStatesOrder,
} from './order'
import cashCountReducer from '../features/cash-count/redux/cash-count'
import withdrawReducer, { resetChangableStatesWithdraw } from './withdraw'
import saleReducer from '../features/cash-count/redux/new-sale'

/**
 * Combinación de todos los reducers de la aplicación.
 */
const rootReducer = combineReducers({
	cart: cartReducer,
	coupon: couponReducer,
	catalog: catalogReducer,
	authentication: authenticationReducer,
	order: orderReducer,
	'cash-count': cashCountReducer,
	withdraw: withdrawReducer,
	sale: saleReducer,
	scanner: scannerReducer
})

/**
 * Configuración para la persistencia del estado.
 */
const persistConfig = {
	key: 'root',
	storage,
	blacklist: ['coupon', 'catalog', 'order', 'sale', 'cash-count', 'withdraw', 'scanner' ]
}

/**
 * Reducer persistente que combina la configuración de persistencia con el reducer raíz.
 */
const persistedReducer = persistReducer(persistConfig, rootReducer)

/**
 * Middleware para la sincronización del estado entre pestañas/ventanas.
 */
const stateSyncMiddleware = createStateSyncMiddleware({
	blacklist: ['persist/PERSIST', 'persist/REHYDRATE'],
})

/**
 * Configuración del store de Redux.
 */
const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(stateSyncMiddleware),
})

// Inicializa el listener para la sincronización del estado
initMessageListener(store)

/**
 * Persistor para manejar la persistencia del estado.
 */
const persistor = persistStore(store, null, () => {
	store.dispatch(resetChangableStatesAuth())
	store.dispatch(resetChangableStatesOrder())
	store.dispatch(resetChangableStatesWithdraw())
	store.dispatch(clean())
})

export { store, persistor }
