import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiUrl } from '../utils/var'

const initialState = {
	contents: {
		items: [],
		ScannedProduct: '',
	},
	isLoading: false,
	error: null,
	codeBars: null,
}

export const fetchItems = createAsyncThunk(
	'scanner/fetchItems',
	async () => {
		const url = `${apiUrl}/api/items-without-auth/getItemsLiquidationWithoutAuth`
		try {
			const response = await fetch(url, {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				credentials: 'include',
			})
			if (!response.ok) {
				let errorMessage
				try {
					const errorBody = await response.json()
					errorMessage = errorBody.message || 'Error desconocido'
				} catch {
					errorMessage =
						'Ocurrió un error al traer los productos, recargue el escaner'
				}

				const errorInfo = {
					code: response.status,
					message: errorMessage,
					url: url,
				}

				throw new Error(JSON.stringify(errorInfo))
			}

			const responseBody = await response.json()
			
			return responseBody
		} catch (error) {
			console.log(error)
			let errorMessage

			try {
				const errorInfo = JSON.parse(error.message)
				errorMessage = errorInfo.message
			} catch {
				errorMessage =
					'Ocurrio un error al traer los productos, recargue el punto de venta'
			}
			throw new Error(errorMessage)
		}
	}
)

const scannerSlice = createSlice({
	name: 'scanner',
	initialState,
	reducers: {
		changeScannedProduct: (state,action) => {
			const scannedProduct = action.payload
			return {
				...state,
				contents: {
					...state.contents,
					ScannedProduct: scannedProduct,
				},
			}
		},
		changeCodeBars:(state, action) => {
			state.codeBars = action.payload
		},
		changeIsLoading:(state, action) => {
			state.isLoading = action.payload
		}
	},
	extraReducers: (builder) => {
		builder.addCase(fetchItems.pending, (state) => {
			state.isLoading = true
			state.error = null
		})
		.addCase(fetchItems.fulfilled, (state, action) => {
			state.contents.items = action.payload.items
			//console.log(action.payload)
			state.isLoading = false
			state.error = null
		})
		.addCase(fetchItems.rejected, (state, action) => {
			state.isLoading = false
			state.error = action.error.message
		})
	},
})

export const {changeScannedProduct, changeCodeBars} = scannerSlice.actions

export default scannerSlice.reducer
