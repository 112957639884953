import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiUrl, idDevice } from '../utils/var'
import {
	convertDateToMilitaryTimeFormat,
	formatDate,
} from '../utils/functions/date'
import { handlePrintRetiro } from '../utils/functions/printTicket'
import { updateIsShowConfirm } from '../features/cash-count/redux/cash-count'
import Swal from 'sweetalert2'

const initialState = {
	isShowModalWithdraw: false,
	isInWithdraw: false,
	amountCashDrawer: '',
	withDrawalAmount: '',
	gerente: null,
	fecha: '',
	hora: '',
	isLoading: false,
	error: null,
	status: 'noinit',
	place: 'pos',
}

export const transactWithdraw = createAsyncThunk(
	'withdraw/transactWithdraw',
	async ({ password }, { getState }) => {
		try {
			const {
				amountCashDrawer,
				withDrawalAmount,
				gerente,
				fecha,
				hora,
				place,
			} = getState().withdraw
			const url = `${apiUrl}/api/withdraw/createWithdraw/${idDevice()}`
			const response = await fetch(url, {
				method: 'PATCH',
				body: JSON.stringify({
					U_CSM_SaldoAcumulado: amountCashDrawer,
					U_CSM_SaldoRetirado: Number(withDrawalAmount),
					U_CSM_EmpRetiro: +gerente,
					U_CSM_FechaRetiro: fecha,
					U_CSM_HoraRetiro: hora,
					idDevice: idDevice(),
					place: place,
					password,
				}),
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				credentials: 'include',
			})

			if (!response.ok) {
				let errorMessage
				try {
					const errorBody = await response.json()
					errorMessage = errorBody.message || 'Error desconocido'
				} catch {
					errorMessage =
						'Ocurrio un error al generar el retiro, recargue el punto de venta o contacte al encargado.'
				}

				const errorInfo = {
					code: response.status,
					message: errorMessage,
					url: url,
				}

				throw new Error(JSON.stringify(errorInfo))
			}
			const responseBody = await response.json()

			let isPrinterAvailableRetiro = false
			do {
				try {
					if (!isPrinterAvailableRetiro) {
						const isHandlePrintRetiro = await handlePrintRetiro(responseBody)
						if (isHandlePrintRetiro) isPrinterAvailableRetiro = true
					}

					if (!isPrinterAvailableRetiro) {
						const title = 'Error al imprimir'
						const html =
							'No se pudo imprimir el comprobante de Retiro <br/>' +
							'¿Desea intentar de nuevo?'
						const result = await Swal.fire({
							title: title,
							html: html,
							icon: 'error',
							showCancelButton: true,
							confirmButtonColor: '#3085d6',
							cancelButtonColor: '#d33',
							confirmButtonText: 'Sí',
							cancelButtonText: 'No',
						})

						if (result.isDismissed) {
							isPrinterAvailableRetiro = true
						}
					}
				} catch (error) {
					console.log(error)

					const errorBody = JSON.parse(error.message)
					const typePrint = errorBody.type
					const title = 'Error al imprimir'
					const html = `No se pudo imprimir: ${typePrint} <br>¿Desea intentar de nuevo?`
					const result = await Swal.fire({
						title: title,
						html: html,
						icon: 'error',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Sí',
						cancelButtonText: 'No',
					})

					if (result.isDismissed) {
						isPrinterAvailableRetiro = true
					}
				}
			} while (!isPrinterAvailableRetiro)
		} catch (error) {
			let errorMessage
			console.error(error)

			try {
				const errorInfo = JSON.parse(error.message)
				errorMessage = errorInfo.message
			} catch {
				errorMessage = 'Ocurrio un error'
			}
			throw new Error(errorMessage)
		}
	}
)

export const getNextWithdraw = createAsyncThunk(
	'withdraw/getNextWithdraw',
	async (_, { getState, dispatch }) => {
		try {
			const idEmployee = getState()['cash-count'].idEmployee

			const url = `${apiUrl}/api/withdraw/next/${idDevice()}`
			const response = await fetch(url, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				credentials: 'include',
			})

			if (!response.ok) {
				let errorMessage
				try {
					const errorBody = await response.json()
					errorMessage = errorBody.message || 'Error desconocido'
				} catch {
					errorMessage =
						'Ocurrio un error al trraer el siguiente retiro, recargue el punto de venta o contacte al encargado.'
				}

				const errorInfo = {
					code: response.status,
					message: errorMessage,
					url: url,
				}

				throw new Error(JSON.stringify(errorInfo))
			}

			const data = await response.json()
			data.data.idEmployee = idEmployee
			const amountCashDrawer = parseFloat(data.data.amountCashDrawer)
			const maxBalance = parseInt(data.data.maxBalance)

			if (amountCashDrawer <= maxBalance) {
				dispatch(updateIsShowConfirm(true))
			}

			return data
		} catch (error) {
			let errorMessage
			console.error(error)

			try {
				const errorInfo = JSON.parse(error.message)
				errorMessage = errorInfo.message
			} catch {
				errorMessage = 'Ocurrio un error'
			}
			throw new Error(errorMessage)
		}
	}
)

export const withdrawSlice = createSlice({
	name: 'withdraw',
	initialState,
	reducers: {
		initWithdraw: (state, action) => {
			state.isInWithdraw = true
			state.amountCashDrawer = Number(action.payload.amountCashDrawer)
			state.withDrawalAmount = Number(action.payload.withDrawalAmount)
			state.gerente = action.payload.mangerId
			state.hora = convertDateToMilitaryTimeFormat(new Date())
			state.fecha = formatDate(new Date())
		},
		cleanWithdraw: () => {
			return initialState
		},
		verifyNeedWithdraw: (state, action) => {
			const isShow = action.payload
			state.isShowModalWithdraw = isShow
		},
		changeGerente: (state, action) => {
			state.gerente = action.payload
		},
		changeEfectivoRetiro: (state, action) => {
			state.withDrawalAmount = action.payload
		},
		changeStatus: (state, action) => {
			state.status = action.payload
		},
		resetChangableStatesWithdraw: (state) => {
			state.error = null
		},
		changePlaceToPOS: (state) => {
			state.place = 'pos'
		},
		changePlaceToAdmin: (state) => {
			state.place = 'admin'
		},
	},
	extraReducers: (builder) => {
		builder.addCase(transactWithdraw.pending, (state) => {
			state.isLoading = true
			state.error = null
			state.status = 'loading'
		})
		builder.addCase(transactWithdraw.fulfilled, (state) => {
			return {
				...initialState,
				isLoading: false,
				error: null,
				isShowModalWithdraw: true,
				status: 'complete',
				place: state.place,
			}
		})
		builder.addCase(transactWithdraw.rejected, (state, action) => {
			state.error =
				action.error.message || 'Ocurrio un error, intentelo de nuevo'
			state.isLoading = false
			state.isShowModalWithdraw = true
			state.status = 'error'
		})
		builder.addCase(getNextWithdraw.pending, (state) => {
			state.isLoading = true
			state.error = null
			state.status = 'loading'
		})
		builder.addCase(getNextWithdraw.fulfilled, (state, action) => {
			state.isLoading = false
			state.error = null

			const payload = action.payload.data
			const amountCashDrawer = parseFloat(payload.amountCashDrawer)
			const openingBalance = parseInt(payload.openingBalance)

			if (amountCashDrawer > openingBalance) {
				state.isInWithdraw = true
				state.amountCashDrawer = Number(payload.amountCashDrawer)
				state.withDrawalAmount = Number(payload.withDrawalAmount)
				state.gerente = payload.mangerId
				state.hora = convertDateToMilitaryTimeFormat(new Date())
				state.fecha = formatDate(new Date())
				state.isShowModalWithdraw = true
				state.place = 'admin'
				state.idEmployee = payload.idEmployee
			}
		})
		builder.addCase(getNextWithdraw.rejected, (state, action) => {
			state.isLoading = false
			state.error =
				action.error.message || 'Ocurrio un error, intentelo de nuevo'
			state.status = 'error'
		})
	},
})

export const {
	initWithdraw,
	verifyNeedWithdraw,
	cleanWithdraw,
	changeGerente,
	changeEfectivoRetiro,
	changeStatus,
	resetChangableStatesWithdraw,
	changePlaceToAdmin,
	changePlaceToPOS,
} = withdrawSlice.actions

export default withdrawSlice.reducer
