import React, { useEffect, useMemo, useContext, useState } from 'react'
// import Table from '../../../core/pagination/datatable'

import OrderItems from './OrderItems'
import CancelOrder from './CancelOrder'
import { OrdersCashCountContext } from '../provider/OrdersCashCount'
import FeedbackCancelOrder from './FeedbackCancelOrder'
import TableOrder from './TableOrder'
import ModalDetails from '../../../components/modalSales'
import { useSelector } from 'react-redux'
import { selectIsShowFeedbackCashCount } from '../redux/selectors/cash-count'

export default function Orders() {
	const isShowFeedbackCashCount = useSelector(selectIsShowFeedbackCashCount)
	const {
		searchOrder,
		orders,
		updateIdOrderCancel,
		isLoadingOrders,
		updateIsCancelShow,
		updateSearchOrder,
		isShowFeedbackCancelOrder,
		getOrders,
	} = useContext(OrdersCashCountContext)
	const [modalVisible, setModalVisible] = useState(false)
	const [selectedRecord, setSelectedRecord] = useState(null)
	const handleShowModal = (record) => {
		console.log(record)
		setSelectedRecord(record)
		setModalVisible(true)
	}

	const handleCloseModal = () => {
		setModalVisible(false)
		setSelectedRecord(null)
	}

	const showCancel = (id) => {
		updateIsCancelShow(true)
		updateIdOrderCancel(id)
	}

	useEffect(() => {
		getOrders()
	}, [])

	useEffect(() => {
		if (!isShowFeedbackCancelOrder) {
			getOrders()
		}
	}, [isShowFeedbackCancelOrder])

	const filteredData = useMemo(() => {
		if (!searchOrder) {
			return orders
		}

		return orders.filter((entry) =>
			Object.keys(entry).some((key) =>
				String(entry[key]).toLowerCase().includes(searchOrder.toLowerCase())
			)
		)
	}, [searchOrder, orders])

	useEffect(() => {
		if (!isShowFeedbackCashCount) {
			getOrders()
		}
	}, [isShowFeedbackCashCount])

	return (
		<div>
			<TableOrder
				isLoadingOrders={isLoadingOrders}
				updateSearchOrder={updateSearchOrder}
				handleShowModal={handleShowModal}
				showCancel={showCancel}
				filteredData={filteredData}
				searchOrder={searchOrder}
			/>
			<OrderItems />
			<CancelOrder />
			<FeedbackCancelOrder />
			<ModalDetails
				visible={modalVisible}
				onClose={handleCloseModal}
				record={selectedRecord}
			/>
		</div>
	)
}
