import { createSelector } from '@reduxjs/toolkit'

export const selectCashier = (state) => state.sale.cashier
export const selectIsNotRegisteredOrder = (state) =>
	state.sale.isNotRegisteredOrder
export const selectIsLoadingFolioVenta = (state) => state.sale.loadingFolioVenta
export const selectIsDataSaleComplete = (state) => state.sale.isDataSaleComplete
export const selectIsNotRedisteredOrder = (state) =>
	state.sale.isNotRegisteredOrder
export const selectFolioVenta = (state) => state.sale.folioVenta
export const selectFolioVentaCopy = (state) => state.sale.folioVentaCopy
export const selectError = (state) => state.sale.error
export const selectStatus = (state) => state.sale.status
export const selectIsLoading = (state) => state.sale.loading
export const selectItemsCouponAmount = (state) => state.sale.coupon.amount
export const selectItemsCouponType = (state) => state.sale.coupon.type
export const selectItemsCouponCode = (state) => state.sale.coupon.code
export const selectItemsCouponErrorValidate = (state) =>
	state.sale.coupon.errorValidate
export const selectItemsCouponIsLoadingValidate = (state) =>
	state.sale.coupon.isLoadingValidate

export const selectItems = (state) => state.sale.data
export const selectItemsCount = createSelector([selectItems], (items) =>
	items.reduce((count, item) => (count += item.quantity), 0)
)
export const selectItemsSubtotal = createSelector([selectItems], (items) =>
	items.reduce((subtotal, item) => {
		return (
			subtotal +
			((item.AvgPriceLiquidation
				? parseFloat(item.AvgPriceLiquidation)
				: parseFloat(item.AvgPrice)) * item.quantity ?? 0)
		)
	}, 0)
)
export const selectItemsDiscount = createSelector(
	[selectItemsCouponCode, selectItems],
	(discountCode, items) => {
		if (discountCode) {
			const discount = items.reduce(
				(accumulator, item) =>
					accumulator + item.couponAmountDiscounted * item.quantity,
				0
			)

			return discount
		}

		return 0
	}
)
export const selectItemsTotal = createSelector(
	[selectItemsSubtotal, selectItemsDiscount],
	(subtotal, discount) => subtotal - discount
)
export const selectItemsDiscountLiquidation = createSelector(
	[selectItems],
	(items) =>
		items.reduce((liq, item) => {
			if (item.AvgPriceLiquidation) {
				liq += (item.AvgPrice - item.AvgPriceLiquidation) * item.quantity
			}

			return liq
		}, 0)
)
