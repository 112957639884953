import Swal from 'sweetalert2';

export const showSweetAlertError = async () => {
    await Swal.fire({
        title: 'Seleccionar todos los campos',
        text: 'Por favor, seleccione todos los campos obligatorios',
        icon: 'warning',
    })
}

export const showSweetAlertSuccess = async () => {
    await Swal.fire({
        title: 'Configuracion de la impresora guardada con éxito',
        icon: 'success',
        showCancelButton: false,
    })
}

export const handleDeleteConfirmation = async (restoreConfigurations) => {
    const result = await Swal.fire({
        title: '¿Estás seguro?',
        text: 'Esta acción eliminará la configuración de la impresora térmica.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar!',
        cancelButtonText: 'Cancelar',
        open
    })

    if (result.isConfirmed) {
        await restoreConfigurations()
        Swal.fire(
            'Eliminado!',
            'La configuración de la impresora ha sido eliminada.',
            'success'
        )
    }
}

export const showErrorPrint = async (errorPrinting) => {
    await Swal.fire({
        title: 'Ocurrió un error al imprimir',
        text: errorPrinting || 'Error desconocido',
        icon: 'error',
        showConfirmButton: true,
        didOpen: () => {
            Swal.hideLoading()
        }
    });
};

export const showSweetAlertLoadingAnimation = () => {
    Swal.fire({
        title: 'Imprimiendo...',
        text: 'Por favor, espera mientras se completa la impresión.',
        allowOutsideClick: false,
        didClose: () => {
            Swal.close()
        },
        didOpen: () => {
            Swal.showLoading();
        },
    });
};

export const showSweetAlertSuccess2 = async () => {
    await Swal.fire({
        title: 'Impresión completada',
        icon: 'success',
    });
};

export const showSweetAlertErrorConnecting = async (Printer, Port) => {
    await Swal.fire({
        title: 'Error conectando a la impresora',
        text: `Ocurrió un error al conectartse a la impresora ${Printer} : ${Port}`,
        icon: 'error',
        showConfirmButton: true
    })
}

export const showSweetAlerrSuccessConnection = async () => {
    await Swal.fire({
        title: 'Exito al conectar',
        icon: 'success'
    })
}