import React from 'react'
import OpenPOS from '../components/OpenPOS'
import Orders from '../components/Orders'
import OrdersCashCountProvider from '../provider/OrdersCashCount'
import ConfirmExecuteCashCount from '../components/ConfirmExecuteCashCount'
import Actions from '../components/Actions'
import FeedbackExecuteCashCount from '../components/FeedbackExecuteCashCount'
import PagesTitle from '../../../layouts/PagesTitle'
import { idDevice } from '../../../utils/var'
import DashboardLayout from '../../../layouts/DashboardLayout'
import WithdrawModal from '../../pos/components/WithdrawModal'

const CorteCaja = () => {
	return (
		<>
			<DashboardLayout>
				<PagesTitle maintitle={'Arqueo de caja'} subtitle={'Administración del corte de este equipo'} />
				{!idDevice() ? (
					<p>Seleccione un dispositivo en el apartado de configuración</p>
				) : (
					<>
						<Actions />
						<ConfirmExecuteCashCount />
						<WithdrawModal />
						<FeedbackExecuteCashCount />
						<OrdersCashCountProvider>
							<Orders />
						</OrdersCashCountProvider>
					</>
				)}
			</DashboardLayout>
			<OpenPOS />
		</>
	)
}

export default CorteCaja