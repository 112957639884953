import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'
import { formatCurrency } from '../../../utils/functions/money'
import ImageWithBasePath from '../../../core/img/imagewithbasebath'
import ItemOfert from '../components/itemOfert'
import { apiUrl } from '../../../utils/var'

Item.propTypes = {
	ItemCode: PropTypes.string,
	ItemName: PropTypes.string,
	AvgPrice: PropTypes.number,
	AvgPriceLiquidation: PropTypes.number,
	PicturName: PropTypes.string,
	OnHand: PropTypes.number,
	ItemCodeOfert: PropTypes.string,
	ItemNameOfert: PropTypes.string,
	AvgPriceOfert: PropTypes.number,
	AvgPriceLiquidationOfert: PropTypes.number,
}

export default function Item({
	ItemCode,
	ItemName, 
	AvgPrice,
	AvgPriceLiquidation,
	PicturName,
	OnHand,
	ItemCodeOfert,
	ItemNameOfert,
	AvgPriceOfert,
	AvgPriceLiquidationOfert,
}) {
	const [className, setClassName] = useState('lapy hithere')

	useEffect(() => {
		const timer = setTimeout(() => {
			setClassName('lapy')
		}, 750)
		return () => clearTimeout(timer) // Limpiar el timeout si el componente se desmonta
	}, [])

	return (
		<>
			<div className={className}>
				<ImageWithBasePath src='assets/img/lapi.png' />
			</div>
			<div className='escaneado-card product-list d-flex align-items-start justify-content-between'>
				<div
					className='d-flex align-items-center product-info'
					style={{ padding: 5 + 'px' }}>
					<img
						className='card-image-scanner'
						src={
							PicturName
								? `${apiUrl}/proxy/empleados/web/index.php?r=AppMovil/proxy/compress&filename=${PicturName}`
								// `http://thoro-ent.dyndns.info/empleados/imagenes/ITEMS/${PicturName}`
								: 'assets/img/media/Lapy.jpg'
						}
						alt='Productos'
					/>
				</div>
				<div
					className='info flex-column align-items-center'
					style={{ marginTop: 10 + '%' }}>
					<span
						style={{
							color: 'white',
							background: '#e71f85',
							display: 'inline-block',
							padding: '5px 10px',
							borderRadius: 10 + 'px',
						}}>
						{ItemCode}
					</span>
					<h2 style={{ paddingTop: 10 + 'px' }}>
						<Link to='#'>{ItemName}</Link>
					</h2>
					<div className='d-flex flex-row'>
						{AvgPriceLiquidation ? (
							<h2 style={{ paddingTop: 10 + 'px', color: '#e71f85' }}>
								Precio:{' '}
								<span
									className='text-decoration-line-through'
									style={{ color: 'gray', fontSize: '20px' }}>
									{formatCurrency(AvgPrice)}
								</span>{' '}
								<span>{formatCurrency(AvgPriceLiquidation)}</span>
							</h2>
						) : (
							<h2 style={{ paddingTop: 10 + 'px', color: '#e71f85' }}>
								Precio: {formatCurrency(AvgPrice)}
							</h2>
						)}

						<h5
							style={{
								paddingLeft: 5 + 'px',
								paddingTop: 20 + 'px',
								color: 'gray',
							}}>
							(incluye IVA)
						</h5>
					</div>
					<h3 style={{ paddingTop: 10 + 'px', color: '#17a2b8' }}>
						Stock disponible: {parseInt(OnHand)} piezas.
					</h3>
				</div>
				<div className='card'>
					{AvgPriceLiquidation ? (
						<div className='d-flex ribbone-escaner ribbone-top-right text-danger align-items-end justify-content-end'>
							<span className='bg-danger' style={{ margin: '18px' }}>
								Liquidación
							</span>
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
			<ItemOfert
				ItemCode={ItemCodeOfert}
				ItemName={ItemNameOfert}
				AvgPrice={AvgPriceOfert}
				AvgPriceLiquidation={AvgPriceLiquidationOfert}
			/> 
		</>
	)
}
